<template>
    <div>
        <div class="scroll-arrow-container is-flex is-justify-content-space-between">
            <div
                id="scroll-horizontal"
                class="is-flex-grow-1"></div>
            <span class="arrow-horizontal"> </span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ScrollArrow',
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';

    .scroll-arrow-container {
        transform: rotate(-90deg);
    }
    #scroll-horizontal::before {
        -webkit-animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
        /* Safari 4+ */

        -moz-animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
        /* Fx 5+ */

        -o-animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
        /* Opera 12+ */

        animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
        /* IE 10+, Fx 29+ */

        position: absolute;

        margin-left: 86px;
        width: 2px;
        height: 90px;
        background: black;
        content: ' ';
        margin-top: -40px;
    }

    //Arrow down efect
    .arrow-horizontal {
        display: block;
        margin: 0 auto;
        width: 10px;
        position: absolute;
        // height: 38px;
    }

    .arrow-horizontal:after {
        content: '';
        display: block;
        margin: 0;
        padding: 0;
        width: 8px;
        height: 8px;
        border-top: 0.125rem solid $black;
        border-right: 0.125rem solid $black;
        -moz-transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        -ms-transform: rotate(135deg);
        transform: rotate(135deg);
        margin-left: 83px;
        margin-top: 48px;
    }

    @-webkit-keyframes elasticus {
        0% {
            -webkit-transform-origin: 0% 0%;
            -ms-transform-origin: 0% 0%;
            -moz-transform-origin: 0% 0%;
            -o-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
            -webkit-transform: scale(1, 0);
            -ms-transform: scale(1, 0);
            -moz-transform: scale(1, 0);
            -o-transform: scale(1, 0);
            transform: scale(1, 0);
        }
        50% {
            -webkit-transform-origin: 0% 0%;
            -ms-transform-origin: 0% 0%;
            -moz-transform-origin: 0% 0%;
            -o-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
            -webkit-transform: scale(1, 1);
            -ms-transform: scale(1, 1);
            -moz-transform: scale(1, 1);
            -o-transform: scale(1, 1);
            transform: scale(1, 1);
        }
        50.1% {
            -webkit-transform-origin: 0% 100%;
            -ms-transform-origin: 0% 100%;
            -moz-transform-origin: 0% 100%;
            -o-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
            -webkit-transform: scale(1, 1);
            -ms-transform: scale(1, 1);
            -moz-transform: scale(1, 1);
            -o-transform: scale(1, 1);
            transform: scale(1, 1);
        }
        100% {
            -webkit-transform-origin: 0% 100%;
            -ms-transform-origin: 0% 100%;
            -moz-transform-origin: 0% 100%;
            -o-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
            -webkit-transform: scale(1, 0);
            -ms-transform: scale(1, 0);
            -moz-transform: scale(1, 0);
            -o-transform: scale(1, 0);
            transform: scale(1, 0);
        }
    }
    @-moz-keyframes elasticus {
        0% {
            -webkit-transform-origin: 0% 0%;
            -ms-transform-origin: 0% 0%;
            -moz-transform-origin: 0% 0%;
            -o-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
            -webkit-transform: scale(1, 0);
            -ms-transform: scale(1, 0);
            -moz-transform: scale(1, 0);
            -o-transform: scale(1, 0);
            transform: scale(1, 0);
        }
        50% {
            -webkit-transform-origin: 0% 0%;
            -ms-transform-origin: 0% 0%;
            -moz-transform-origin: 0% 0%;
            -o-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
            -webkit-transform: scale(1, 1);
            -ms-transform: scale(1, 1);
            -moz-transform: scale(1, 1);
            -o-transform: scale(1, 1);
            transform: scale(1, 1);
        }
        50.1% {
            -webkit-transform-origin: 0% 100%;
            -ms-transform-origin: 0% 100%;
            -moz-transform-origin: 0% 100%;
            -o-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
            -webkit-transform: scale(1, 1);
            -ms-transform: scale(1, 1);
            -moz-transform: scale(1, 1);
            -o-transform: scale(1, 1);
            transform: scale(1, 1);
        }
        100% {
            -webkit-transform-origin: 0% 100%;
            -ms-transform-origin: 0% 100%;
            -moz-transform-origin: 0% 100%;
            -o-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
            -webkit-transform: scale(1, 0);
            -ms-transform: scale(1, 0);
            -moz-transform: scale(1, 0);
            -o-transform: scale(1, 0);
            transform: scale(1, 0);
        }
    }
    @-o-keyframes elasticus {
        0% {
            -webkit-transform-origin: 0% 0%;
            -ms-transform-origin: 0% 0%;
            -moz-transform-origin: 0% 0%;
            -o-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
            -webkit-transform: scale(1, 0);
            -ms-transform: scale(1, 0);
            -moz-transform: scale(1, 0);
            -o-transform: scale(1, 0);
            transform: scale(1, 0);
        }
        50% {
            -webkit-transform-origin: 0% 0%;
            -ms-transform-origin: 0% 0%;
            -moz-transform-origin: 0% 0%;
            -o-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
            -webkit-transform: scale(1, 1);
            -ms-transform: scale(1, 1);
            -moz-transform: scale(1, 1);
            -o-transform: scale(1, 1);
            transform: scale(1, 1);
        }
        50.1% {
            -webkit-transform-origin: 0% 100%;
            -ms-transform-origin: 0% 100%;
            -moz-transform-origin: 0% 100%;
            -o-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
            -webkit-transform: scale(1, 1);
            -ms-transform: scale(1, 1);
            -moz-transform: scale(1, 1);
            -o-transform: scale(1, 1);
            transform: scale(1, 1);
        }
        100% {
            -webkit-transform-origin: 0% 100%;
            -ms-transform-origin: 0% 100%;
            -moz-transform-origin: 0% 100%;
            -o-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
            -webkit-transform: scale(1, 0);
            -ms-transform: scale(1, 0);
            -moz-transform: scale(1, 0);
            -o-transform: scale(1, 0);
            transform: scale(1, 0);
        }
    }
    @keyframes elasticus {
        0% {
            -webkit-transform-origin: 0% 0%;
            -ms-transform-origin: 0% 0%;
            -moz-transform-origin: 0% 0%;
            -o-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
            -webkit-transform: scale(1, 0);
            -ms-transform: scale(1, 0);
            -moz-transform: scale(1, 0);
            -o-transform: scale(1, 0);
            transform: scale(1, 0);
        }
        50% {
            -webkit-transform-origin: 0% 0%;
            -ms-transform-origin: 0% 0%;
            -moz-transform-origin: 0% 0%;
            -o-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
            -webkit-transform: scale(1, 1);
            -ms-transform: scale(1, 1);
            -moz-transform: scale(1, 1);
            -o-transform: scale(1, 1);
            transform: scale(1, 1);
        }
        50.1% {
            -webkit-transform-origin: 0% 100%;
            -ms-transform-origin: 0% 100%;
            -moz-transform-origin: 0% 100%;
            -o-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
            -webkit-transform: scale(1, 1);
            -ms-transform: scale(1, 1);
            -moz-transform: scale(1, 1);
            -o-transform: scale(1, 1);
            transform: scale(1, 1);
        }
        100% {
            -webkit-transform-origin: 0% 100%;
            -ms-transform-origin: 0% 100%;
            -moz-transform-origin: 0% 100%;
            -o-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
            -webkit-transform: scale(1, 0);
            -ms-transform: scale(1, 0);
            -moz-transform: scale(1, 0);
            -o-transform: scale(1, 0);
            transform: scale(1, 0);
        }
    }
</style>
