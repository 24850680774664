<template>
    <div class="start">
        <section class="hero is-fullheight is-relative custom-background">
            <div class="hero-body is-flex-direction-column is-justify-content-center">
                <div class="columns is-mobile is-fullwidth is-multiline">
                    <div class="column is-12-desktop has-text-centered-desktop is-8-widescreen is-offset-1-widescreen is-7-fullhd is-12-touch has-text-centered-touch">
                        <h1 class="has-font-reft has-text-white">{{ t.yourOpinion }}</h1>
                    </div>
                    <div class="column is-6-desktop is-offset-3-desktop is-offset-2-widescreen is-offset-2-fullhd is-5-fullhd is-8-touch is-offset-2-touch">
                        <figure class="image">
                            <img
                                class="mx-auto"
                                src="@/assets/images/yourOpinion/points.jpg"
                                alt="" />
                        </figure>
                    </div>
                </div>
            </div>
            <ScrollArrow />
        </section>
        <b-carousel
            :has-drag="false"
            ref="testCar"
            :autoplay="false"
            v-model="testIndex"
            id="testimonials"
            class="hero is-large">
            <b-carousel-item
                v-for="(testimonial, i) in testimonials"
                :key="i">
                <div
                    class="columns is-centered is-mobile py-16"
                    :style="{ 'background-color': testimonial.color }">
                    <div class="column is-6-desktop is-10-touch">
                        <div class="is-flex is-flex-direction-column mb-12">
                            <div class="is-flex is-flex-direction-column is-align-items-center">
                                <figure class="image is-250x250">
                                    <img
                                        class="is-rounded tw-w-full tw-h-full tw-object-cover"
                                        :src="testimonial.image"
                                        alt="" />
                                </figure>
                                <h3 class="has-font-sacramento has-text-centered mt-4 is-size-1">{{ testimonial.name }}</h3>
                                <h4 class="has-font-lota-light has-text-weight-bold has-text-centered is-size-4">{{ testimonial.profession[$i18n.locale] }}</h4>
                            </div>
                            <div class="has-border-white is-size-5-desktop is-size-5-tablet is-size-6-mobile mt-4">
                                <p class="has-font-lota has-text-white">{{ testimonial.testimonial[$i18n.locale] }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </b-carousel-item>
        </b-carousel>
        <section class="section">
            <div class="container">
                <div class="columns is-mobile">
                    <div class="column is-12 has-text-right-desktop has-text-centered-touch">
                        <v-swatches
                            v-model="color"
                            :swatches="swatches"
                            inline
                            background-color="#f7f7f7"></v-swatches>
                    </div>
                </div>
            </div>
        </section>

        <form @submit.prevent="send">
            <div class="columns is-mobile is-centered is-multiline">
                <div class="column is-2-desktop is-offset-1-desktop is-12-touch has-text-centered-touch center-inside">
                    <h1 class="has-font-lota-light has-text-weight-bold pr-14-widescreen">
                        {{ t.space }}
                    </h1>
                </div>
                <div class="column is-4-desktop p-0 center-inside is-flex is-11-touch">
                    <div class="img-container">
                        <b-upload
                            v-model="uploadImg"
                            class="file-label center-inside img-upload"
                            accept="image/*"
                            required>
                            <b-icon
                                v-if="!uploadImg"
                                icon="plus"
                                type="is-black"
                                size="is-large">
                            </b-icon>
                            <img
                                v-else
                                class="testimonial-image"
                                :src="getFileURL"
                                alt="" />
                        </b-upload>
                    </div>
                </div>
                <div class="column is-5-desktop p-0 is-12-touch">
                    <div
                        class="text-container p-6"
                        :style="{ backgroundColor: color }">
                        <textarea
                            type="textarea"
                            class="user-text has-text-white has-font-lota"
                            :placeholder="t.testimonial"
                            v-model="testimonial"
                            required />
                        <br />

                        <input
                            type="text"
                            class="mt-4 has-text-white has-font-lota"
                            :placeholder="t.name"
                            required
                            v-model="name" />

                        <input
                            type="text"
                            class="mt-4 profession-input has-text-white has-font-lota"
                            :placeholder="t.job"
                            required
                            v-model="profession" />

                        <b-button
                            rounded
                            :loading="isLoading"
                            class="mt-4 has-font-lota has-text-black"
                            native-type="submit"
                            >{{ t.send }}</b-button
                        >
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import ScrollArrow from '@/components/shared/ScrollArrow.vue';
    import { sendTestimonial } from '@/api/testimonies';
    import Hammer from 'hammerjs';
    import { getTestimonials } from '@/api/beLoveBeYou';

    import VSwatches from 'vue-swatches';
    export default {
        components: {
            ScrollArrow,
            VSwatches,
        },

        data() {
            return {
                uploadImg: null,
                testimonials: [],
                testIndex: 0,
                color: '#3DB8BC',
                swatches: ['#3DB8BC', '#93BFB7', '#BF8897', '#F2D8E1', '#D9B0C3', '#BFD9D1'],
                isLoading: false,
                name: null,
                profession: null,
                testimonial: null,
            };
        },

        computed: {
            getFileURL() {
                return window.URL.createObjectURL(this.uploadImg);
            },

            t() {
                return this.$t('views.YourOpinion');
            },
        },

        created() {
            getTestimonials()
                .then((response) => {
                    this.testimonials = response.data.testimonials.filter((t) => t.accept);
                })
                .catch((error) => {
                    throw error;
                });
        },

        mounted() {
            const carousel = this.$refs.testCar;
            const hammer = new Hammer(carousel.$el);
            hammer.on('swipeleft', () => {
                carousel.next();
            });
            hammer.on('swiperight', () => {
                carousel.prev();
            });
        },

        methods: {
            send() {
                let data = {
                    name: this.name,
                    profession: {
                        pt: this.profession,
                    },
                    color: this.color,
                    testimonial: {
                        pt: this.testimonial,
                    },
                };

                if (this.getToken) {
                    this.isLoading = true;

                    sendTestimonial(data, this.uploadImg)
                        .then((response) => {
                            this.$buefy.snackbar.open({
                                type: 'is-primary',
                                message: this.t.message,
                                actionText: 'Ok',
                                indefinite: true,
                                position: 'is-top',
                            });
                            this.uploadImg = null;
                            this.testimonial = null;
                            this.name = null;
                            this.profession = null;
                        })
                        .catch((error) => {
                            throw error;
                        })
                        .finally(() => {
                            this.isLoading = false;
                        });
                } else {
                    this.$router.push({
                        name: 'Login',
                        params: { route: this.$router.history.current.name },
                    });
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';
    @import '@/assets/scss/mixins.scss';

    .start {
        @include mobile {
            overflow-x: hidden;
        }
    }

    .custom-background {
        background-color: #bfd9d1;
    }

    .hero {
        h1 {
            font-size: 10rem;

            @include until($touch) {
                font-size: 5rem;
            }
        }
    }

    form {
        h1 {
            font-size: 1.5rem;

            @include until($touch) {
                padding-inline: 0.75rem;
                font-size: 1.2rem;
            }

            @include from($widescreen) {
                font-size: 2rem;
            }
        }

        .column:nth-of-type(2) {
            border: solid 1px black;
            min-height: 18em;
        }
        .column:nth-of-type(3) {
            min-height: 18em;

            .text-container {
                height: 100%;

                @include until(769px) {
                    width: 100%;
                }
            }

            textarea {
                width: 100%;
                height: 18rem;
                background-color: transparent;
                padding: 1rem;
                font-size: 1.5rem;
                border: solid 2px white;
                resize: none;
                display: block;
                margin-left: auto;
                margin-right: auto;

                @include scrollbar($white);
            }

            input {
                background-color: transparent;
                border: none;
                width: 100%;
                font-size: 1.5rem;
            }

            .profession-input {
                font-size: 1rem;
            }

            textarea:focus,
            input:focus {
                outline: none;
            }

            textarea::placeholder,
            input::placeholder {
                color: white;
            }
        }

        img {
            max-height: 18em;
        }
    }

    #testimonials {
        img {
            opacity: 0.7;
        }
        .is-250x250 {
            height: 15.625rem;
            width: 15.625rem;
        }

        .has-border-white {
            padding: 1rem !important;
        }
        p {
            height: 11.25rem;
            overflow-y: auto;
            margin-left: 5px;
            @include scrollbar($cyan, $white);

            @include touch() {
                height: 15.625rem;
                padding: 0rem !important;
            }
        }
    }
</style>
