import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';

Vue.use(VueRouter);

import Home from '@/views/Home.vue';
import BeLoveBeYou from '@/views/BeLoveBeYou.vue';
import GetInspired from '@/views/GetInspired.vue';
import Login from '@/views/Login.vue';
import Podcast from '@/views/Podcast.vue';
import AllPodcast from '@/views/AllPodcasts.vue';
import Media from '@/views/Media.vue';
import Blog from '@/views/Blog.vue';
import PostDetail from '@/views/PostDetail.vue';
import MealPlanner from '@/views/MealPlanner.vue';
import Meditate from '@/views/Meditate.vue';
import Store from '@/views/Store.vue';
import Product from '@/views/Product.vue';
import YourOpinion from '@/views/YourOpinion.vue';
import HealthyFood from '@/components/BlogCategories/HealthyFood.vue';
import MeditatePosts from '@/components/BlogCategories/Meditate.vue';
import WellBeing from '@/components/BlogCategories/WellBeing.vue';
import Exercise from '@/components/BlogCategories/Exercise.vue';
import AllPosts from '@/components/BlogCategories/AllPosts.vue';
import Files from '@/components/BlogCategories/Files.vue';
import Contacts from '@/views/Contacts.vue';
import Terms from '@/views/Terms.vue';
import INN from '@/views/INN.vue';
import MusicTherapy from '@/views/MusicTherapy.vue';
import Consultory from '@/views/Consultory.vue';
import Checkout from '@/views/Checkout.vue';
import ThankYou from '@/views/ThankYou.vue';
import Teachers from '@/views/Teachers.vue';
import MeditationsDownload from '@/views/MeditationsDownload.vue';
import Answer from '@/views/Answer.vue';
import Form from '@/views/Form.vue';
import Reiki from '@/views/Reiki.vue';
import Curriculum from '@/views/Curriculum.vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            navbar: {
                textTop: 'primary has-text-weight-bold',
                text: 'primary has-text-weight-bold',
                logoTop: 'v1',
                logo: 'v1',
            },
            sideMenu: 'primary',
            scrollProgress: 'primary',
        },
    },
    {
        path: '/belovebeyou',
        name: 'BeLoveBeYou',
        component: BeLoveBeYou,
        meta: {
            navbar: {
                textTop: 'grey-lighter',
                text: 'primary has-text-weight-bold',
                logoTop: 'v2',
                logo: 'v1',
            },
            sideMenu: 'pink',
            scrollProgress: 'pink',
        },
    },
    {
        path: '/getinspired',
        name: 'GetInspired',
        component: GetInspired,
        meta: {
            navbar: {
                textTop: 'black has-text-weight-bold',
                text: 'black has-text-weight-bold',
                logoTop: 'v3',
                logo: 'v3',
            },
            sideMenu: 'pink',
            scrollProgress: 'pink',
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            onlyNoAuth: true,
        },
    },
    {
        path: '/podcast',
        name: 'Podcast',
        component: Podcast,
        meta: {
            navbar: {
                textTop: 'black',
                text: 'primary has-text-weight-bold',
                logoTop: 'v2',
                logo: 'v1',
            },
            sideMenu: 'primary',
            scrollProgress: 'primary',
        },
    },
    {
        path: '/all-podcast',
        name: 'AllPodcast',
        component: AllPodcast,
        meta: {
            navbar: {
                textTop: 'black',
                text: 'primary has-text-weight-bold',
                logoTop: 'v2',
                logo: 'v1',
            },
            sideMenu: 'primary',
            scrollProgress: 'primary',
        },
    },
    {
        path: '/videos',
        name: 'Media',
        component: Media,
        meta: {
            navbar: {
                textTop: 'dark has-text-weight-bold',
                text: 'dark has-text-weight-bold',
                logoTop: 'v3',
                logo: 'v3',
            },
            sideMenu: 'primary',
            scrollProgress: 'primary',
        },
    },

    {
        path: '/blog',
        name: 'Blog',
        component: Blog,
        meta: {
            navbar: {
                textTop: 'white has-text-weight-bold',
                text: 'dark has-text-weight-bold',
                logoTop: 'v2',
                logo: 'v3',
            },
            sideMenu: 'primary',
            scrollProgress: 'pink',
        },
    },

    {
        path: '/blog/:id',
        name: 'Post',
        component: PostDetail,
        meta: {
            navbar: {
                textTop: 'primary',
                text: 'primary has-text-weight-bold',
                logoTop: 'v1',
                logo: 'v1',
            },
            sideMenu: 'primary',
            scrollProgress: 'primary',
        },
    },
    {
        path: '/meal-planner',
        name: 'MealPlanner',
        component: MealPlanner,
        meta: {
            navbar: {
                textTop: 'primary',
                text: 'primary has-text-weight-bold',
                logoTop: 'v1',
                logo: 'v1',
            },
            sideMenu: 'primary',
            scrollProgress: 'primary',
        },
    },
    {
        path: '/meditate',
        name: 'Meditate',
        component: Meditate,
        meta: {
            navbar: {
                textTop: 'grey-lighter',
                text: 'primary has-text-weight-bold',
                logoTop: 'v2',
                logo: 'v1',
            },
            sideMenu: 'primary',
            scrollProgress: 'primary',
        },
    },

    {
        path: '/store',
        name: 'Store',
        component: Store,
        meta: {
            navbar: {
                textTop: 'white has-text-weight-bold',
                text: 'primary has-text-weight-bold',
                logoTop: 'v2',
                logo: 'v1',
            },
            sideMenu: 'primary',
            scrollProgress: 'primary',
        },
    },
    {
        path: '/store/product/:id',
        name: 'ProductId',
        component: Product,
        meta: {
            navbar: {
                textTop: 'grey-lighter',
                text: 'primary has-text-weight-bold',
                logoTop: 'v2',
                logo: 'v1',
            },
            sideMenu: 'pink',
            scrollProgress: 'primary',
        },
    },

    {
        path: '/your-opinion',
        name: 'YourOpinion',
        component: YourOpinion,
        meta: {
            navbar: {
                textTop: 'grey-lighter',
                text: 'primary has-text-weight-bold',
                logoTop: 'v2',
                logo: 'v1',
            },
            sideMenu: 'pink',
            scrollProgress: 'pink',
        },
    },
    {
        path: '/blog/categories/healthy-food',
        name: 'HealthyFood',
        component: HealthyFood,
        meta: {
            navbar: {
                textTop: 'black',
                text: 'primary has-text-weight-bold',
                logoTop: 'v3',
                logo: 'v1',
            },
            sideMenu: 'pink',
            scrollProgress: 'pink',
        },
    },
    {
        path: '/blog/categories/meditate',
        name: 'MeditatePosts',
        component: MeditatePosts,
        meta: {
            navbar: {
                textTop: 'black',
                text: 'primary has-text-weight-bold',
                logoTop: 'v3',
                logo: 'v1',
            },
            sideMenu: 'pink',
            scrollProgress: 'pink',
        },
    },
    {
        path: '/blog/categories/well-being',
        name: 'WellBeing',
        component: WellBeing,
        meta: {
            navbar: {
                textTop: 'black',
                text: 'primary has-text-weight-bold',
                logoTop: 'v3',
                logo: 'v1',
            },
            sideMenu: 'pink',
            scrollProgress: 'pink',
        },
    },
    {
        path: '/blog/categories/exercise',
        name: 'Exercise',
        component: Exercise,
        meta: {
            navbar: {
                textTop: 'black',
                text: 'primary has-text-weight-bold',
                logoTop: 'v3',
                logo: 'v1',
            },
            sideMenu: 'pink',
            scrollProgress: 'pink',
        },
    },
    {
        path: '/blog/categories/all-posts',
        name: 'AllPosts',
        component: AllPosts,
        meta: {
            navbar: {
                textTop: 'black',
                text: 'primary has-text-weight-bold',
                logoTop: 'v3',
                logo: 'v1',
            },
            sideMenu: 'pink',
            scrollProgress: 'pink',
        },
    },
    {
        path: '/blog/categories/files',
        name: 'Files',
        component: Files,
        meta: {
            navbar: {
                textTop: 'black',
                text: 'primary has-text-weight-bold',
                logoTop: 'v3',
                logo: 'v1',
            },
            sideMenu: 'pink',
            scrollProgress: 'pink',
        },
    },
    {
        path: '/contacts',
        name: 'Contacts',
        component: Contacts,
        meta: {
            navbar: {
                textTop: 'primary',
                text: 'primary has-text-weight-bold',
                logoTop: 'v1',
                logo: 'v1',
            },
            sideMenu: 'primary',
            scrollProgress: 'pink',
        },
    },
    {
        path: '/terms',
        name: 'Terms',
        component: Terms,
        meta: {
            navbar: {
                textTop: 'white',
                text: 'primary has-text-weight-bold',
                logoTop: 'v2',
                logo: 'v1',
            },
            sideMenu: 'pink',
            scrollProgress: 'pink',
        },
    },
    {
        path: '/inn',
        name: 'INN',
        component: INN,
        meta: {
            navbar: {
                textTop: 'primary',
                text: 'primary has-text-weight-bold',
                logoTop: 'v1',
                logo: 'v1',
            },
            sideMenu: 'primary',
            scrollProgress: 'primary',
        },
    },
    {
        path: '/music-therapy',
        name: 'MusicTherapy',
        component: MusicTherapy,
        meta: {
            navbar: {
                textTop: 'primary',
                text: 'primary has-text-weight-bold',
                logoTop: 'v1',
                logo: 'v1',
            },
            sideMenu: 'primary',
            scrollProgress: 'primary',
        },
    },
    {
        path: '/consultory',
        name: 'Consultory',
        component: Consultory,
        meta: {
            navbar: {
                textTop: 'primary',
                text: 'primary has-text-weight-bold',
                logoTop: 'v1',
                logo: 'v1',
            },
            sideMenu: 'primary',
            scrollProgress: 'primary',
        },
    },
    {
        path: '/checkout',
        name: 'Checkout',
        component: Checkout,
        meta: {
            navbar: {
                textTop: 'white',
                text: 'primary has-text-weight-bold',
                logoTop: 'v2',
                logo: 'v1',
            },
            sideMenu: 'primary',
            scrollProgress: 'primary',
        },
    },
    {
        path: '/order-success',
        name: 'OrderSucess',
        component: ThankYou,
        meta: {
            navbar: {
                textTop: 'primary',
                text: 'primary has-text-weight-bold',
                logoTop: 'v1',
                logo: 'v1',
            },
            sideMenu: 'primary',
            scrollProgress: 'primary',
        },
    },
    {
        path: '/teachers',
        name: 'Teachers',
        component: Teachers,
    },
    {
        path: '/meditations/download',
        name: 'Download',
        component: MeditationsDownload,
    },
    {
        path: '/answer',
        name: 'Answer',
        component: Answer,
    },
    {
        path: '/form',
        name: 'Form',
        component: Form,
    },

    {
        path: '/reiki',
        name: 'Reiki',
        component: Reiki,
        meta: {
            navbar: {
                textTop: 'primary',
                text: 'primary has-text-weight-bold',
                logoTop: 'v1',
                logo: 'v1',
            },
            sideMenu: 'primary',
            scrollProgress: 'primary',
        },
    },
    {
        path: '/short-cv',
        name: 'ShortCV',
        component: Curriculum,
        meta: {
            navbar: {
                textTop: 'black',
                text: 'primary has-text-weight-bold',
                logoTop: 'v3',
                logo: 'v1',
            },
            sideMenu: 'primary',
            scrollProgress: 'primary',
        },
    },
    {
        path: '*',
        redirect: '/',
        meta: {
            bottomBarSelected: 1,
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,

    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash,
                offset: { x: 0, y: 200 },
                behavior: 'smooth',
            };
        } else {
            return { x: 0, y: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    if (
        (to.name == 'Post' && from.name == 'Blog') ||
        (from.name == 'Post' && to.name == 'AllPosts') ||
        (from.name == 'Post' && to.name == 'Files') ||
        (from.name == 'Post' && to.name == 'HealthyFood') ||
        (from.name == 'Post' && to.name == 'Meditate') ||
        (from.name == 'Post' && to.name == 'WellBeing')
    ) {
        store.commit('app/setMustScroll', true);
    }

    next();
});

export default router;
