<template>
    <div class="has-text-centered">
        <figure :style="{ backgroundImage: `url(${image})` }">
            <!-- <img :src="image" /> -->
        </figure>

        <p class="has-font-lota mx-auto mt-4 is-size-7-mobile">{{ name }}</p>
        <div class="mt-auto">
            <b-button
                class="has-font-lota-light has-text-weight-bold button-radius is-uppercase mt-6 px-10-desktop px-6-touch is-size-7-mobile"
                type="is-primary"
                @click="productDetails(id)">
                {{ t.buyBuy }}
            </b-button>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['image', 'id', 'name'],
        data() {
            return {
                t: this.$t('views.Store'),
            };
        },

        methods: {
            productDetails(id) {
                this.$router.push({ name: 'ProductId', params: { id: id } });
                this.setShowSideMenu(false);
            },
        },

        created() {},
    };
</script>

<style lang="scss" scoped>
    .has-text-centered {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    figure {
        aspect-ratio: 1;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        max-height: 20rem;
    }
    button {
        font-weight: bold;
    }

    p {
        // max-width: 12rem;
        text-align: center;
    }
</style>
