<template>
    <div>
        <section class="hero is-large is-relative has-background-grey">
            <div class="hero-body is-flex-direction-column is-justify-content-center pt-40-desktop pt-25-touch">
                <div class="columns is-mobile is-multiline is-fullwidth">
                    <div class="column is-11-desktop is-offset-1-desktop is-12-touch">
                        <h1 class="has-text-black has-font-reft">{{ t.terms }}</h1>
                    </div>
                    <div class="column is-9-desktop is-10-widescreen is-offset-1-desktop text-container mt-6-desktop is-8-fullhd is-12-touch">
                        <p
                            class="has-text-justified has-font-lota has-text-black mt-8"
                            v-html="t.point1"></p>
                        <p
                            class="has-text-justified has-font-lota has-text-black is-size-6 mt-8"
                            v-html="t.point2"></p>
                        <p
                            class="has-text-justified has-font-lota has-text-black is-size-6 mt-8"
                            v-html="t.point3"></p>
                        <p
                            class="has-text-justified has-font-lota has-text-black is-size-6 mt-8"
                            v-html="t.point4"></p>
                        <p
                            class="has-text-justified has-font-lota has-text-black is-size-6 mt-8"
                            v-html="t.point5"></p>
                        <p
                            class="has-text-justified has-font-lota has-text-black is-size-6 mt-8"
                            v-html="t.point6"></p>
                        <p
                            class="has-text-justified has-font-lota has-text-black is-size-6 mt-8"
                            v-html="t.point7"></p>
                        <p
                            class="has-text-justified has-font-lota has-text-black is-size-6 mt-8 mb-4"
                            v-html="t.point8"></p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        computed: {
            t() {
                return this.$t('views.Terms');
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';
    @import '@/assets/scss/mixins.scss';

    h1 {
        font-size: 4.8rem;
        line-height: 1em;

        @include until($touch) {
            font-size: 3rem;
        }
        @include from($widescreen) {
            font-size: 5.5rem;
        }
    }

    .text-container {
        height: 30rem;
        overflow-y: scroll;
        @include scrollbar($dark);
    }
</style>
