const state = {
    isMobile: window.innerWidth <= 1023,
    mustScroll: false,
    savedID: null,
    pageRequested: 1,
    showSideMenu: false,
    showMobileNav: false,
    likedSoulStates: [],
};

const getters = {
    isMobile(state, getters) {
        return state.isMobile;
    },
    mustScroll(state, getters) {
        return state.mustScroll;
    },

    getSavedID(state, getters) {
        return state.savedID;
    },

    pageRequested(state, getters) {
        return state.pageRequested;
    },

    getShowSideMenu(state, getters) {
        return state.showSideMenu;
    },
    getShowMobileNav(state, getters) {
        return state.showMobileNav;
    },
    getLikedSoulStates(state) {
        return state.likedSoulStates;
    },
};

const mutations = {
    updateWidth(state, width) {
        state.isMobile = width <= 1023;
        state.width = width;
    },

    setMustScroll(state, bol) {
        state.mustScroll = bol;
    },

    setSavedID(state, id) {
        state.savedID = id;
    },

    setPageRequested(state, page) {
        state.pageRequested = page;
    },

    setShowSideMenu(state, bol) {
        state.showSideMenu = bol;
    },

    setShowMobileNav(state, bol) {
        state.showMobileNav = bol;
    },
    addLikedSoulState(state, soulStateId) {
        state.likedSoulStates.push(soulStateId);
    },
    removeLikedSoulState(state, soulStateId) {
        state.likedSoulStates = state.likedSoulStates.filter((s) => s !== soulStateId);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
