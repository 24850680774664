<template>
    <section class="hero is-fullheight">
        <div class="hero-body is-flex-direction-column is-justify-content-center">
            <div class="columns is-vcentered is-mobile is-multiline">
                <div class="column is-12">
                    <div class="center-inside">
                        <img
                            src="../assets/icons/coracao_logo_rosa_seco.png"
                            alt="" />
                    </div>
                    <p class="has-text-centered has-font-reft tw-text-[4rem] has-text-black">
                        {{ t.thanks }}
                    </p>
                    <p class="has-text-centered has-font-sacramento is-size-1 has-text-black">
                        {{ t.answer }}
                    </p>
                    <p class="has-text-centered has-font-lota is-size-6 mt-10 has-text-black">
                        {{ t.find }}
                    </p>
                    <div class="mt-8 social-container">
                        <a
                            href="https://www.instagram.com/belovebeyou/?hl=en"
                            target="_blank">
                            <div class="social center-inside mr-2">
                                <b-icon
                                    icon="instagram"
                                    type="is-white">
                                </b-icon></div
                        ></a>

                        <a
                            href="https://pt-pt.facebook.com/pages/category/Health---Wellness-Website/Be-Love-Be-You-1739647529592163/"
                            target="_blank">
                            <div class="social center-inside mr-2">
                                <b-icon
                                    icon="facebook"
                                    type="is-white">
                                </b-icon></div
                        ></a>

                        <a
                            href="https://twitter.com/belovebeyoufg"
                            target="_blank">
                            <div class="social center-inside mr-2">
                                <b-icon
                                    icon="twitter"
                                    type="is-white">
                                </b-icon></div
                        ></a>

                        <a
                            href="https://pt.linkedin.com/in/ana-garcez-3a166338"
                            target="_blank">
                            <div class="social center-inside">
                                <b-icon
                                    icon="linkedin"
                                    type="is-white">
                                </b-icon>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        created() {
            this.clearCart();
        },
        computed: {
            t() {
                return this.$t('views.Answer');
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';

    img {
        width: 20%;

        @include until($tablet) {
            width: 60%;
        }
    }

    .social {
        background-color: #bfd9d1;
        width: 38px;
        height: 38px;
        border-radius: 19px;

        @include until($tablet) {
            margin-bottom: 0.25rem;
        }
    }

    .social-container {
        display: inline-flex;
        width: 100%;
        justify-content: center;
    }
</style>
