<template>
    <section>
        <section class="hero is-fullheight video is-relative">
            <video
                id="meditate_video"
                autoplay
                muted
                loop
                playsinline>
                <source
                    src="../assets/images/meditate/video.mp4"
                    type="video/mp4" />
            </video>
            <div class="hero-body">
                <h1 class="has-font-borest has-text-white is-flex is-align-items-center ml-45-desktop ml-10-touch">{{ t.meditate }}</h1>
            </div>

            <ScrollArrow class="is-white" />
        </section>
        <section class="hero">
            <div class="hero-body">
                <div class="has-font-sacramento is-size-2-desktop is-size-3-tablet is-size-3-mobile has-text-centered">
                    <p>
                        {{ t.imagine }}
                    </p>
                    <p>
                        {{ t.transport }}
                    </p>

                    <p>
                        {{ t.now }}
                    </p>
                    <p>
                        {{ t.do }}
                    </p>
                    <p>
                        {{ t.prepared }}
                    </p>
                </div>
                <div class="mt-10 mb-10 is-hidden-desktop">
                    <p class="has-text-centered has-font-refto is-size-3 mb-2 has-text-primary">{{ t.album }}: {{ album[0].title }} ({{ album[0].price }} €)</p>
                    <div class="card mt-8">
                        <div class="card-content">
                            <div class="media">
                                <div class="media-left">
                                    <ul>
                                        <li
                                            class="item has-font-lota py-4 pl-4"
                                            v-for="(item, index) in musicPlaylist"
                                            :class="isCurrentSong(index) ? 'active has-text-weight-bold ' : ''"
                                            v-on:click="changeSong(index)"
                                            :key="index">
                                            {{ item.title }} ({{ item.duration }})
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="card-image py-10">
                            <div class="is-flex is-align-items-center is-flex-direction-column">
                                <figure class="image is-96x96">
                                    <img
                                        @load="onImageLoaded()"
                                        :src="musicPlaylist[currentSong].image"
                                        id="playerAlbumArt" />
                                </figure>
                                <h4 class="has-font-lota mt-4">
                                    {{ musicPlaylist[currentSong].title }}
                                </h4>
                                <p style="visibility: hidden">
                                    {{ musicPlaylist[currentSong].desc }}
                                </p>
                            </div>

                            <div>
                                <div class="player-progress is-flex is-justify-content-center is-align-items-center mb-4">
                                    <span class="pr-3">{{ currentTime | fancyTimeFormat }}</span>
                                    <input
                                        type="range"
                                        min="1"
                                        max="100"
                                        v-model="currentProgressBar" />
                                    <span class="pl-3"> {{ trackDuration | fancyTimeFormat }}</span>
                                </div>
                                <hr />
                                <ul class="is-flex is-justify-content-center">
                                    <li class="px-5">
                                        <b-button
                                            :class="{ isDisabled: currentSong == 0 }"
                                            v-on:click="prevSong()"
                                            title="Previous Song"
                                            type="is-outlined">
                                            <b-icon
                                                icon="skip-previous"
                                                class="px-6">
                                            </b-icon>
                                        </b-button>
                                    </li>
                                    <li class="px-5">
                                        <b-button
                                            class="is-outlined"
                                            v-on:click="playAudio()"
                                            title="Play/Pause Song">
                                            <span
                                                :class="[currentlyStopped ? 'stop' : currentlyPlaying ? 'pause' : 'play']"
                                                :key="1">
                                                <b-icon
                                                    v-if="!currentlyPlaying"
                                                    icon="play"
                                                    class="px-9">
                                                </b-icon>
                                                <b-icon
                                                    v-else
                                                    icon="pause"
                                                    class="px-9">
                                                </b-icon
                                            ></span>
                                        </b-button>
                                    </li>
                                    <li class="px-5">
                                        <b-button
                                            :class="{
                                                isDisabled: currentSong == musicPlaylist.length - 1,
                                            }"
                                            v-on:click="nextSong()"
                                            type="is-outlined">
                                            <b-icon
                                                icon="skip-next"
                                                class="px-6">
                                            </b-icon>
                                        </b-button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-10 mb-10 px-20 is-hidden-touch">
                    <p class="has-text-centered has-font-refto is-size-3 mb-2 has-text-primary">{{ t.album }}: {{ album[0].title }} ({{ album[0].price }} €)</p>
                    <div class="card mt-8">
                        <div class="card-content p-0">
                            <div class="media">
                                <div class="media-left p-2">
                                    <ul>
                                        <li
                                            class="item has-font-lota py-4 pl-4"
                                            v-for="(item, index) in musicPlaylist"
                                            :class="isCurrentSong(index) ? 'active has-text-weight-bold ' : ''"
                                            v-on:click="changeSong(index)"
                                            :key="index">
                                            {{ item.title }} ({{ item.duration }})
                                        </li>
                                    </ul>
                                </div>
                                <div class="media-content is-flex is-align-self-center is-flex-direction-column py-20">
                                    <div class="is-flex is-align-items-center is-flex-direction-column">
                                        <figure class="image is-96x96">
                                            <img
                                                class="is-rounded"
                                                @load="onImageLoaded()"
                                                :src="musicPlaylist[currentSong].image"
                                                id="playerAlbumArt" />
                                        </figure>
                                        <h4 class="has-font-lota mt-4">
                                            {{ musicPlaylist[currentSong].title }}
                                        </h4>
                                        <p style="visibility: hidden">
                                            {{ musicPlaylist[currentSong].desc }}
                                        </p>
                                    </div>

                                    <div>
                                        <div class="player-progress is-flex is-justify-content-center is-align-items-center mb-4">
                                            <span class="pr-3">{{ currentTime | fancyTimeFormat }}</span>
                                            <input
                                                type="range"
                                                min="1"
                                                max="100"
                                                v-model="currentProgressBar" />
                                            <span class="pl-3"> {{ trackDuration | fancyTimeFormat }}</span>
                                        </div>
                                        <hr />
                                        <ul class="is-flex is-justify-content-center">
                                            <li class="px-5">
                                                <b-button
                                                    :class="{ isDisabled: currentSong == 0 }"
                                                    v-on:click="prevSong()"
                                                    title="Previous Song"
                                                    type="is-outlined">
                                                    <b-icon
                                                        icon="skip-previous"
                                                        class="px-6">
                                                    </b-icon>
                                                </b-button>
                                            </li>
                                            <li class="px-5">
                                                <b-button
                                                    class="is-outlined"
                                                    v-on:click="playAudio()"
                                                    title="Play/Pause Song">
                                                    <span
                                                        :class="[currentlyStopped ? 'stop' : currentlyPlaying ? 'pause' : 'play']"
                                                        :key="1">
                                                        <b-icon
                                                            v-if="!currentlyPlaying"
                                                            icon="play"
                                                            class="px-9">
                                                        </b-icon>
                                                        <b-icon
                                                            v-else
                                                            icon="pause"
                                                            class="px-9">
                                                        </b-icon
                                                    ></span>
                                                </b-button>
                                            </li>
                                            <li class="px-5">
                                                <b-button
                                                    :class="{
                                                        isDisabled: currentSong == musicPlaylist.length - 1,
                                                    }"
                                                    v-on:click="nextSong()"
                                                    type="is-outlined">
                                                    <b-icon
                                                        icon="skip-next"
                                                        class="px-6">
                                                    </b-icon>
                                                </b-button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="is-flex is-align-items-center is-flex-direction-column button-container">
                    <b-button
                        type="is-primary"
                        size="is-medium-touch"
                        :loading="isLoading"
                        @click="stripe"
                        rounded
                        class="mt-10 px-10"
                        >Checkout</b-button
                    >

                    <div
                        id="paypal-button-container"
                        class="mt-4"></div>
                </div>
            </div>
        </section>
    </section>
</template>

<script>
    import { getMeditations, stripeCheckout, payPalCheckout } from '@/api/media';
    import { loadScript } from '@paypal/paypal-js';
    import ScrollArrow from '@/components/shared/ScrollArrow.vue';

    export default {
        components: {
            ScrollArrow,
        },

        data() {
            return {
                audio: '',
                imgLoaded: false,
                currentlyPlaying: false,
                currentlyStopped: false,
                currentTime: 0,
                checkingCurrentPositionInTrack: '',
                trackDuration: 0,
                currentProgressBar: 0,
                isPlaylistActive: false,
                currentSong: 0,
                debug: false,
                musicPlaylist: [],
                audioFile: '',
                album: [],
                isLoading: false,
                email: null,
            };
        },

        computed: {
            t() {
                return this.$t('views.Meditate');
            },
        },

        filters: {
            fancyTimeFormat: function (s) {
                return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s;
            },
        },
        methods: {
            togglePlaylist: function () {
                this.isPlaylistActive = !this.isPlaylistActive;
            },
            nextSong: function () {
                if (this.currentSong < this.musicPlaylist.length - 1) this.changeSong(this.currentSong + 1);
            },
            prevSong: function () {
                if (this.currentSong > 0) this.changeSong(this.currentSong - 1);
            },
            changeSong: function (index) {
                var wasPlaying = this.currentlyPlaying;
                this.imageLoaded = false;
                if (index !== undefined) {
                    this.stopAudio();
                    this.currentSong = index;
                }
                this.audioFile = this.musicPlaylist[this.currentSong].url;
                this.audio = new Audio(this.audioFile);
                var localThis = this;
                this.audio.addEventListener('loadedmetadata', function () {
                    localThis.trackDuration = Math.round(this.duration);
                });
                this.audio.addEventListener('ended', this.handleEnded);
                if (wasPlaying) {
                    this.playAudio();
                }
            },
            isCurrentSong: function (index) {
                if (this.currentSong == index) {
                    return true;
                }
                return false;
            },
            getCurrentSong: function (currentSong) {
                return this.musicPlaylist[currentSong].url;
            },
            playAudio: function () {
                if (this.currentlyStopped == true && this.currentSong + 1 == this.musicPlaylist.length) {
                    this.currentSong = 0;
                    this.changeSong();
                }
                if (!this.currentlyPlaying) {
                    this.getCurrentTimeEverySecond(true);
                    this.currentlyPlaying = true;
                    this.audio.play();
                } else {
                    this.stopAudio();
                }
                this.currentlyStopped = false;
            },
            stopAudio: function () {
                this.audio.pause();
                this.currentTime = 0;
                this.currentlyPlaying = false;
                this.pausedMusic();
            },
            handleEnded: function () {
                if (this.currentSong + 1 == this.musicPlaylist.length) {
                    this.stopAudio();
                    this.currentlyPlaying = false;
                    this.currentlyStopped = true;
                } else {
                    this.currentlyPlaying = false;
                    this.currentSong++;
                    this.changeSong();
                    this.playAudio();
                }
            },
            onImageLoaded: function () {
                this.imgLoaded = true;
            },
            getCurrentTimeEverySecond: function (startStop) {
                var localThis = this;
                this.checkingCurrentPositionInTrack = setTimeout(
                    function () {
                        localThis.currentTime = localThis.audio.currentTime;
                        localThis.currentProgressBar = (localThis.audio.currentTime / localThis.trackDuration) * 100;
                        localThis.getCurrentTimeEverySecond(true);
                    }.bind(this),
                    1000,
                );
            },
            pausedMusic: function () {
                clearTimeout(this.checkingCurrentPositionInTrack);
            },
            toggleDebug: function () {
                this.debug = !this.debug;
                document.body.classList.toggle('debug');
            },

            stripe() {
                let data = {
                    meditation: this.album[0].id,
                };

                stripeCheckout(data)
                    .then((response) => {
                        if (this.isMobile) {
                            window.location.assign(response.data.session.url, '_blank');
                        } else window.open(response.data.session.url, '_blank');
                    })
                    .catch((error) => {
                        throw error;
                    });
            },

            async paypal() {
                let paypal;
                let t = this;
                try {
                    paypal = await loadScript({
                        'client-id': 'AelSxaixTplWJjIpPfYBCNsS9etWjNbPGAJDegaNMRgT6u6cUzQ4c69FR2J6xtwF0PUk3swgAH2um0a8',
                        'disable-funding': 'credit,card',
                    });
                } catch (error) {
                    console.error('failed to load the PayPal JS SDK script', error);
                }

                if (paypal) {
                    try {
                        await paypal
                            .Buttons({
                                style: {
                                    label: 'paypal',
                                    height: 40,
                                    shape: 'pill',
                                },

                                onApprove: function (data, actions) {
                                    return actions.order.capture().then(function (orderData) {
                                        // Full available details
                                        console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));
                                        let data = {
                                            meditation: t.album[0].id,
                                            to_email: null,
                                            paypal_checkout: null,
                                        };

                                        data.to_email = orderData.purchase_units[0].payee.email_address;
                                        data.paypal_checkout = orderData.id;

                                        payPalCheckout(data)
                                            .then((response) => {
                                                t.goToPage('OrderSucess');
                                            })
                                            .catch((error) => {
                                                t.$buefy.toast.open({
                                                    message: t.t.errorMessage,
                                                    position: 'is-top',
                                                    type: 'is-secondary',
                                                });
                                                throw error;
                                            });
                                    });
                                },

                                onError: function (err) {
                                    t.$buefy.toast.open({
                                        message: t.t.errorMessage,
                                        position: 'is-top',
                                        type: 'is-secondary',
                                    });
                                },
                            })
                            .render('#paypal-button-container');
                    } catch (error) {
                        console.error('failed to render the PayPal Buttons', error);
                    }
                }
            },
        },
        watch: {
            currentTime: function () {
                this.currentTime = Math.round(this.currentTime);
            },
        },
        beforeDestroy: function () {
            this.audio.removeEventListener('ended', this.handleEnded);
            this.audio.removeEventListener('loadedmetadata', this.handleEnded);

            clearTimeout(this.checkingCurrentPositionInTrack);
        },

        created() {
            getMeditations()
                .then((response) => {
                    this.album.push({
                        title: response.data.meditations[0].name,
                        id: response.data.meditations[0]._id,
                        price: response.data.meditations[0].price,
                        file: response.data.meditations[0].file_url,
                    });

                    response.data.meditations[0].tracks.map((track) => {
                        this.musicPlaylist.push({
                            title: track.name,
                            duration: track.duration,
                            url: track.preview_url,
                            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin id tempor nulla. Sed ac interdum elit, elementum fermentum mauris.',
                            image: response.data.meditations[0].image,
                            completeUrl: track.file_url,
                        });
                    });

                    this.changeSong();
                    this.audio.loop = false;
                    this.paypal();
                })
                .catch((error) => {});
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';

    #meditate_video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 100vh;
        width: 100vw;
        max-width: unset;
        max-height: unset;
        object-fit: cover;

        &:focus-visible {
            outline: none;
        }
    }

    h1 {
        position: relative;
        font-size: 6rem;
        @include mobile {
            font-size: 4rem;
        }
        @include tablet-only {
            font-size: 5rem;
        }
    }
    .card-image:last-child img {
        border-radius: 9999rem !important;
    }
    .player-progress input {
        -webkit-appearance: none;
        appearance: none;
        width: 60%;
        height: 4px;
        background: #000;
        border-radius: 10px;
        outline: none;
        cursor: pointer;
    }
    .player-progress input::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 15px;
        height: 15px;
        border-radius: 100%;
        background: #fff;
        cursor: pointer;
        outline: none;
        cursor: pointer;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 1);
        transition: all 0.2s;
    }
    .media-content,
    .card-image {
        background-color: #f8f8f8;
    }
    .media-left {
        ul {
            li {
                border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                &:hover {
                    cursor: pointer;
                    background-color: #f8f8f8;
                }

                &.active {
                    background-color: #f8f8f8;
                }
            }
        }
        @media (max-width: 1024px) {
            width: 100%;
        }
    }

    .button-container {
        @include from($touch) {
            max-width: 50%;
            margin: 0 auto;
        }

        #paypal-button-container {
            width: 50%;
            z-index: 0 !important;
        }

        button {
            @include from($touch) {
                width: 50%;
                height: 40px;
            }
        }
    }
</style>
