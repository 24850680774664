<template>
    <div>
        <section class="section has-background-primary">
            <div class="container has-navbar">
                <h1 class="has-text-white has-font-borest">{{ t.books }}</h1>
                <div class="columns is-mobile is-multiline mt-16-desktop mt-8-mobile">
                    <div class="column is-4-desktop is-12-touch">
                        <b-carousel
                            ref="prodCar"
                            class="product-gallery"
                            :autoplay="false"
                            v-model="prodIndex"
                            :pause-info="false"
                            :overlay="gallery"
                            @click="switchGallery(true)">
                            <b-carousel-item
                                v-for="(item, i) in images"
                                :key="i">
                                <figure class="image">
                                    <img
                                        :src="item"
                                        alt="" />
                                </figure>
                            </b-carousel-item>
                            <span
                                v-if="gallery"
                                @click="switchGallery(false)"
                                class="modal-close is-large" />
                        </b-carousel>
                    </div>
                    <div class="column is-offset-1-desktop is-12-touch">
                        <p class="has-text-white is-size-3-desktop is-size-3-tablet is-size-4-mobile has-font-lota-light has-text-weight-bold">
                            {{ product.name[getLocale] }}
                        </p>
                        <div class="is-flex is-flex-direction-row my-16-desktop mt-8-mobile mb-16-mobile">
                            <div>
                                <h4 class="p-4 is-size-4 has-has-text-weight-bold price">€{{ product.unit_amount.toFixed(2) }}</h4>
                            </div>
                            <div
                                class="ml-8-desktop mx-auto-mobile"
                                v-if="product.purchasable">
                                <b-field
                                    custom-class="is-size-5 has-text-black is-uppercase has-font-lota-light has-text-weight-bold"
                                    class="be-select ml-4-touch"
                                    :label="t.quantities">
                                    <b-select
                                        class="is-size-5"
                                        v-model="quantity">
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="other">{{ t.other }}</option>
                                    </b-select>
                                </b-field>

                                <b-field>
                                    <b-input
                                        v-if="quantity == 'other'"
                                        v-model="otherQuantity" />
                                </b-field>
                            </div>
                        </div>
                        <SocialButtons
                            :centered="false"
                            iconColor="#93bfb7" />

                        <b-button
                            class="mt-4"
                            type="is-secondary"
                            size="is-medium"
                            v-if="product.purchasable"
                            @click="buy"
                            >{{ t.add }}</b-button
                        >
                        <b-button
                            class="mt-4"
                            type="is-secondary"
                            size="is-medium"
                            v-else
                            @click="buy"
                            >{{ t.goTo }}</b-button
                        >

                        <h4 class="has-text-white is-size-5 has-font-lota-light has-text-weight-bold mt-12 mb-8">
                            {{ t.description }}
                        </h4>
                        <div class="desc-box p-12-desktop p-6-touch mb-16">
                            <p class="is-uppercase has-font-lota tw-whitespace-break-spaces" v-html="product.description[getLocale]">
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <BannerLinks />
    </div>
</template>

<script>
    import SocialButtons from '@/components/store/SocialButtons.vue';
    import BannerLinks from '@/components/store/BannerLinks.vue';
    import { getProducts } from '@/api/store';
    import Hammer from 'hammerjs';
    export default {
        name: 'ProductId',
        components: {
            SocialButtons,
            BannerLinks,
        },
        data() {
            return {
                quantity: 1,
                images: [],
                product: [],
                cart: [],
                otherQuantity: null,
                prodIndex: 0,
                gallery: false,
            };
        },
        methods: {
            switchGallery(value) {
                this.gallery = value;
                if (value) {
                    return document.documentElement.classList.add('is-clipped');
                } else {
                    return document.documentElement.classList.remove('is-clipped');
                }
            },
            startEvent() {
                // this.$nextTick(() => {
                const carousel = this.$refs.prodCar;
                const hammer = new Hammer(carousel.$el);
                hammer.on('swipeleft', () => {
                    carousel.next();
                });
                hammer.on('swiperight', () => {
                    carousel.prev();
                });
                // });
            },
            buy() {
                if (this.product.purchasable == true) {
                    if (this.quantity == 'other') {
                        this.product.quantity = parseInt(this.otherQuantity);
                    } else this.product.quantity = this.quantity;

                    let existArray = this.cart.filter((product) => product._id == this.product._id);

                    if (existArray.length == 0) {
                        this.cart.push(this.product);
                        this.setCart(this.cart);
                        this.$buefy.toast.open({
                            duration: 3000,
                            message: this.t.message,
                            position: 'is-top',
                            type: 'is-primary',
                        });
                    } else {
                        for (let index = 0; index < this.cart.length; index++) {
                            if (this.cart[index]._id == this.product._id) {
                                this.cart[index].quantity = this.cart[index].quantity + parseInt(this.product.quantity);
                                this.setCart(this.cart);
                                this.$buefy.toast.open({
                                    duration: 3000,
                                    message: this.t.message,
                                    position: 'is-top',
                                    type: 'is-primary',
                                });
                                break;
                            }
                        }
                    }
                } else {
                    window.open(this.product.url, '_blank');
                }
            },
        },

        computed: {
            t() {
                return this.$t('views.Product');
            },
        },
        created() {
            this.cart = this.getCart;

            getProducts({ _id: this.$route.params.id })
                .then((response) => {
                    this.images = response.data.products[0].images;
                    this.product = response.data.products[0];
                    this.startEvent();
                })
                .catch((error) => {
                    throw error;
                });
        },
    };
</script>

<style lang="scss" scoped>
    @import '~bulma/sass/utilities/mixins.sass';
    h1 {
        font-size: 8rem;
        @include mobile {
            font-size: 6rem;
        }
    }
    .price {
        border: 1px solid white;
        width: fit-content;
    }

    .desc-box {
        border: 2px solid black;
    }
</style>

<style>
    .product-gallery figure {
        height: 600px;
    }

    html.is-clipped .product-gallery figure {
        height: 100dvh;
        height: 100vh;
    }

    .product-gallery figure img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
</style>
