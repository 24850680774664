import store from '@/store/index';
import router from '@/router/index';

export default function () {
    router.beforeEach((to, from, next) => {
        const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

        if (requiresAuth) {
            let token = store.getters['auth/getToken'];

            if (token) {
                next();
            } else next({ name: 'Login' });
        } else next();

        const onlyNoAuth = to.matched.some((record) => record.meta.onlyNoAuth);

        if (onlyNoAuth) {
            let token = store.getters['auth/getToken'];
            if (!token) {
                next();
            } else {
                next({ name: 'Home' });
            }
        } else {
            next();
        }
    });
}
