<template>
    <div
        :class="centered ? 'is-centered' : 'is-justify-content-flex-start'"
        class="columns is-mobile is-multiline">
        <div class="column">
            <a
                href="https://www.instagram.com/belovebeyou/?hl=en"
                target="_blank">
                <b-button
                    :style="{ color: iconColor }"
                    class="button-social"
                    rounded
                    type="is-white">
                    <b-icon
                        custom-size="is-size-4 is-vertically-aligned"
                        icon="instagram"></b-icon> </b-button
            ></a>
        </div>
        <div class="column">
            <a
                href="https://pt-pt.facebook.com/pages/category/Health---Wellness-Website/Be-Love-Be-You-1739647529592163/"
                target="_blank">
                <b-button
                    :style="{ color: iconColor }"
                    class="button-social"
                    rounded
                    type="is-white">
                    <b-icon
                        custom-size="is-size-4 is-vertically-aligned"
                        icon="facebook"></b-icon> </b-button
            ></a>
        </div>
        <div class="column">
            <a
                href="https://twitter.com/belovebeyoufg"
                target="_blank">
                <b-button
                    :style="{ color: iconColor }"
                    class="button-social"
                    rounded
                    type="is-white">
                    <b-icon
                        custom-size="is-size-4 is-vertically-aligned"
                        icon="twitter"></b-icon> </b-button
            ></a>
        </div>
        <div class="column">
            <a
                href="https://pt.linkedin.com/in/ana-garcez-3a166338"
                target="_blank">
                <b-button
                    :style="{ color: iconColor }"
                    class="button-social"
                    rounded
                    type="is-white">
                    <b-icon
                        custom-size="is-size-4 is-vertically-aligned"
                        icon="linkedin"></b-icon>
                </b-button>
            </a>
        </div>
        <!-- <div class="column">
			<b-button :style="{ color: iconColor }" class="button-social" rounded type="is-white">
				<b-icon custom-size="is-size-4 is-vertically-aligned" icon="share-variant"></b-icon>
			</b-button>
		</div> -->
    </div>
</template>

<script>
    export default {
        name: 'SocialButtons',
        props: {
            centered: {
                type: Boolean,
                default: true,
            },
            iconColor: {
                type: String,
                default: '#eaeaea',
            },
        },
    };
</script>

<style lang="scss" scoped>
    .column {
        flex-grow: unset;
    }
    .button-social {
        width: 45px;
        height: 45px;
        padding: 0px;
    }
</style>
