<template>
    <div>
        <section class="hero is-fullheight is-relative pt-20">
            <div class="hero-body is-flex-direction-column is-justify-content-center">
                <div class="columns is-mobile is-multiline is-fullwidth">
                    <div class="column is-8-desktop is-offset-2-desktop is-6-widescreen is-offset-3-widescreen is-12-touch">
                        <form
                            class="form has-text-centered pb-4"
                            @submit.prevent="submitForm">
                            <p class="has-font-reft has-text-black has-text-centered">{{ t.form }}</p>

                            <input
                                type="text"
                                class="has-text-black has-font-lota"
                                :placeholder="t.name"
                                v-model="name"
                                required />

                            <input
                                type="email"
                                placeholder="EMAIL"
                                class="has-text-black has-font-lota mt-4"
                                v-model="email"
                                required />

                            <input
                                type="text"
                                :placeholder="t.companyName"
                                class="has-text-black has-font-lota mt-4"
                                v-model="company" />

                            <input
                                type="text"
                                :placeholder="t.event"
                                class="has-text-black has-font-lota mt-4"
                                v-model="event" />

                            <input
                                type="text"
                                :placeholder="t.location"
                                class="has-text-black has-font-lota mt-4"
                                v-model="location" />

                            <input
                                type="number"
                                :placeholder="t.budget"
                                class="has-text-black has-font-lota mt-4"
                                v-model="budget"
                                min="0"
                                oninput="this.value = Math.abs(this.value)" />

                            <textarea
                                cols="30"
                                rows="2"
                                v-model="service"
                                class="has-text-black has-font-lota mt-4"
                                :placeholder="t.service"></textarea>

                            <b-datepicker
                                :placeholder="t.date"
                                icon="calendar-today"
                                :min-date="today"
                                class="has-text-black has-font-lota mt-4 picker"
                                size="is-small"
                                v-model="date"
                                editable>
                            </b-datepicker>

                            <textarea
                                cols="30"
                                rows="10"
                                v-model="message"
                                class="has-text-black has-font-lota mt-4"
                                :placeholder="t.message"></textarea>

                            <b-button
                                rounded
                                type="is-primary"
                                class="send-btn is-flex mx-auto my-6"
                                native-type="submit"
                                :loading="isLoading"
                                >{{ t.send }}</b-button
                            >
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import { workshopForm } from '@/api/form';

    export default {
        data() {
            return {
                minDate: null,
                name: null,
                email: null,
                company: null,
                event: null,
                location: null,
                budget: null,
                service: null,
                date: new Date(),
                message: null,
                isLoading: false,
                today: new Date(),
            };
        },

        computed: {
            t() {
                return this.$t('views.Form');
            },
        },

        methods: {
            submitForm() {
                this.isLoading = true;
                let data = {
                    email: this.email,
                    name: this.name,
                    company: this.company,
                    event: this.event,
                    location: this.location,
                    budget: this.budget,
                    type: this.service,
                    date: this.date,
                    message: this.message,
                };

                workshopForm(data)
                    .then((response) => {
                        this.name = null;
                        this.email = null;
                        this.question = null;
                        this.goToPage('Answer');
                    })
                    .catch((error) => {
                        this.$buefy.toast.open({
                            message: this.t.error,
                            position: 'is-top',
                            type: 'is-secondary',
                        });
                        throw error;
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';
    @import '@/assets/scss/mixins.scss';

    .form {
        background-color: #e2e2e2;
        border-radius: 48px;

        p {
            font-size: 4rem;
        }

        input,
        textarea,
        .picker {
            width: 90%;
            background-color: #f7f7f7;
            border: none;
            padding: 0.2rem;
        }

        .picker {
            margin: 0 auto;
        }

        textarea {
            resize: none;
            @include scrollbar($dark);
        }

        input::placeholder,
        textarea::placeholder {
            color: black;
        }
    }
</style>
