<template>
    <div class="background">
        <section class="hero is-fullheight is-relative">
            <div class="hero-body is-flex-direction-column is-justify-content-center">
                <div class="columns is-mobile is-fullwidth">
                    <div class="column is-12-touch is-5-fullhd is-offset-2-fullhd is-7-widescreen is-7-desktop">
                        <div class="has-text-centered-desktop">
                            <h1 class="has-text-white has-font-borest">Be Love</h1>
                            <h2 class="has-text-white has-font-sacramento">Be You</h2>
                        </div>
                    </div>
                </div>
                <div class="columns is-mobile is-centered is-fullwidth">
                    <div class="column is-6-fullhd is-offset-4-fullhd is-8-widescreen">
                        <p class="has-text-white is-size-6-desktop is-size-5-fullhd is-size-5-tablet is-size-6-mobile has-border-white has-font-lota-light has-text-weight-bold has-text-weight-bold">
                            {{ t.concept }}
                        </p>
                    </div>
                </div>
            </div>
            <ScrollArrow />
        </section>
        <section
            id="health-coach"
            class="section">
            <div class="container">
                <h1 class="has-text-white has-font-borest">Health Coach</h1>
                <div class="columns is-mobile is-centered is-multiline mt-8-desktop mt-15-touch">
                    <div class="column is-6-desktop is-12-touch has-text-white">
                        <div class="texts">
                            <h2
                                class="has-font-sacramento"
                                id="concept">
                                {{ t.whatIsHealthCoach }} {{ t.nutrition }}
                            </h2>
                            <p
                                class="has-font-lota-light has-text-weight-bold has-text-weight-bold mt-8"
                                v-html="t.healthCoachExplanation"></p>

                            <h2 class="has-font-borest mt-12">
                                {{ t.expect }}
                            </h2>
                            <p class="has-font-lota-light has-text-weight-bold mt-8">
                                {{ t.sessions }}
                                <br />
                                {{ t.trip }}
                                <br />
                                {{ t.knowledge }}
                            </p>

                            <h2 class="has-font-borest mt-12">
                                {{ t.offer }}
                            </h2>

                            <p class="has-font-lota-light has-text-weight-bold mt-8">
                                {{ t.services }}
                            </p>
                            <div class="mt-8">
                                <a
                                    @click="goToPage('Store')"
                                    class="has-text-white is-underlined">
                                    {{ t.seeAll }}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="column is-6-desktop is-12-touch">
                        <figure class="image mt-8-touch">
                            <img
                                class="is-rounded mx-auto"
                                src="@/assets/images/belovebeyou/coach.jpg"
                                alt="" />
                        </figure>
                        <h2
                            class="mt-8 has-text-centered has-font-sacramento has-text-white"
                            v-html="t.slogan"></h2>
                    </div>
                </div>

                <div
                    id="contact"
                    class="columns is-mobile is-multiline is-centered mt-16-touch mt-12-desktop">
                    <div class="column is-6-desktop is-12-touch is-relative">
                        <div class="form-background"></div>
                        <div class="columns is-mobile is-centered">
                            <div class="column is-8-widescreen is-10-desktop is-8-tablet is-11-mobile">
                                <form
                                    id="form-contact"
                                    @submit.prevent="submitForm"
                                    class="is-flex is-flex-direction-column">
                                    <h3 class="has-font-borest">{{ t.help }}</h3>
                                    <input
                                        class="my-4 p-1 has-font-lota"
                                        type="text"
                                        name="name"
                                        :placeholder="t.name"
                                        v-model="name"
                                        required />
                                    <input
                                        class="my-4 p-1 has-font-lota"
                                        type="email"
                                        name="email"
                                        placeholder="EMAIL"
                                        v-model="email"
                                        required />
                                    <textarea
                                        class="my-4 p-1 has-font-lota"
                                        name=""
                                        id=""
                                        cols="30"
                                        rows="10"
                                        :placeholder="t.question"
                                        v-model="question"
                                        required></textarea>
                                    <div>
                                        <b-button
                                            rounded
                                            type="is-secondary"
                                            class="my-4"
                                            native-type="submit"
                                            :loading="isLoading"
                                            >{{ t.send }}</b-button
                                        >
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="column is-6-desktop is-12-touch is-hidden-touch">
                        <figure class="image">
                            <img
                                class="mx-auto"
                                src="@/assets/images/belovebeyou/milk2.jpg"
                                alt="" />
                        </figure>
                    </div>
                </div>
            </div>
        </section>
        <b-carousel
            :has-drag="false"
            ref="testCar"
            :autoplay="false"
            v-model="testIndex"
            id="testimonials"
            class="hero is-large">
            <b-carousel-item
                v-for="(testimonial, i) in testimonials"
                :key="i">
                <div
                    class="columns is-centered is-mobile py-16"
                    :style="{ 'background-color': testimonial.color }">
                    <div class="column is-6-desktop is-10-touch">
                        <div class="is-flex is-flex-direction-column mb-12">
                            <div class="is-flex is-flex-direction-column is-align-items-center">
                                <figure class="image is-250x250">
                                    <img
                                        class="is-rounded tw-w-full tw-h-full tw-object-cover"
                                        :src="testimonial.image"
                                        alt="" />
                                </figure>
                                <h3 class="has-font-sacramento has-text-centered mt-4 is-size-1">{{ testimonial.name }}</h3>
                                <h4 class="has-font-lota-light has-text-weight-bold has-text-centered is-size-4">{{ testimonial.profession[$i18n.locale] }}</h4>
                            </div>
                            <div class="has-border-white is-size-5-desktop is-size-5-tablet is-size-6-mobile mt-4">
                                <p class="has-font-lota has-text-white">{{ testimonial.testimonial[$i18n.locale] }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </b-carousel-item>
        </b-carousel>
    </div>
</template>

<script>
    import { getTestimonials } from '@/api/beLoveBeYou';
    import { newForm } from '@/api/form';
    import ScrollArrow from '@/components/shared/ScrollArrow.vue';
    import Hammer from 'hammerjs';

    export default {
        components: {
            ScrollArrow,
        },

        data() {
            return {
                testimonials: [],
                name: null,
                email: null,
                question: null,
                isLoading: false,
                testIndex: 0,
            };
        },

        mounted() {
            addEventListener('resize', this.setFormHeight);
            this.setFormHeight();
            const carousel = this.$refs.testCar;
            const hammer = new Hammer(carousel.$el);
            hammer.on('swipeleft', () => {
                carousel.next();
            });
            hammer.on('swiperight', () => {
                carousel.prev();
            });
        },
        created() {
            getTestimonials()
                .then((response) => {
                    this.testimonials = response.data.testimonials.filter((t) => t.accept);
                })
                .catch((error) => {
                    throw error;
                });
        },
        computed: {
            t() {
                return this.$t('views.BeLoveBeYou');
            },
        },
        methods: {
            setFormHeight() {
                const form = document.getElementById('form-contact');
                const formHeight = form.offsetHeight;
                document.documentElement.style.setProperty('--form-height', `${formHeight}px`);
                const texts = document.querySelector('#health-coach .texts');
                const textsHeight = texts.offsetHeight;
                document.documentElement.style.setProperty('--texts-height', `${textsHeight}px`);
            },
            getColor(color) {
                return color;
            },

            submitForm() {
                this.isLoading = true;
                let data = {
                    name: this.name,
                    email: this.email,
                    question: this.question,
                };

                newForm(data)
                    .then((response) => {
                        this.name = null;
                        this.email = null;
                        this.question = null;

                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.t.questionSend,
                            position: 'is-top',
                            type: 'is-primary',
                        });
                    })
                    .catch((error) => {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.t.error,
                            position: 'is-top',
                            type: 'is-secondary',
                        });
                        throw error;
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
        },
        destroyed() {
            removeEventListener('resize', this.setFormHeight);
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';
    @import '@/assets/scss/mixins.scss';

    .background {
        background-image: url('../assets/images/belovebeyou/gradiente_belovebeyou2.jpg');
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: $secondary;
        z-index: -2;

        .hero {
            h1,
            h2 {
                line-height: 1.2em;
            }
            h1 {
                font-size: 8rem;
                @include until($fullhd) {
                    font-size: 7rem;
                }
            }
            h2 {
                font-size: 6rem;
                @include until($fullhd) {
                    font-size: 5rem;
                }
            }
        }

        @include mobile {
            .hero {
                h1,
                h2 {
                    line-height: 1.2em;
                }
                h1 {
                    font-size: 4rem;
                }
                h2 {
                    font-size: 4rem;
                }
                p {
                    padding: 1.5em !important;
                }
            }
        }
    }

    #health-coach {
        h1 {
            font-size: 10rem;
            @include until($fullhd) {
                font-size: 8rem;
            }
        }
        h2 {
            font-size: 4rem;
            line-height: 1.2em;
            @include until($fullhd) {
                font-size: 3rem;
            }
        }
        h3 {
            font-size: 2.5rem;
            line-height: 1.2em;
        }

        input,
        textarea {
            border: unset !important;
            &:focus-visible {
                outline: unset !important;
            }
        }

        & > div > div.columns:first-of-type {
            img {
                max-height: var(--texts-height);
                width: auto;
            }
        }

        #form-contact,
        #form-contact * {
            z-index: 1;
        }

        #contact {
            figure,
            img {
                height: var(--form-height);
                width: auto;
            }
            @include mobile {
                h3 {
                    font-size: 2rem;
                    line-height: 1.2em;
                    margin-bottom: 1rem;
                }
            }
        }

        .form-background {
            z-index: 0;
            position: absolute;
            border-bottom-right-radius: 2rem;
            border-top-right-radius: 2rem;
            top: -1.5rem;
            left: -100vw;
            right: 0;
            background-color: $grey-lighter;
            height: calc(var(--form-height) + 4rem);
        }

        @include mobile {
            h1 {
                font-size: 5.5rem;
                line-height: 1.2em;
            }
            h2 {
                font-size: 3.3rem;
                line-height: 1.4em;
            }
        }
    }

    #testimonials {
        img {
            opacity: 0.7;
        }
        .is-250x250 {
            height: 15.625rem;
            width: 15.625rem;
        }

        .has-border-white {
            padding: 1rem !important;
        }
        p {
            height: 11.25rem;
            overflow-y: auto;
            margin-left: 5px;
            @include scrollbar($cyan, $white);

            @include touch() {
                height: 15.625rem;
                padding: 0rem !important;
            }
        }
    }
</style>
