import store from '@/store/index';

export default {
    computed: {
        getLocale() {
            return store.getters['language/getLanguage'];
        },
    },

    methods: {
        changeLanguage(language) {
            store.commit('language/setLanguage', language);
        },
    },
};
