<template>
    <div class="scroll-arrow-container is-flex is-flex-direction-column is-justify-content-space-between">
        <p class="scroll-text">SCROLL</p>

        <div
            id="scroll-down"
            class="is-flex-grow-1"></div>
        <span class="arrow-down"> </span>
    </div>
</template>

<script>
    export default {
        name: 'ScrollArrow',
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';
    .scroll-arrow-container {
        position: absolute;
        height: 10.625rem;
        right: 4.6875rem;
        bottom: 0%;
        transform: translateY(-100%);
        @include until($touch) {
            right: 0.75rem;
        }
    }

    .scroll-text {
        font-size: 0.8rem;
        letter-spacing: 2px;
        font-family: 'Lota Grotesque';
        writing-mode: vertical-rl;
        color: $pink;
        @include until($touch) {
            font-size: 0.7rem;
        }
    }

    //Arrow down efect
    .arrow-down {
        display: block;
        margin: 0 auto;
        width: 10px;
        // height: 38px;
    }

    .arrow-down:after {
        content: '';
        display: block;
        margin: 0;
        padding: 0;
        width: 8px;
        height: 8px;
        border-top: 0.125rem solid $pink;
        border-right: 0.125rem solid $pink;
        -moz-transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        -ms-transform: rotate(135deg);
        transform: rotate(135deg);
        margin-left: 0.0625rem;
    }

    #scroll-down::before {
        -webkit-animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
        /* Safari 4+ */

        -moz-animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
        /* Fx 5+ */

        -o-animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
        /* Opera 12+ */

        animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
        /* IE 10+, Fx 29+ */

        position: absolute;
        top: 0px;
        left: 50%;
        margin-left: -1px;
        width: 2px;
        height: calc(100% - 4rem - 0.5625rem);
        background: $pink;
        content: ' ';
        margin-top: 4rem;
    }
    @-webkit-keyframes elasticus {
        0% {
            -webkit-transform-origin: 0% 0%;
            -ms-transform-origin: 0% 0%;
            -moz-transform-origin: 0% 0%;
            -o-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
            -webkit-transform: scale(1, 0);
            -ms-transform: scale(1, 0);
            -moz-transform: scale(1, 0);
            -o-transform: scale(1, 0);
            transform: scale(1, 0);
        }
        50% {
            -webkit-transform-origin: 0% 0%;
            -ms-transform-origin: 0% 0%;
            -moz-transform-origin: 0% 0%;
            -o-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
            -webkit-transform: scale(1, 1);
            -ms-transform: scale(1, 1);
            -moz-transform: scale(1, 1);
            -o-transform: scale(1, 1);
            transform: scale(1, 1);
        }
        50.1% {
            -webkit-transform-origin: 0% 100%;
            -ms-transform-origin: 0% 100%;
            -moz-transform-origin: 0% 100%;
            -o-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
            -webkit-transform: scale(1, 1);
            -ms-transform: scale(1, 1);
            -moz-transform: scale(1, 1);
            -o-transform: scale(1, 1);
            transform: scale(1, 1);
        }
        100% {
            -webkit-transform-origin: 0% 100%;
            -ms-transform-origin: 0% 100%;
            -moz-transform-origin: 0% 100%;
            -o-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
            -webkit-transform: scale(1, 0);
            -ms-transform: scale(1, 0);
            -moz-transform: scale(1, 0);
            -o-transform: scale(1, 0);
            transform: scale(1, 0);
        }
    }
    @-moz-keyframes elasticus {
        0% {
            -webkit-transform-origin: 0% 0%;
            -ms-transform-origin: 0% 0%;
            -moz-transform-origin: 0% 0%;
            -o-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
            -webkit-transform: scale(1, 0);
            -ms-transform: scale(1, 0);
            -moz-transform: scale(1, 0);
            -o-transform: scale(1, 0);
            transform: scale(1, 0);
        }
        50% {
            -webkit-transform-origin: 0% 0%;
            -ms-transform-origin: 0% 0%;
            -moz-transform-origin: 0% 0%;
            -o-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
            -webkit-transform: scale(1, 1);
            -ms-transform: scale(1, 1);
            -moz-transform: scale(1, 1);
            -o-transform: scale(1, 1);
            transform: scale(1, 1);
        }
        50.1% {
            -webkit-transform-origin: 0% 100%;
            -ms-transform-origin: 0% 100%;
            -moz-transform-origin: 0% 100%;
            -o-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
            -webkit-transform: scale(1, 1);
            -ms-transform: scale(1, 1);
            -moz-transform: scale(1, 1);
            -o-transform: scale(1, 1);
            transform: scale(1, 1);
        }
        100% {
            -webkit-transform-origin: 0% 100%;
            -ms-transform-origin: 0% 100%;
            -moz-transform-origin: 0% 100%;
            -o-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
            -webkit-transform: scale(1, 0);
            -ms-transform: scale(1, 0);
            -moz-transform: scale(1, 0);
            -o-transform: scale(1, 0);
            transform: scale(1, 0);
        }
    }
    @-o-keyframes elasticus {
        0% {
            -webkit-transform-origin: 0% 0%;
            -ms-transform-origin: 0% 0%;
            -moz-transform-origin: 0% 0%;
            -o-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
            -webkit-transform: scale(1, 0);
            -ms-transform: scale(1, 0);
            -moz-transform: scale(1, 0);
            -o-transform: scale(1, 0);
            transform: scale(1, 0);
        }
        50% {
            -webkit-transform-origin: 0% 0%;
            -ms-transform-origin: 0% 0%;
            -moz-transform-origin: 0% 0%;
            -o-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
            -webkit-transform: scale(1, 1);
            -ms-transform: scale(1, 1);
            -moz-transform: scale(1, 1);
            -o-transform: scale(1, 1);
            transform: scale(1, 1);
        }
        50.1% {
            -webkit-transform-origin: 0% 100%;
            -ms-transform-origin: 0% 100%;
            -moz-transform-origin: 0% 100%;
            -o-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
            -webkit-transform: scale(1, 1);
            -ms-transform: scale(1, 1);
            -moz-transform: scale(1, 1);
            -o-transform: scale(1, 1);
            transform: scale(1, 1);
        }
        100% {
            -webkit-transform-origin: 0% 100%;
            -ms-transform-origin: 0% 100%;
            -moz-transform-origin: 0% 100%;
            -o-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
            -webkit-transform: scale(1, 0);
            -ms-transform: scale(1, 0);
            -moz-transform: scale(1, 0);
            -o-transform: scale(1, 0);
            transform: scale(1, 0);
        }
    }
    @keyframes elasticus {
        0% {
            -webkit-transform-origin: 0% 0%;
            -ms-transform-origin: 0% 0%;
            -moz-transform-origin: 0% 0%;
            -o-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
            -webkit-transform: scale(1, 0);
            -ms-transform: scale(1, 0);
            -moz-transform: scale(1, 0);
            -o-transform: scale(1, 0);
            transform: scale(1, 0);
        }
        50% {
            -webkit-transform-origin: 0% 0%;
            -ms-transform-origin: 0% 0%;
            -moz-transform-origin: 0% 0%;
            -o-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
            -webkit-transform: scale(1, 1);
            -ms-transform: scale(1, 1);
            -moz-transform: scale(1, 1);
            -o-transform: scale(1, 1);
            transform: scale(1, 1);
        }
        50.1% {
            -webkit-transform-origin: 0% 100%;
            -ms-transform-origin: 0% 100%;
            -moz-transform-origin: 0% 100%;
            -o-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
            -webkit-transform: scale(1, 1);
            -ms-transform: scale(1, 1);
            -moz-transform: scale(1, 1);
            -o-transform: scale(1, 1);
            transform: scale(1, 1);
        }
        100% {
            -webkit-transform-origin: 0% 100%;
            -ms-transform-origin: 0% 100%;
            -moz-transform-origin: 0% 100%;
            -o-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
            -webkit-transform: scale(1, 0);
            -ms-transform: scale(1, 0);
            -moz-transform: scale(1, 0);
            -o-transform: scale(1, 0);
            transform: scale(1, 0);
        }
    }
</style>
