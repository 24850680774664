<template>
    <div>
        <div class="columns is-mobile is-multiline pt-40-desktop pt-30-touch">
            <div class="column is-2-desktop is-12-touch mt-40-desktop">
                <div class="img-box center-inside">
                    <figure class="image">
                        <img
                            class="inn mx-auto"
                            src="../assets/images/consultory/Dr Rath Health Foundation.jpg"
                            alt="" />
                    </figure>
                </div>
            </div>
            <div class="column is-10-desktop mt-20-desktop mt-10-touch is-12-touch">
                <div class="columns is-mobile is-multiline">
                    <div class="column is-4-widescreen is-5-desktop is-12-touch">
                        <h1 class="has-font-lota has-text-black has-text-weight-bold has-text-centered-touch px-4-touch">{{ t.title }}</h1>
                        <p class="has-font-lota has-text-black mt-10-desktop mt-12-widescreen mt-6-touch pr-12-desktop px-4-touch">{{ t.text }}</p>
                    </div>

                    <div class="column is-12">
                        <div class="columns is-mobile is-multiline pt-20">
                            <div class="column is-4-fullhd is-4-desktop is-10-touch is-offset-1-touch">
                                <figure class="image">
                                    <img
                                        src="../assets/images/consultory/cience.jpg"
                                        alt="" />
                                </figure>
                            </div>
                            <div class="column is-7-widescreen is-8-desktop is-6-fullhd is-12-touch">
                                <p class="has-font-lota has-text-black pr-16-desktop mt-6-touch px-4-touch -tw-translate-y-1">{{ t.textTwo }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            t() {
                return this.$t('views.Consultory');
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';

    .img-box {
        border: 1px solid black;
        width: 90%;
        height: 12.5rem;
        border-top-right-radius: 4.3rem;
        border-bottom-right-radius: 4.3rem;

        @include until($touch) {
            width: 96%;
        }
    }
    .inn {
        width: 70%;
        @include until($touch) {
            width: 50%;
        }
    }

    h1 {
        font-size: 1.5rem;
    }
</style>
