<template>
    <section class="hero is-fullheight">
        <div class="hero-body has-navbar center-inside">
            <div class="columns is-vcentered">
                <div
                    class="column"
                    v-if="order.length > 0">
                    <div class="center-inside">
                        <div class="image-container">
                            <figure class="image is-1by1">
                                <img
                                    :src="order[0].meditation.image"
                                    alt="" />
                            </figure>
                        </div>

                        <div class="ml-6">
                            <p class="has-font-refto is-size-2-desktop is-size-4-touch has-text-secondary">
                                {{ order[0].meditation.name }}
                            </p>
                            <p
                                v-for="track in order[0].meditation.tracks"
                                :key="track._id"
                                class="has-font-lota is-size-6 has-text-primary">
                                {{ track.name }}
                            </p>
                        </div>
                    </div>
                    <div class="mt-10 center-inside">
                        <b-button
                            type="is-primary"
                            size="is-large"
                            @click="download"
                            >Download</b-button
                        >
                    </div>
                </div>
                <div
                    v-else
                    class="column">
                    <p class="has-navbar has-text-centered has-text-primary is-size-3">
                        {{ t.noCode }}
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { getDownloadCode, updateDownloadCode } from '@/api/media';
    export default {
        data() {
            return {
                order: null,
                t: this.$t('views.MeditationsDownload'),
            };
        },

        methods: {
            download() {
                window.open(this.order[0].meditation.file_url, '_blank');

                let data = {
                    id: this.order[0]._id,
                    used: true,
                };

                updateDownloadCode(data)
                    .then((response) => {
                        this.goToPage('Home');
                    })
                    .catch((error) => {
                        this.$buefy.toast.open({
                            message: this.t.error,
                            position: 'is-top',
                            type: 'is-secondary',
                        });
                        throw error;
                    });
            },
        },

        created() {
            getDownloadCode(this.$route.query.code)
                .then((response) => {
                    this.order = response.data.orders;
                })
                .catch((error) => {
                    this.$buefy.toast.open({
                        message: this.t.error,
                        position: 'is-top',
                        type: 'is-secondary',
                    });
                    throw error;
                });
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';

    .image-container {
        width: 250px;

        @include until($mobile) {
            width: 200px;
        }
    }

    .has-navbar {
        margin-top: 0 !important;
    }
</style>
