import store from '@/store/index';

export default {
    computed: {
        getCart() {
            return store.getters['cart/getCart'];
        },
    },
    methods: {
        setCart(cart) {
            store.commit('cart/setCart', cart);
        },

        clearCart() {
            store.commit('cart/clearCart');
        },
    },
};
