export default {
    computed: {
        isMobile() {
            return this.$store.getters['app/isMobile'];
        },

        mustScroll() {
            return this.$store.getters['app/mustScroll'];
        },

        getSavedId() {
            return this.$store.getters['app/getSavedID'];
        },
        pageRequested() {
            return this.$store.getters['app/pageRequested'];
        },

        showSideMenu() {
            return this.$store.getters['app/getShowSideMenu'];
        },

        showMobileNav() {
            return this.$store.getters['app/getShowMobileNav'];
        },
    },
    methods: {
        updateWidth(width) {
            this.$store.commit('app/updateWidth', width);
        },

        setMustScroll(bol) {
            this.$store.commit('app/setMustScroll', bol);
        },
        setSavedID(id) {
            this.$store.commit('app/setSavedID', id);
        },
        setPageRequested(page) {
            this.$store.commit('app/setPageRequested', page);
        },

        setShowSideMenu(bol) {
            this.$store.commit('app/setShowSideMenu', bol);
        },

        setShowMobileNav(bol) {
            this.$store.commit('app/setShowMobileNav', bol);
        },
    },
};
