<template>
    <div>
        <section class="hero is-medium is-relative has-background-grey">
            <div class="hero-body is-flex-direction-column is-justify-content-center-desktop pb-20-desktop">
                <div class="columns is-mobile is-multiline is-fullwidth pt-20-desktop pt-10-touch">
                    <div class="column is-12-touch is-11-desktop is-offset-1-desktop has-text-centered-touch">
                        <h2 class="has-font-lota has-text-black">{{ t.all }}</h2>
                    </div>
                    <div class="column is-4-desktop is-offset-1-desktop has-text-centered-touch">
                        <h1 class="has-font-borest has-text-white">Podcast</h1>
                    </div>
                    <div class="column is-4-desktop is-relative search-column is-10-mobile is-8-tablet is-offset-2-tablet is-offset-1-mobile is-offset-1-mobile">
                        <b-input
                            rounded
                            :placeholder="t.search"
                            type="search"
                            icon="magnify"
                            icon-clickable
                            class="search has-font-lota has-text-black">
                        </b-input>
                    </div>
                </div>
            </div>
        </section>

        <div class="has-background-custom-grey">
            <section class="section">
                <div class="container">
                    <div class="columns is-mobile is-multiline">
                        <div class="column is-12 has-text-centered">
                            <h1 class="has-font-lota has-text-black">{{ t.allEpisodes }}</h1>
                        </div>
                        <div class="column is-12-desktop is-10-tablet is-12-mobile is-10-widescreen is-8-fullhd is-offset-2-fullhd is-offset-1-widescreen is-offset-1-tablet is-offset-0-desktop">
                            <div class="columns is-mobile is-multiline is-variable is-8-widescreen">
                                <div
                                    class="column is-4-widescreen is-4-desktop is-6-mobile is-4-tablet is-4-fullhd py-10-desktop"
                                    v-for="n in 9"
                                    :key="n">
                                    <figure class="image is-square">
                                        <img
                                            class="object-fit"
                                            src="../assets/images/belovebeyou/coach.jpg" />
                                    </figure>
                                    <p class="podcast-title has-font-lota has-text-black mt-4">NOME DO EPISÓDIO DE PODCAST</p>
                                    <p class="podcast-description has-font-lota-light has-text-black">04 Jan 2022</p>
                                </div>
                            </div>
                        </div>
                        <div class="column is-12 is-flex is-justify-content-center">
                            <b-icon
                                icon="chevron-left"
                                class="mt-1"
                                custom-size="mdi-36px"
                                type="is-black">
                            </b-icon>
                            <p class="mx-6 is-size-5">1/10</p>
                            <b-icon
                                icon="chevron-right"
                                class="mt-1"
                                custom-size="mdi-36px"
                                type="is-black">
                            </b-icon>
                        </div>
                        <div class="column is-12 is-flex is-justify-content-center mt-10">
                            <figure class="image mt-10 is-128x128">
                                <img src="../assets/images/podcast/itunes.png" />
                            </figure>
                            <figure class="image mt-10 ml-10 is-48x48">
                                <img src="../assets/images/podcast/spotify.png" />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <section class="hero is-small is-relative">
            <div class="hero-body is-flex-direction-column is-justify-content-flex-start">
                <h1
                    class="has-font-borest is-clickable ag"
                    @click="changePage('Home', '#ana-garcez')">
                    ANA GARCEZ
                </h1>
            </div>
        </section>
        <div class="bottom-background">
            <section class="hero is-fullheight is-relative">
                <div class="hero-body is-flex-direction-column is-justify-content-flex-start pr-0">
                    <div class="columns is-mobile is-fullwidth">
                        <div class="column is-6-desktop is-offset-6-desktop is-10-touch is-offset-2-touch has-text-right pr-0">
                            <div class="form-container p-8 has-text-left">
                                <p class="has-font-reft has-text-black">{{ t.lose }}</p>
                                <p class="has-font-reft has-text-black">{{ t.episode }}</p>

                                <input
                                    type="text"
                                    class="has-font-lota mt-6"
                                    placeholder="EMAIL" />
                                <br />

                                <b-button class="send-btn has-font-lota has-text-black mt-6"> {{ t.send }}</b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    import ScrollArrow from '@/components/shared/ScrollArrow.vue';
    export default {
        components: {
            ScrollArrow,
        },

        computed: {
            t() {
                return this.$t('views.AllPodcasts');
            },
        },

        methods: {
            changePage(name, hash) {
                this.$router.push({ name: name, hash: hash });
                this.setShowSideMenu(false);
                this.setShowMobileNav(false);
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';

    .has-background-grey {
        h2 {
            font-size: 2rem;
        }

        h1 {
            font-size: 6rem;
            line-height: 1em;

            @include from($widescreen) {
                font-size: 8rem;
            }
        }

        .search-column {
            display: flex;
            align-items: flex-end;
        }

        .search {
            width: 100%;
            /* background-color: #e8e8e8; */
        }
    }

    .has-background-custom-grey {
        background-color: #f7f7f7;
    }

    .ag {
        font-size: 2.5rem;
        text-align: center;
        color: black;

        &:hover {
            color: #8e8e8e;
        }
    }

    .bottom-background {
        background-image: url('../assets/images/podcast/podcast_mockup.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        z-index: -2;
        background-position-y: center;
        background-position-x: right;
    }

    .form-container {
        border-top: solid 2px black;
        border-bottom: solid 2px black;
        border-left: solid 2px black;
        border-top-left-radius: 49px;
        border-bottom-left-radius: 49px;
        width: 100%;

        p {
            font-size: 3rem;
            line-height: 1em;

            @include until($touch) {
                font-size: 2rem;
            }
        }

        input {
            width: 80%;
            background-color: #f7f7f7;
            border: none;

            &::placeholder {
                color: black;
            }

            @include until($mobile-l) {
                width: 100%;
            }
        }

        .send-btn {
            width: 5.3rem;
            height: 2.5rem;
            border-radius: 1.5rem;
            color: black;
        }
    }
</style>
