import { post, get, put, remove } from '@/utils/http';

export function getImages() {
    return get('/soul_states?isActive=true');
}
export function addImage({ name, image }) {
    let form = new FormData();
    form.append('image', image);
    form.append('name', name);

    return post('/soul_states', form);
}

export function addLike(id) {
    return put('/soul_states/likes', { id: id });
}

export function removeLike(id) {
    return remove('/soul_states/likes', { id: id });
}
