<template>
    <div>
        <section class="hero is-fullheight is-relative">
            <div class="hero-body is-flex-direction-column is-justify-content-end pb-0">
                <div class="columns is-mobile is-multiline is-fullwidth is-vcentered">
                    <div class="column is-6-desktop is-4-fullhd is-offset-2-fullhd is-12-touch mt-20-touch">
                        <form
                            class="form p-4"
                            @submit.prevent="submitForm">
                            <p class="has-text-black has-font-borest form-title">
                                {{ t.help }}
                            </p>

                            <input
                                type="text"
                                class="has-font-lota has-text-black my-3"
                                :placeholder="t.name"
                                v-model="name"
                                required />

                            <input
                                type="email"
                                class="has-font-lota has-text-black my-3"
                                placeholder="EMAIL"
                                v-model="email"
                                required />

                            <textarea
                                name=""
                                id=""
                                cols="30"
                                rows="10"
                                class="has-font-lota has-text-black my-3"
                                :placeholder="t.question"
                                v-model="question"
                                required></textarea>

                            <b-button
                                rounded
                                type="is-primary"
                                class="has-font-lota mt-4 mb-10"
                                native-type="submit"
                                :loading="isLoading"
                                >{{ t.send }}</b-button
                            >
                        </form>

                        <SocialButtons
                            class="pt-8"
                            :iconColor="'#BF8897'" />
                    </div>

                    <div class="column is-6-desktop is-4-fullhd is-12-touch">
                        <figure class="image">
                            <img
                                src="../assets/images/contacts/contacts.jpg"
                                alt="ana" />
                        </figure>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-relative custom-background">
            <div class="hero-body is-flex-direction-column is-justify-content-center">
                <div class="columns is-mobile is-multiline">
                    <div class="column is-6-desktop is-12-touch has-text-centered-touch is-offset-1-fullhd">
                        <h1 class="has-font-borest has-text-white">{{ t.workshops }}</h1>
                    </div>
                    <div class="column is-11-desktop is-12-touch is-8-fullhd is-offset-1-fullhd">
                        <p
                            class="has-font-lota has-text-white"
                            v-html="t.text"></p>
                    </div>
                    <div class="column is-12-desktop mt-10-desktop is-12-touch has-text-centered-touch is-offset-1-fullhd">
                        <b-button
                            rounded
                            type="is-white"
                            class="more-info-btn has-font-borest"
                            @click="goToPage('Form')"
                            >{{ t.moreInfo }}</b-button
                        >
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import SocialButtons from '@/components/store/SocialButtons';

    import { newForm } from '@/api/form';

    export default {
        components: {
            SocialButtons,
        },

        data() {
            return {
                name: null,
                email: null,
                question: null,
                isLoading: false,
            };
        },

        computed: {
            t() {
                return this.$t('views.Contacts');
            },
        },

        methods: {
            submitForm() {
                this.isLoading = true;
                let data = {
                    name: this.name,
                    email: this.email,
                    question: this.question,
                };

                newForm(data)
                    .then((response) => {
                        this.name = null;
                        this.email = null;
                        this.question = null;
                        this.goToPage('Answer');
                    })
                    .catch((error) => {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.t.error,
                            position: 'is-top',
                            type: 'is-secondary',
                        });
                        throw error;
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';
    @import '@/assets/scss/mixins.scss';

    form {
        background-color: #e2e2e2;
        height: auto;
        width: 100%;
        border-radius: 2rem;

        p {
            font-size: 2rem;
            @include mobile {
                font-size: 1.7rem;
                // margin-left: 0.5rem;
            }
        }

        input,
        textarea {
            width: 100%;
            background-color: #f7f7f7;
            border: none;
            padding: 0.3rem;
        }

        textarea {
            resize: none;
            @include scrollbar($dark);
        }

        input::placeholder,
        textarea::placeholder {
            color: black;
        }
    }

    .custom-background {
        background-color: #bfd9d1;

        h1 {
            font-size: 6rem;
            line-height: 1em;

            @include until($touch) {
                font-size: 4rem;
            }
            @include from($fullhd) {
                font-size: 8rem;
            }
        }

        p {
            font-size: 1.5rem;
            @include mobile {
                font-size: 1rem;
            }
        }

        .more-info-btn {
            font-size: 1.8rem;
            color: #5a5a5a;
            @include mobile {
                font-size: 1.4rem;
            }
        }
    }
</style>
