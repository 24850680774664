import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import language from './modules/language';
import auth from './modules/auth';
import cart from './modules/cart';
import app from './modules/app';

Vue.use(Vuex);
const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
});

export default new Vuex.Store({
    modules: { language, auth, cart, app },
    plugins: [vuexLocal.plugin],
});
