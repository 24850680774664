<template>
    <section class="hero is-fullheight">
        <div class="hero-body">
            <div class="columns is-vcentered is-mobile is-fullwidth is-multiline center-inside">
                <div class="column is-6-touch is-3-desktop">
                    <figure>
                        <img
                            src="../assets/icons/coracao_verde.png"
                            alt="" />
                    </figure>
                </div>
                <div class="column is-11">
                    <p class="has-text-centered has-font-sacramento is-size-1 has-text-secondary">{{ t.thanks }}</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        computed: {
            t() {
                return this.$t('views.ThankYou');
            },
        },

        created() {
            this.clearCart();
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';

    /*  img {
        width: 20%;

        @include until($tablet) {
            width: 60%;
        }
    } */
</style>
