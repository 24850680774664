import Vue from 'vue';

import getLocale from './shared/getLocale';
import goToPage from './shared/goToPage';
import user from './modules/user';
import cart from './modules/cart';
import app from './modules/app';

let mixins = [getLocale, goToPage, user, cart, app];

for (let mixin of mixins) Vue.mixin(mixin);
