<template>
    <div class="tw-flex tw-h-screen">
        <div class="tw-w-1/2 max-lg:tw-hidden">
            <img
                class="tw-w-full tw-h-full tw-object-cover tw-object-right"
                src="@/assets/images/login/login.jpg"
                alt="" />
        </div>
        <div class="tw-w-full max-lg:tw-px-4 lg:tw-w-1/2 tw-flex tw-justify-center tw-mx-auto">
            <form
                @submit.prevent="login"
                class="columns is-multiline tw-content-center tw-max-w-3xl tw-flex">
                <div class="column is-10-desktop is-offset-2-desktop is-12-touch has-text-centered-touch">
                    <h1 class="has-font-borest has-text-black">Login</h1>
                </div>
                <div class="column is-8-desktop is-7-fullhd is-offset-1-desktop is-12-touch my-6">
                    <div class="input-container p-10">
                        <input
                            type="email"
                            class="has-font-lota"
                            required
                            :placeholder="t.email"
                            v-model="email" />

                        <input
                            type="password"
                            class="has-font-lota"
                            required
                            placeholder="PASSWORD"
                            v-model="password" />
                    </div>
                </div>
                <div class="column is-4-desktop is-offset-1-desktop is-flex is-justify-content-center is-12-touch">
                    <b-button
                        :loading="isLoading"
                        native-type="submit"
                        class="login-button has-font-lota"
                        >{{ t.login }}</b-button
                    >
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import { login } from '@/api/login';
    export default {
        data() {
            return {
                email: null,
                password: null,
                isLoading: false,
                prevRoute: null,
            };
        },

        computed: {
            t() {
                return this.$t('views.Login');
            },
        },

        methods: {
            login() {
                this.isLoading = true;
                login(this.email, this.password)
                    .then((response) => {
                        this.addToken(response.headers.authorization);
                        this.addUser(response.data.body);
                        this.isLoading = false;
                        if (this.$route.params.route == 'Login' || this.$route.params.route == undefined) {
                            this.goToPage('Home');
                        } else this.goToPage(this.$route.params.route);
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.t.error,
                            position: 'is-top',
                            type: 'is-secondary',
                        });
                        throw error;
                    });
            },
        },

        created() {
            this.$buefy.snackbar.open({
                type: 'is-primary',
                message: this.t.message,
                actionText: 'Ok',
                indefinite: true,
                position: 'is-top',
            });
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';

    h1 {
        font-size: 6rem;
        line-height: 1em;

        @include from($widescreen) {
            font-size: 8rem;
        }
    }

    .input-container {
        width: 100%;
        background-color: #e8e8e8;
        border-radius: 5rem;
        height: 10rem;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
    }

    input {
        width: 100%;
        border: none;

        &::placeholder {
            color: black;
        }
    }
    .login-button {
        background-color: #bf8897;
        color: white;
        border: none;
        display: flex;
        width: auto;
        height: 2.5rem;
        border-radius: 2.5rem;
    }
</style>
