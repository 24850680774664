<template>
    <section class="section-seven">
        <div class="hero-body is-flex is-align-items-center is-justify-content-space-evenly">
            <h3
                class="has-text-white is-size-5 has-font-lota mb-3-touch is-clickable"
                @click="changePage('Store', '#categories')">
                {{ t.books }}
            </h3>
            <h3
                class="has-text-white is-size-5 has-font-lota my-3-touch is-clickable"
                @click="changePage('Store', '#cooking')">
                GARCEZ COOKING LIVE
            </h3>
            <h3
                class="has-text-white is-size-5 has-font-lota my-3-touch is-clickable"
                @click="changePage('Store', '#health')">
                HEALTH COACHING
            </h3>
            <h3
                class="has-text-white is-size-5 has-font-lota mt-3-touch is-clickable"
                @click="changePage('Store', '#meditations')">
                {{ t.meditations }}
            </h3>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'BannerLinks',

        data() {
            return {
                t: this.$t('components.Banner'),
            };
        },

        methods: {
            changePage(name, hash) {
                this.$router.push({ name: name, hash: hash });
                this.setShowSideMenu(false);
                this.setShowMobileNav(false);
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '~bulma/sass/utilities/mixins.sass';
    .section-seven {
        background: #c9c9c9;
        .is-flex {
            @include touch {
                flex-direction: column;
            }
        }
    }
</style>
