<template>
    <div>
        <section class="section">
            <div class="container">
                <h1 class="has-font-borest has-text-centered has-text-primary">
                    {{ t.reiki }}
                </h1>

                <div class="columns is-mobile is-multiline is-flex is-justify-content-center">
                    <div class="column is-12-mobile is-7-tablet is-4-desktop">
                        <figure class="image">
                            <img
                                src="@/assets/images/reiki/1-BL-cut.jpg"
                                alt="" />
                        </figure>
                    </div>
                </div>
                <div class="columns is-mobile is-multiline is-flex is-justify-content-center mb-0">
                    <div
                        class="column is-12-mobile is-7-tablet is-4-desktop"
                        v-for="(image, index) in images"
                        :key="index">
                        <figure class="image">
                            <img
                                :src="image.imgUrl"
                                alt="reiki-image" />
                        </figure>
                    </div>
                </div>
                <div class="column is-12 has-text-centered mt-10">
                    <b-button
                        rounded
                        class="is-primary p-6 has-font-lota"
                        @click="goToLink">
                        {{ t.signUp }}
                    </b-button>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import { getLink } from '@/api/reiki';
    export default {
        data() {
            return {
                url: null,
                images: [],
            };
        },

        computed: {
            t() {
                return this.$t('views.Reiki');
            },
        },

        methods: {
            goToLink() {
                if (this.isMobile) {
                    window.location.assign(this.url, '_blank');
                } else window.open(this.url, '_blank');
            },
        },

        created() {
            getLink().then((response) => {
                this.url = response.data.reiki.url;
            });
            this.images = [
                {
                    imgUrl: require('@/assets/images/reiki/2BL-cut.jpg'),
                },
                {
                    imgUrl: require('@/assets/images/reiki/3BL-cut.jpg'),
                },
                {
                    imgUrl: require('@/assets/images/reiki/4BL-cut.jpg'),
                },
                {
                    imgUrl: require('@/assets/images/reiki/5BL-cut.jpg'),
                },
                {
                    imgUrl: require('@/assets/images/reiki/6BL-cut.jpg'),
                },
                {
                    imgUrl: require('@/assets/images/reiki/7BL-cut.jpg'),
                },
                {
                    imgUrl: require('@/assets/images/reiki/8BL-cut.jpg'),
                },
                {
                    imgUrl: require('@/assets/images/reiki/9BL-cut.jpg'),
                },
                {
                    imgUrl: require('@/assets/images/reiki/10BL-cut.jpg'),
                },
                {
                    imgUrl: require('@/assets/images/reiki/11-BL-cut.jpg'),
                },
                {
                    imgUrl: require('@/assets/images/reiki/12BL-cut.jpg'),
                },
                {
                    imgUrl: require('@/assets/images/reiki/13BL-cut.jpg'),
                },
            ];
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';

    h1 {
        font-size: 8rem;

        @include until($touch) {
            font-size: 4rem;
            margin-block: 2rem;
        }
    }
</style>
