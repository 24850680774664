import store from '@/store/index';
import { ToastProgrammatic as Toast } from 'buefy';
import router from '@/router/index';

export default {
    computed: {
        getUser() {
            return store.getters['auth/getUser'];
        },
        getToken() {
            return store.getters['auth/getToken'];
        },
    },
    methods: {
        addUser(user) {
            store.commit('auth/addUser', user);
        },
        addToken(token) {
            store.commit('auth/setToken', token);
        },
        onSessionExpired() {
            store.commit('auth/resetStore');
            store.commit('auth/setToken', null);
            localStorage.removeItem('vuex');
        },
    },
};
