<template>
    <div>
        <div class="background">
            <section class="hero is-fullheight is-relative">
                <div class="hero-body is-flex-direction-column is-justify-content-center">
                    <div class="columns is-mobile is-fullwidth">
                        <div class="column is-12-touch is-4-desktop">
                            <div class="has-text-centered-desktop">
                                <h1 class="has-font-borest has-text-white pb-40-desktop">Blog</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="hero-foot pb-4">
                    <div class="columns is-mobile is-fullwidth">
                        <div class="column is-12-touch has-text-centered-touch is-offset-5-desktop is-7-desktop is-6-widescreen is-offset-7-widescreen is-5-fullhd is-offset-7-fullhd">
                            <h2 class="has-font-sacramento">{{ t.show }}</h2>
                            <h2 class="has-font-sacramento">{{ t.showTwo }}</h2>
                        </div>
                    </div>
                </div>
                <ScrollArrow />
            </section>
        </div>
        <section
            class="hero is-medium is-relative has-background-grey"
            id="blog-posts">
            <div class="hero-body is-flex-direction-column is-justify-content-center">
                <div class="columns is-fullwidth is-mobile is-flex is-justify-content-center is-multiline">
                    <div class="column is-10-desktop is-8-widescreen is-12-touch is-flex is-justify-content-end">
                        <div
                            @scroll="scrollPost"
                            id="posts-container"
                            class="columns is-fullwidth is-mobile pb-2">
                            <div
                                v-for="post in posts"
                                :key="post.id"
                                class="column is-4-tablet is-6-mobile"
                                :ref="post._id"
                                :id="post._id">
                                <figure
                                    class="image is-1by1 is-clickable"
                                    @click="seePostDetail(post._id)">
                                    <img
                                        :src="post.image"
                                        alt=""
                                        class="object-fit" />
                                </figure>
                                <p class="has-font-lota is-size-5-desktop is-size-7-touch is-size-6-widescreen is-size-5-fullhd has-text-black mt-2">{{ post.title[getLocale] }}</p>
                                <p class="has-font-lota is-size-7-desktop is-size-7-touch is-size-7-widescreen">{{ moment(post.createdAt).utc().format('DD MMMM YYYY') }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="column is-12 has-text-centered mt-2">
                        <p class="has-text-black has-font-lota-light has-text-weight-bold is-size-5-desktop is-size-6-touch">{{ t.drag }}</p>
                    </div>
                </div>
            </div>
        </section>

        <section
            class="hero is-relative has-background-white"
            id="categories-title">
            <div class="hero-body is-flex-direction-column is-justify-content-center">
                <div class="columns is-mobile is-centered">
                    <div class="column is-6-desktop is-offset-4-desktop">
                        <h1 class="has-font-lota-light has-text-weight-bold has-text-weight-bold has-text-centered has-text-black">{{ t.categories }}</h1>
                    </div>
                </div>
            </div>
        </section>
        <section
            class="hero is-relative has-background-secondary"
            id="categories-container">
            <div class="hero-body is-flex-direction-column is-justify-content-center">
                <div class="columns is-centered is-mobile is-multiline py-8-touch">
                    <div class="column is-4-desktop is-8-touch center-inside">
                        <figure class="image">
                            <img src="../assets/images/blog/porta_final.png" />
                        </figure>
                    </div>
                    <div class="column is-6-desktop is-12-touch is-flex is-justify-content-center">
                        <div class="columns is-centered is-mobile is-multiline category-title">
                            <div
                                class="column is-5-desktop is-6-touch icon-container is-clickable"
                                v-for="(category, index) in categories"
                                :key="index"
                                @click="goToPage(category.page)">
                                <div class="is-flex is-flex-direction-column is-justify-content-center is-align-content-center is-fullheight my-4">
                                    <div class="center-inside is-relative">
                                        <div class="plus center-inside">
                                            <b-icon
                                                class="is-clickable"
                                                icon="plus"
                                                size="is-small"
                                                type="is-secondary">
                                            </b-icon>
                                        </div>

                                        <div
                                            class="icon-category"
                                            :class="category.class"></div>
                                    </div>

                                    <p class="icon-title has-font-lota has-text-weight-semibold has-text-centered mt-5 is-clickable">{{ category.title }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import ScrollArrow from '@/components/shared/ScrollArrow.vue';

    import { getPosts } from '@/api/blog';
    export default {
        components: {
            ScrollArrow,
        },

        data() {
            return {
                posts: [],
                page: 1,
                limit: 5,
                totalItems: 0,
                carouselIndex: 0,
                fetchingPosts: false,
            };
        },

        methods: {
            scrollPost(e) {
                if (this.fetchingPosts) return;
                const { target } = e;

                if (Math.abs(target.scrollLeft) >= target.scrollWidth - target.clientWidth - 100) {
                    if (this.totalItems > 0) {
                        this.page = this.totalItems / this.limit;
                        this.totalItems = 0;
                    }

                    this.page++;

                    let query = {
                        page: this.page,
                        limit: this.limit,
                        order: 'desc',
                    };

                    this.fetchingPosts = true;

                    getPosts(query)
                        .then((response) => {
                            this.posts.push(...response.data.posts);
                        })
                        .catch((error) => {
                            throw error;
                        }).finally(() => {
                            this.fetchingPosts = false;
                        });
                }
            },

            seePostDetail(id) {
                if (this.totalItems > 0) {
                    this.setPageRequested(this.totalItems / this.limit);
                } else this.setPageRequested(this.page);

                this.setSavedID(id);
                this.$router.push({ name: 'Post', params: { id: id } });
                this.setShowSideMenu(false);
            },

            info(e) {},
        },

        created() {
            if (this.mustScroll) {
                this.totalItems = this.pageRequested * this.limit;

                this.setMustScroll(false);
                let query = {
                    page: this.page,
                    limit: this.totalItems,
                    order: 'desc',
                };
                getPosts(query)
                    .then((response) => {
                        this.posts = response.data.posts;
                    })
                    .catch((error) => {
                        throw error;
                    })
                    .finally(() => {
                        let elementTop = this.$refs[this.getSavedId][0].offsetTop;

                        window.scrollTo({
                            top: elementTop - 200,
                            behavior: 'smooth',
                        });

                        document.getElementById(this.getSavedId).scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                            inline: 'center',
                        });
                    });
            } else {
                let query = {
                    page: this.page,
                    limit: this.limit,
                    order: 'desc',
                };
                getPosts(query)
                    .then((response) => {
                        this.posts = response.data.posts;

                        if (this.mustScroll) {
                            this.carouselIndex = this.getSavedId;
                            document.querySelector('#blog-posts').scrollIntoView({
                                behavior: 'smooth',
                            });
                            this.setMustScroll(false);
                        }
                    })
                    .catch((error) => {
                        throw error;
                    });
            }
        },

        computed: {
            t() {
                return this.$t('views.Blog');
            },

            categories() {
                return [
                    {
                        title: this.t.food,
                        class: 'icon-carrot',
                        page: 'HealthyFood',
                    },
                    {
                        title: this.t.exercise,
                        class: 'icon-exercise',
                        page: 'Exercise',
                    },
                    {
                        title: this.t.meditate,
                        class: 'icon-meditate',
                        page: 'MeditatePosts',
                    },
                    {
                        title: this.t.all,
                        class: 'icon-all',
                        page: 'AllPosts',
                    },
                    {
                        title: this.t.wellBeing,
                        class: 'icon-heart',
                        page: 'WellBeing',
                    },
                    {
                        title: this.t.file,
                        class: 'icon-files',
                        page: 'Files',
                    },
                ];
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';
    @import '@/assets/scss/mixins.scss';

    .background {
        background-image: url('../assets/images/blog/blog.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        z-index: -2;
        background-position-x: left;
        background-position-y: top;
        // @include mobile {
        //     background-position: unset;
        // }

        h1,
        h2 {
            line-height: 1.2em;
        }
        h1 {
            font-size: 10rem;
            @include mobile {
                font-size: 8rem;
            }
            @include from($widescreen) {
                font-size: 9rem;
            }
        }

        h2 {
            font-size: 6rem;
            color: #6a4329;
            @include until($touch) {
                font-size: 3.5rem;
                &:last-of-type {
                    // margin-bottom: 5rem;
                }
            }

            @include from($widescreen) {
                font-size: 5rem;
            }
        }
    }

    #blog-posts {
        .carousel-list,
        .card {
            box-shadow: none !important;
        }

        .card {
            background-color: transparent;
        }

        .object-fit {
            object-fit: cover;
        }
        p {
            letter-spacing: 0;
        }
    }

    #categories-title h1 {
        font-size: 3rem;
        text-transform: uppercase;

        @include until($touch) {
            font-size: 2.5rem;
        }

        @include from($touch) {
            // transform: translateY(-1.5em);
        }
    }

    @mixin mask() {
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
    }

    #categories-container {
        .icon-category {
            width: 100%;
            height: 4rem;
            background: #e2aebc;
        }
        .icon-title {
            font-size: 1rem;
            color: #e2aebc;

            @include from($fullhd) {
                font-size: 1.3rem;
            }
        }

        .icon-carrot {
            mask: url('../assets/images/blog/Grupo_156.svg');
            @include mask();
            @include until($mobile-l) {
                height: 6.4rem;
            }
        }

        .icon-exercise {
            mask: url('../assets/images/blog/Grupo_152.svg');
            @include mask();
            @include until($mobile-l) {
                height: 6.4rem;
            }
        }

        .icon-meditate {
            mask: url('../assets/images/blog/Grupo_155.svg');
            height: 3.7rem;
            @include mask();
            @include until($mobile-l) {
                width: 6.4rem;
                height: 6.4rem;
            }
        }
        .icon-all {
            mask: url('../assets/images/blog/Grupo_153.svg');
            height: 3.8rem;
            @include mask();
            @include until($mobile-l) {
                width: 6.4rem;
                height: 6.4rem;
            }
        }
        .icon-files {
            mask: url('../assets/images/blog/Grupo_154.svg');
            height: 3.75rem;
            @include mask();
            @include until($mobile-l) {
                width: 6.4rem;
                height: 6.4rem;
            }
        }
        .icon-heart {
            mask: url('../assets/images/blog/coracao-01.svg');
            height: 3.2rem;
            @include mask();
            @include until($mobile-l) {
                width: 6.4rem;
                height: 6.4rem;
            }
        }

        .plus {
            background-color: white;
            border-radius: 99999px;
            width: 2.4rem;
            height: 2.4rem;
            padding: 0.5rem;
            position: absolute;
            right: 0;
            transform: translateY(-0.4em);
            visibility: hidden;
        }

        .is-fullheight {
            height: 100%;
        }

        .icon-container {
            position: relative;
            &:hover {
                color: white;

                .plus {
                    visibility: visible;
                    @include until($touch) {
                        font-size: 2.5rem;
                        visibility: hidden;
                    }
                }

                .icon-carrot,
                .icon-exercise,
                .icon-meditate,
                .icon-heart,
                .icon-all,
                .icon-files {
                    background: white;
                }

                .icon-title {
                    color: white;
                }
            }
        }
    }

    #posts-container {
        display: inline-flex;
        overflow-x: scroll;
        @include scrollbar($dark);
    }
</style>
