<template>
    <section>
        <nav
            class="navbar px-6-widescreen py-2-widescreen p-1-mobile is-fixed-top"
            role="navigation"
            aria-label="main navigation"
            :class="navbarBackground">
            <div class="navbar-brand">
                <figure class="image is-flex is-align-items-center ml-2">
                    <img
                        :src="getLogo"
                        alt="AG" />
                </figure>

                <a
                    role="button"
                    class="navbar-burger"
                    aria-label="menu"
                    aria-expanded="false"
                    data-target="navbarBasicExample"
                    @click="setShowMobileNav(!showMobileNav)"
                    :class="`${navbarText} ${showMobileNav ? 'is-active' : ''}`">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>

            <div
                id="navbarBasicExample"
                class="navbar-menu"
                :class="{ 'is-active': showMobileNav }">
                <div class="navbar-start ml-16-desktop ml-1-mobile">
                    <div
                        class="navbar-item mx-4-desktop has-dropdown is-hoverable"
                        :class="navbarText">
                        <a
                            class="navbar-link has-font-lota is-arrowless"
                            @click="goToPage('Home')"
                            :class="navbarText">
                            {{ t.about }}
                        </a>

                        <div
                            class="navbar-dropdown"
                            :class="navbarBackground">
                            <a
                                class="navbar-item sub-label pl-3 has-font-lota"
                                :class="navbarText"
                                @click="changePage('Home', '#ana-garcez')">
                                AG
                            </a>
                            <a
                                class="navbar-item sub-label pl-3 has-font-lota"
                                :class="navbarText"
                                @click="changePage('Home', '#mission')">
                                {{ t.mission }}
                            </a>
                            <a
                                class="navbar-item sub-label pl-3 has-font-lota"
                                :class="navbarText"
                                @click="changePage('Home', '#formation')">
                                {{ t.formation }}
                            </a>
                        </div>
                    </div>

                    <div class="navbar-item mx-4-desktop has-dropdown is-hoverable">
                        <a
                            class="navbar-link has-font-lota is-arrowless"
                            @click="goToPage('BeLoveBeYou')"
                            :class="navbarText">
                            BE LOVE BE YOU
                        </a>

                        <div
                            class="navbar-dropdown"
                            :class="navbarBackground">
                            <a
                                class="navbar-item sub-label pl-3 has-font-lota"
                                :class="navbarText"
                                @click="changePage('BeLoveBeYou', '#concept')">
                                {{ t.concept }}
                            </a>
                        </div>
                    </div>

                    <div class="navbar-item mx-4-desktop has-dropdown is-hoverable">
                        <a
                            class="navbar-link has-font-lota is-arrowless"
                            :class="navbarText"
                            @click="goToPage('GetInspired')">
                            {{ t.inspire }}
                        </a>

                        <div
                            class="navbar-dropdown"
                            :class="navbarBackground">
                            <a
                                class="navbar-item sub-label pl-3 has-font-lota"
                                :class="navbarText"
                                @click="changePage('GetInspired', '#health-coach')">
                                {{ t.healthCoach }}
                            </a>
                            <a
                                class="navbar-item sub-label pl-3 has-font-lota"
                                :class="navbarText"
                                @click="changePage('GetInspired', '#approach')">
                                {{ t.approach }}
                            </a>
                            <a
                                class="navbar-item sub-label pl-3 has-font-lota"
                                :class="navbarText"
                                @click="changePage('GetInspired', '#soul-states')">
                                {{ t.states }}
                            </a>
                        </div>
                    </div>

                    <div class="navbar-item mx-4-desktop has-dropdown is-hoverable">
                        <a
                            class="navbar-link has-font-lota is-arrowless"
                            :class="navbarText"
                            @click="goToPage('Media')">
                            MEDIA
                        </a>

                        <div
                            class="navbar-dropdown"
                            :class="navbarBackground">
                            <a
                                class="navbar-item sub-label pl-3 has-font-lota"
                                :class="navbarText"
                                @click="goToPage('Meditate')">
                                {{ t.meditate }}
                            </a>

                            <a
                                class="navbar-item sub-label pl-3 has-font-lota"
                                :class="navbarText"
                                @click="goToPage('Teachers')">
                                {{ t.teachers }}
                            </a>
                        </div>
                    </div>
                    <div class="navbar-item mx-4-desktop has-dropdown is-hoverable">
                        <a
                            class="navbar-link is-arrowless is-relative mt-4"
                            :class="navbarText"
                            @click="goToPage('Checkout')">
                            <span class="is-relative py-2-mobile">
                                <b-icon
                                    pack="bl-icons"
                                    icon="bl-cesto_compras"
                                    class="is-size-4">
                                </b-icon>
                                <div class="badge">
                                    {{ getCart.length }}
                                </div>
                            </span>
                        </a>
                    </div>
                    <div class="navbar-item has-dropdown is-hoverable is-hidden-widescreen">
                        <a
                            class="navbar-link is-arrowless has-font-lota"
                            :class="navbarText"
                            @click="goToPage('Blog')">
                            BLOG
                        </a>
                    </div>
                    <div class="navbar-item has-dropdown is-hoverable is-hidden-widescreen">
                        <a
                            class="navbar-link is-arrowless has-font-lota"
                            :class="navbarText"
                            @click="goToPage('Store')">
                            {{ t.store }}
                        </a>
                    </div>
                    <div class="navbar-item has-dropdown is-hoverable is-hidden-widescreen">
                        <a
                            class="navbar-link is-arrowless has-font-lota"
                            :class="navbarText"
                            @click="goToPage('MealPlanner')">
                            MEAL PLANNER
                        </a>
                    </div>
                    <div class="navbar-item has-dropdown is-hoverable is-hidden-widescreen">
                        <a
                            class="navbar-link is-arrowless has-font-lota"
                            :class="navbarText"
                            @click="goToPage('YourOpinion')">
                            {{ t.opinion }}
                        </a>
                    </div>
                    <div class="navbar-item has-dropdown is-hoverable is-hidden-widescreen">
                        <a
                            class="navbar-link is-arrowless has-font-lota"
                            :class="navbarText"
                            @click="goToPage('Contacts')"
                            >{{ t.contacts }}
                        </a>
                    </div>
                    <div class="navbar-item has-dropdown is-hoverable is-hidden-widescreen">
                        <a
                            class="navbar-link is-arrowless has-font-lota"
                            :class="navbarText"
                            @click="goToPage('Terms')">
                            {{ t.terms }}
                        </a>
                    </div>

                    <div
                        v-show="getToken"
                        class="navbar-item has-dropdown is-hoverable is-hidden-widescreen">
                        <a
                            class="navbar-link is-arrowless has-font-lota"
                            :class="navbarText"
                            @click="logout"
                            >{{ t.logout }}
                        </a>
                    </div>
                </div>

                <div class="navbar-end">
                    <div class="navbar-item mr-10 is-hidden-touch is-hidden-desktop-only">
                        <transition name="rotate">
                            <b-icon
                                v-if="!showSideMenu"
                                class="is-clickable"
                                type="is-primary"
                                :class="navbarText"
                                @click.native="showMenu"
                                icon="plus"
                                size="is-medium">
                            </b-icon>
                        </transition>
                    </div>

                    <div class="navbar-item mobile-padding">
                        <div class="buttons">
                            <b-button
                                class="has-font-lota btn-language"
                                :class="{ 'language-selected': currentLocale == 'pt' }"
                                @click="saveLanguage('pt')">
                                PT
                            </b-button>
                            <b-button
                                class="has-font-lota btn-language"
                                :class="{ 'language-selected': currentLocale == 'en' }"
                                @click="saveLanguage('en')">
                                EN
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </nav>

        <transition name="slide-fade">
            <div
                v-show="showSideMenu"
                class="side-menu"
                :class="sideMenuColor">
                <div class="pt-12">
                    <transition name="rotate">
                        <b-icon
                            v-if="showSideMenu"
                            icon="close"
                            type="is-white"
                            @click.native="showMenu"
                            class="is-clickable"
                            size="is-medium">
                        </b-icon>
                    </transition>
                    <div
                        class="mt-14"
                        style="height: 100%">
                        <a
                            class="py-4 has-text-white has-text-weight-bold"
                            @click="goToPage('Blog')"
                            >BLOG</a
                        >
                        <a
                            class="py-4 has-text-white has-text-weight-bold"
                            @click="goToPage('Store')">
                            {{ t.store }}
                        </a>
                        <a
                            class="py-4 has-text-white has-text-weight-bold"
                            @click="goToPage('MealPlanner')"
                            >MEAL PLANNER</a
                        >
                        <a
                            class="py-4 has-text-white has-text-weight-bold"
                            @click="goToPage('YourOpinion')">
                            {{ t.opinion }}
                        </a>
                        <a
                            class="py-4 has-text-white has-text-weight-bold"
                            @click="goToPage('Contacts')">
                            {{ t.contacts }}
                        </a>
                        <a
                            class="py-4 has-text-white has-text-weight-bold"
                            @click="goToPage('Terms')">
                            {{ t.terms }}
                        </a>
                        <a
                            v-show="getToken"
                            class="py-4 has-text-white has-text-weight-bold"
                            @click="logout">
                            {{ t.logout }}
                        </a>
                    </div>
                </div>
            </div>
        </transition>
    </section>
</template>

<script>
    import _ from 'lodash';
    export default {
        data() {
            return {
                view: {
                    topOfPage: true,
                },
                logos: {
                    v1: require('@/assets/icons/logo_v1.svg'),
                    v2: require('@/assets/icons/logo_v2.png'),
                    v3: require('@/assets/icons/logo_v3.svg'),
                },
            };
        },
        computed: {
            t() {
                return this.$t('components.Navbar');
            },
            getLogo() {
                if (this.view.topOfPage && !this.showMobileNav) return this.logos[_.get(this.$route, 'meta.navbar.logoTop', 'v1')];
                else return this.logos[_.get(this.$route, 'meta.navbar.logo', 'v1')];
            },
            sideMenuColor() {
                return `has-background-${_.get(this.$route, 'meta.sideMenu', 'pink')}`;
            },
            navbarBackground() {
                return !this.view.topOfPage || this.showMobileNav ? 'has-background-white' : 'has-background-transparent';
            },

            navbarText() {
                if (this.view.topOfPage && !this.showMobileNav) return `has-text-${_.get(this.$route, 'meta.navbar.textTop', 'cyan')}`;
                else return `has-text-${_.get(this.$route, 'meta.navbar.text', 'cyan')}`;
            },
            currentLocale() {
                return this.$i18n.locale;
            },
        },
        mounted() {
            addEventListener('scroll', this.handleScroll);
            // alert current window width
            // alert(window.innerWidth);
        },
        methods: {
            showMenu() {
                this.setShowSideMenu(!this.showSideMenu);
            },

            saveLanguage(lang) {
                this.$i18n.locale = lang;
                this.changeLanguage(lang);
                this.moment.locale(lang);
            },

            logout() {
                this.onSessionExpired();
                this.$buefy.toast.open({
                    message: this.t.logoutMessage,
                    position: 'is-top',
                    type: 'is-primary',
                });
                this.setShowSideMenu(false);
            },

            handleScroll() {
                this.view.topOfPage = window.pageYOffset <= 10;
            },

            changePage(name, hash) {
                this.$router.push({ name: name, hash: hash });
                this.setShowSideMenu(false);
                this.setShowMobileNav(false);
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';

    @include touch {
        .navbar.is-fixed-top .navbar-menu,
        .navbar.is-fixed-top-touch .navbar-menu {
            max-height: calc(100dvh - 3.75rem);
        }
    }

    .btn-language,
    .btn-language:active,
    .btn-language:focus,
    .btn-language:hover {
        border: 1px solid $primary;
        border-radius: 100%;
        width: 2.5rem;
        height: 2.5rem;
        font-size: 1rem;
        font-weight: bold;
        color: $primary;
        &.language-selected {
            background-color: $primary;
            color: $white;
            width: 3rem;
            height: 3rem;
        }
    }

    .navbar-brand {
        figure {
            width: 4rem;
            height: 4rem;

            @include mobile {
                width: 3.125rem;
                height: 3.125rem;
            }
        }
    }

    .navbar-item,
    .navbar-link {
        &:hover {
            color: $cyan !important;
        }
    }

    .navbar-item.has-dropdown:focus .navbar-link,
    .navbar-item.has-dropdown:hover .navbar-link,
    .navbar-item.has-dropdown.is-active .navbar-link {
        background-color: unset !important;
    }

    .navbar-dropdown {
        border: unset !important;
        box-shadow: unset !important;
        a {
            background-color: unset !important;
        }
        & > a:first-of-type {
            margin-top: -0.5rem;
        }
    }

    .badge {
        position: absolute;
        top: 0;
        right: 0;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 100%;
        background-color: $primary;
        color: $white;
        font-size: 0.75rem;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translate(50%, -50%);
    }

    .side-menu {
        position: fixed;
        display: flex;
        justify-content: center;
        right: 0;
        z-index: 30;
        height: 100%;

        @include from($tablet) {
            width: 408px;
        }

        & > div {
            width: fit-content;
            & > div {
                display: flex;
                flex-direction: column;
            }
        }
    }

    .navbar-item,
    .navbar-link {
        @include until($touch) {
            // font-size: 1rem;
            line-height: 1em;
            // margin-top: unset !important;
        }
    }

    .sub-label {
        @include until($touch) {
            margin-left: 2em;
        }
    }

    nav {
        font-size: 0.875rem;
        @include until($touch) {
            // font-size: 1rem;
        }
    }
</style>
