<template>
    <div>
        <div class="background">
            <section class="hero is-fullheight is-relative">
                <div class="hero-body is-flex-direction-column is-justify-content-center-desktop">
                    <div class="columns is-mobile is-fullwidth">
                        <div class="column is-7-widescreen is-8-desktop is-5-fullhd is-8-touch mt-10-touch mt-20-desktop">
                            <h1 class="has-font-borest has-text-white">Podcast</h1>
                            <h2 class="has-font-sacramento has-text-white">{{ t.always }}</h2>

                            <div style="display: flex">
                                <figure class="image mt-10 is-128x128">
                                    <img src="../assets/images/podcast/itunes.png" />
                                </figure>
                                <figure class="image mt-10 ml-10 is-48x48">
                                    <img src="../assets/images/podcast/spotify.png" />
                                </figure>
                            </div>
                            <p class="has-font-lota has-text-white">{{ t.opinions }}</p>
                        </div>
                    </div>
                </div>
                <div class="hero-footer">
                    <div class="columns is-mobile is-multiline">
                        <div class="column is-6-desktop is-8-touch is-offset-3-desktop is-offset-2-touch">
                            <div class="download-number-container py-6">
                                <p class="download-number has-text-white has-font-borest">123456</p>
                                <p class="download-text has-font-lota">DOWNLOADS</p>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollArrow />
            </section>
        </div>

        <section class="hero is-large is-relative hero-custom-background">
            <div class="hero-body is-flex-direction-column is-justify-content-flex-start pt-10">
                <div class="columns is-mobile is-fullwidth is-multiline">
                    <div class="column is-12 has-text-right pb-20-desktop">
                        <b-button
                            class="see-all has-font-lota"
                            @click="goToPage('AllPodcast')">
                            {{ t.allPodcasts }}</b-button
                        >
                    </div>
                    <div class="column is-12 has-text-centered pb-10">
                        <h1 class="has-font-lota has-text-black is-size-4-touch">{{ t.popular }}</h1>
                    </div>
                    <div
                        class="column is-12 py-6"
                        v-for="n in 4"
                        :key="n">
                        <div class="columns is-mobile is-multiline">
                            <div class="column is-3-desktop is-4-touch is-2-widescreen">
                                <figure class="image is-square">
                                    <img
                                        class="object-fit"
                                        src="../assets/images/belovebeyou/coach.jpg" />
                                </figure>
                            </div>
                            <div class="column is-6-desktop is-6-touch is-offset-1-desktop is-relative">
                                <p class="podcast-title has-font-lota has-text-black">NOME DO EPISÓDIO DE PODCAST</p>
                                <p class="podcast-description has-font-lota-light has-text-weight-bold has-text-black">Descrição</p>
                                <p class="has-font-lota-light has-text-weight-bold podcast-date">04 Jan 2022</p>
                            </div>
                            <div class="column is-2-desktop is-2-touch is-flex is-justify-content-end">
                                <figure class="image is-64x64">
                                    <img src="../assets/images/podcast/spotify.png" />
                                </figure>
                            </div>
                            <div class="column is-12">
                                <hr class="has-background-black" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div class="has-background-grey">
            <section class="section">
                <div class="container">
                    <div class="columns is-mobile is-multiline">
                        <div class="column is-12 has-text-centered">
                            <h1 class="has-font-lota has-text-black">{{ t.allEpisodes }}</h1>
                        </div>
                        <div class="column is-12-desktop is-10-tablet is-12-mobile is-10-widescreen is-8-fullhd is-offset-2-fullhd is-offset-1-widescreen is-offset-1-tablet is-offset-0-desktop">
                            <div class="columns is-mobile is-multiline is-variable is-8-widescreen">
                                <div
                                    class="column is-4-widescreen is-4-desktop is-6-mobile is-4-tablet is-4-fullhd py-10-desktop"
                                    v-for="n in 9"
                                    :key="n">
                                    <figure class="image is-square">
                                        <img
                                            class="object-fit"
                                            src="../assets/images/belovebeyou/coach.jpg" />
                                    </figure>
                                    <p class="podcast-title has-font-lota has-text-black mt-4">NOME DO EPISÓDIO DE PODCAST</p>
                                    <p class="podcast-description has-font-lota-light has-text-weight-bold has-text-black">04 Jan 2022</p>
                                </div>

                                <div class="column is-12 has-text-centered">
                                    <b-button
                                        class="see-more-btn has-font-lota"
                                        @click="goToPage('AllPodcast')">
                                        {{ t.more }}</b-button
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <section class="hero is-small is-relative">
            <div class="hero-body is-flex-direction-column is-justify-content-flex-start">
                <h1
                    class="has-font-borest is-clickable ag"
                    @click="changePage('Home', '#ana-garcez')">
                    ANA GARCEZ
                </h1>
            </div>
        </section>

        <div class="bottom-background">
            <section class="hero is-fullheight is-relative">
                <div class="hero-body is-flex-direction-column is-justify-content-flex-start pr-0">
                    <div class="columns is-mobile is-fullwidth">
                        <div class="column is-6-desktop is-offset-6-desktop is-10-touch is-offset-2-touch has-text-right pr-0">
                            <div class="form-container p-8 has-text-left">
                                <p class="has-font-reft has-text-black">{{ t.never }}</p>
                                <p class="has-font-reft has-text-black">{{ t.none }}</p>

                                <input
                                    type="text"
                                    class="has-font-lota mt-6"
                                    placeholder="EMAIL" />
                                <br />

                                <b-button class="send-btn has-font-lota has-text-black mt-6"> {{ t.send }}</b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    import ScrollArrow from '@/components/shared/ScrollArrow.vue';
    export default {
        components: {
            ScrollArrow,
        },

        computed: {
            t() {
                return this.$t('views.Podcast');
            },
        },

        methods: {
            changePage(name, hash) {
                this.$router.push({ name: name, hash: hash });
                this.setShowSideMenu(false);
                this.setShowMobileNav(false);
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';
    @import '@/assets/scss/mixins.scss';

    .background {
        background-image: url('../assets/images/podcast/podcast_header.jpg');
        background-size: contain;
        background-repeat: no-repeat;
        z-index: -2;
        background-position-y: top;
        background-color: #ddcaa8;
        background-position-x: right;

        h1 {
            line-height: 1em;
        }
        h1 {
            font-size: 8rem;
            @include from($widescreen) {
                font-size: 9rem;
            }

            @include until($touch) {
                font-size: 5rem;
            }
        }

        h2 {
            font-size: 5rem;
            @include until($touch) {
                font-size: 2.5rem;
            }
        }

        p {
            font-size: 2.3rem;
            @include until($touch) {
                font-size: 1rem;
            }
        }

        .download-number-container {
            border: 4px solid white;
            width: 70%;
            background-color: transparent;
            text-align: center;
            display: flex;
            justify-content: space-evenly;
            flex-direction: column;
            transform: translateY(4em);
            z-index: 5;

            @include until($touch) {
                width: 100%;
                transform: translateY(0em);
            }

            .download-number {
                font-size: 7rem;
                @include until($touch) {
                    font-size: 5rem;
                }
            }

            .download-text {
                font-size: 1rem;
            }
        }

        .hero-footer {
            z-index: 5;
        }
    }

    .hero-custom-background {
        background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 23%, rgba(242, 216, 225, 1) 23%);

        h1 {
            font-size: 2.5rem;
        }

        .see-all {
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 6px 8px #00000029;
            border-radius: 2.5rem;
            color: #f2d8e1;
        }

        .podcast-title {
            font-size: 1.5rem;
            @include until($touch) {
                font-size: 1rem;
            }
        }

        .podcast-description {
            font-size: 1.25rem;

            @include until($touch) {
                font-size: 1rem;
            }
        }

        .podcast-date {
            position: absolute;
            bottom: 1.8rem;
        }
    }

    .has-background-grey {
        h1 {
            font-size: 2rem;
        }

        .podcast-title {
            font-size: 1.5rem;
            @include until($touch) {
                font-size: 1rem;
            }
        }

        .see-more-btn {
            margin: 0 auto;
            display: flex;
            width: 14rem;
            height: 3rem;
            border: 1px solid black;
            border-radius: 1.5rem;
            color: black;
        }
    }

    .object-fit {
        object-fit: cover;
    }

    .ag {
        font-size: 2.5rem;
        text-align: center;
        color: black;

        &:hover {
            color: #8e8e8e;
        }
    }

    .bottom-background {
        background-image: url('../assets/images/podcast/podcast_mockup.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        z-index: -2;
        background-position-y: center;
        background-position-x: right;
    }

    .form-container {
        border-top: solid 2px black;
        border-bottom: solid 2px black;
        border-left: solid 2px black;
        border-top-left-radius: 49px;
        border-bottom-left-radius: 49px;
        width: 100%;

        p {
            font-size: 3rem;
            line-height: 1em;

            @include until($touch) {
                font-size: 2rem;
            }
        }

        input {
            width: 80%;
            background-color: #f7f7f7;
            border: none;

            &::placeholder {
                color: black;
            }

            @include until($mobile-l) {
                width: 100%;
            }
        }

        .send-btn {
            width: 5.3rem;
            height: 2.5rem;
            border-radius: 1.5rem;
            color: black;
        }
    }
</style>
