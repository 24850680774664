<template>
    <div class="has-background-secondary">
        <section class="section">
            <div class="container">
                <p class="has-text-white is-size-1 pt-22-touch pt-10-desktop pl-10-desktop has-text-centered-touch has-font-borest has-navbar">
                    {{ t.cart }}
                </p>
                <div
                    class="columns is-mobile is-multiline"
                    style="z-index: 0"
                    v-if="cart.length > 0">
                    <div class="column is-8-desktop is-12-touch">
                        <div class="mt-10">
                            <div
                                v-for="product in cart"
                                :key="product.id">
                                <div class="media">
                                    <div class="media-left">
                                        <figure class="image is-1by1 post-image mb-6 ml-10-desktop ml-6-touch">
                                            <img
                                                :src="product.images[0]"
                                                alt="" />
                                        </figure>
                                    </div>

                                    <div class="media-content">
                                        <p class="has-text-white has-font-lota is-size-4-desktop is-size-5-tablet is-size-6-mobile">{{ product.quantity }}x {{ product.name[getLocale] }}</p>
                                        <p class="has-text-white has-font-lota is-size-5-desktop mt-4 is-size-5-tablet is-size-6-mobile">{{ product.unit_amount.toFixed(2) }} € / uni</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column is-4-desktop is-offset-1-touch is-10-touch total-container">
                        <div class="total-box">
                            <p class="has-font-lota has-text-centered has-text-white is-size-3-desktop is-size-3-tablet is-size-5-mobile">Total</p>
                            <p class="mt-4 has-text-centered has-text-white is-size-3-desktop is-size-3-tablet is-size-5-mobile">{{ getTotal }} €</p>
                        </div>

                        <b-button
                            class="mt-4 btn"
                            type="is-primary"
                            size="is-large"
                            :loading="isLoading"
                            @click="cardCheckout"
                            >Checkout</b-button
                        >
                        <div
                            id="paypal-button-container"
                            class="mt-4"></div>
                        <b-button
                            class="mt-4 btn"
                            size="is-large"
                            @click="clear"
                            >{{ t.cleanCart }}</b-button
                        >
                    </div>
                </div>

                <p
                    v-else
                    class="has-text-white is-size-3-desktop is-size-3-tablet is-size-4-mobile pt-22-touch px-4-touch pt-10-desktop pl-10-desktop has-text-centered-touch has-font-refto">
                    {{ t.noItems }}
                    <span
                        class="is-clickable is-underlined"
                        @click="goToPage('Store')">
                        {{ t.store }}</span
                    >
                </p>
            </div>
        </section>
    </div>
</template>

<script>
    import { loadScript } from '@paypal/paypal-js';
    import { checkout, payPalCheckout } from '@/api/store';
    export default {
        data() {
            return {
                cart: [],
                isLoading: false,
                total: 0,
            };
        },

        methods: {
            cardCheckout() {
                this.isLoading = true;
                let data = {
                    products: [],
                };
                this.cart.map((product) => {
                    data.products.push({
                        product: product._id,
                        quantity: product.quantity,
                        price: product.price.id,
                    });

                    checkout(data)
                        .then((response) => {
                            if (this.isMobile) {
                                window.location.assign(response.data.session.url, '_blank');
                            } else window.location.href = response.data.session.url;
                        })
                        .catch((error) => {
                            throw error;
                        })
                        .finally(() => {
                            this.isLoading = false;
                        });
                });
            },

            clear() {
                this.clearCart();
                this.cart = [];
            },

            async paypal() {
                let paypal;
                let t = this;
                try {
                    paypal = await loadScript({
                        'client-id': 'AelSxaixTplWJjIpPfYBCNsS9etWjNbPGAJDegaNMRgT6u6cUzQ4c69FR2J6xtwF0PUk3swgAH2um0a8',
                        'disable-funding': 'credit,card',
                    });
                } catch (error) {
                    console.error('failed to load the PayPal JS SDK script', error);
                }

                if (paypal) {
                    try {
                        await paypal
                            .Buttons({
                                style: {
                                    label: 'paypal',
                                    height: 55,
                                },

                                onApprove: function (data, actions) {
                                    return actions.order.capture().then(function (orderData) {
                                        // Full available details
                                        console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));

                                        let data = {
                                            products: [],
                                            amount: 0,
                                            customer: {
                                                name: null,
                                                email: null,
                                                address: {
                                                    line1: null,
                                                    line2: null,
                                                    area: null,
                                                    postal_code: null,
                                                    country: null,
                                                },
                                            },
                                            paypal_id: null,
                                        };

                                        data.amount = t.total / 100;
                                        data.customer.name = orderData.purchase_units[0].shipping.name.full_name;
                                        data.customer.email = orderData.purchase_units[0].payee.email_address;
                                        data.customer.address.line1 = orderData.purchase_units[0].shipping.address.address_line_1;

                                        if (orderData.purchase_units[0].shipping.address.address_line_2) {
                                            data.customer.address.line2 = orderData.purchase_units[0].shipping.address.address_line_1;
                                        }

                                        data.customer.address.area = orderData.purchase_units[0].shipping.address.admin_area_2;
                                        data.customer.address.postal_code = orderData.purchase_units[0].shipping.address.postal_code;

                                        data.customer.address.country = orderData.purchase_units[0].shipping.address.country_code;

                                        data.paypal_id = orderData.id;

                                        t.cart.map((product) => {
                                            data.products.push({
                                                product: product._id,
                                                quantity: product.quantity,
                                            });
                                        });

                                        payPalCheckout(data)
                                            .then((response) => {
                                                t.goToPage('OrderSucess');
                                            })
                                            .catch((error) => {
                                                t.$buefy.toast.open({
                                                    message: t.t.errorMessage,
                                                    position: 'is-top',
                                                    type: 'is-secondary',
                                                });
                                                throw error;
                                            });
                                    });
                                },

                                onError: function (err) {},
                            })
                            .render('#paypal-button-container');
                    } catch (error) {
                        console.error('failed to render the PayPal Buttons', error);
                    }
                }
            },
        },

        computed: {
            getTotal() {
                this.cart.map((product) => {
                    this.total += product.price.unit_amount * parseInt(product.quantity);
                });

                return (this.total / 100).toFixed(2);
            },

            t() {
                return this.$t('views.Checkout');
            },
        },

        created() {
            this.paypal();
            this.cart = this.getCart;
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';

    .post-image {
        width: 8.75rem;

        @include until($desktop) {
            width: 5rem;
        }
    }

    .total-box {
        width: 90%;
        border: solid 1px white;
        padding: 1rem;

        @include until($desktop) {
            width: 100%;
        }
    }

    .btn {
        width: 90%;
        @include until($desktop) {
            display: flex;
            margin: 0 auto;
            width: 100%;
        }
        @include until($mobile-l) {
            width: 100%;
            height: 5.5rem;
        }
    }

    #paypal-button-container {
        width: 90%;
        z-index: 0 !important;
        @include until($desktop) {
            display: flex;
            margin: 0 auto;
            width: 100%;
        }
    }

    .total-container {
        z-index: 0 !important;
        @include until($desktop) {
            padding-bottom: 5rem;
        }
    }
</style>
