<template>
    <section
        class="hero is-fullheight"
        :class="$i18n.locale">
        <div class="hero-body">
            <div class="ml-40-desktop mb-10-desktop">
                <h1 class="title has-font-borest has-text-weight-light">NEWSLETTERS</h1>
                <form @submit.prevent="subscribe">
                    <input
                        class="input mt-5"
                        type="text"
                        v-model="email"
                        required
                        placeholder="EMAIL" />
                    <p
                        v-if="sentType"
                        class="tw-font-medium tw-mt-1"
                        :class="{
                            'has-text-success': sentType === 'success',
                            'has-text-danger': sentType === 'error',
                        }">
                        {{ sentMessage }}
                    </p>
                    <b-button
                        class="button is-outlined is-rounded is-black tw-mt-8"
                        native-type="submit"
                        :loading="isLoading"
                        >{{ t.send }}</b-button
                    >
                </form>
            </div>
        </div>
    </section>
</template>

<script>
    import { newsletter } from '@/api/beLoveBeYou';
    export default {
        data() {
            return {
                t: this.$t('components.Newsletter'),
                email: null,
                isLoading: false,
                sentType: null,
                sentMessage: null,
            };
        },

        methods: {
            subscribe() {
                this.isLoading = true;
                let data = {
                    email_address: this.email,
                };

                newsletter(data)
                    .then((response) => {
                        this.sentType = 'success';
                        this.sentMessage = this.t.success;
                        // this.$buefy.toast.open({
                        //     message: this.t.success,
                        //     position: 'is-top',
                        //     type: 'is-primary',
                        // });
                    })
                    .catch((error) => {
                        this.sentType = 'error';
                        this.sentMessage = this.t.error;
                        // this.$buefy.toast.open({
                        //     message: this.t.error,
                        //     position: 'is-top',
                        //     type: 'is-secondary',
                        // });
                        throw error;
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';

    .hero {
        &.pt {
            background-image: url('../assets/images/newsletter/newsletter_pt.jpg');
        }

        &.en {
            background-image: url('../assets/images/newsletter/newsletter_en.jpg');
        }

        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: bottom;
        background-position-x: left;
        @include touch {
            margin-top: 3rem;
            background-position-x: 77%;
            background-position-y: 0;
        }
    }

    .hero-body {
        display: flex;
        @include touch {
            flex-direction: column;
            transform: translateY(-3rem);
        }
    }
    h1 {
        font-size: 6rem;
        @include mobile {
            font-size: 3.3rem;
        }
        @include tablet-only {
            font-size: 4rem;
        }
    }
    input::placeholder {
        color: black;
    }
</style>
