<template>
    <div class="main">
        <section class="hero is-fullheight is-relative">
            <video
                autoplay
                playsinline
                muted
                loop>
                <source
                    src="../assets/images/homepage/Inicio_homepage.mp4"
                    type="video/mp4" />
            </video>
            <ScrollArrow />
        </section>
        <section class="hero is-small py-6">
            <div class="hero-body">
                <p class="has-text-centered has-font-reft is-size-1-desktop is-size-1-tablet is-size-2-mobile">
                    {{ t.title }}
                </p>
                <p class="has-text-centered has-font-reft is-size-1-desktop is-size-1-tablet is-size-2-mobile">
                    {{ t.titleTwo }}
                </p>

                <div class="has-text-centered">
                    <b-button
                        type="is-primary"
                        rounded
                        class="has-font-lota mt-10 px-16"
                        @click="goToPage('Contacts')">
                        {{ t.session }}</b-button
                    >
                </div>
            </div>
        </section>
        <section
            id="ana-garcez"
            class="section has-background-pink has-text-white">
            <div class="container">
                <div class="columns is-mobile is-multiline is-centered">
                    <div class="column is-6-widescreen is-12-desktop is-12-touch has-text-centered">
                        <h1 class="has-font-borest">ANA<br />GARCEZ</h1>
                        <p class="has-border-white has-font-lota-light has-text-weight-bold is-size-5-desktop is-size-5-tablet is-size-6-mobile p-4 mt-8">
                            {{ t.soul }}
                            <br />
                            {{ t.contribute }}
                            <br />
                            {{ t.next }}
                            <br />
                            {{ t.happy }}
                            <br />
                            {{ t.life }}
                        </p>
                        <h3 class="mt-5 has-text-white has-font-sacramento is-size-1-desktop is-size-3-touch">
                            {{ t.version }}
                        </h3>
                    </div>
                    <div class="column is-5-widescreen is-6-desktop is-12-touch is-relative">
                        <div class="columns is-mobile">
                            <div class="column is-8-touch is-8-desktop-only is-8-fullhd is-offset-2-touch is-offset-2-desktop is-offset-2-fullhd is-10-widescreen is-offset-1-widescreen">
                                <figure class="image">
                                    <img
                                        class="is-rounded"
                                        src="@/assets/images/homepage/ana_garcez_.jpg"
                                        alt="" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section
            id="fantasy"
            class="section">
            <div class="container">
                <div class="columns is-mobile is-multiline">
                    <div class="column is-12-desktop is-6-widescreen is-12-touch">
                        <p class="has-font-reft is-size-1-desktop is-size-1-tablet is-size-2-mobile">{{ t.fantasy }}</p>
                    </div>
                </div>
            </div>
        </section>
        <section
            id="learn"
            class="section has-background-grey-lighter has-text-dark">
            <div class="container">
                <div class="columns is-mobile is-centered is-multiline">
                    <div class="column is-3-widescreen is-12-mobile is-6-tablet">
                        <h3 class="has-font-borest">{{ t.learn }}</h3>
                        <p class="has-font-lota mt-8">
                            {{ t.love }}
                        </p>
                        <hr class="has-background-dark my-8" />
                        <h3 class="has-font-borest mt-12">{{ t.feel }}</h3>
                        <p class="has-font-lota mt-8">
                            {{ t.people }}
                        </p>
                        <hr class="has-background-dark my-8" />
                    </div>
                    <div class="column is-5-widescreen is-12-mobile is-6-tablet">
                        <figure class="image is-flex">
                            <img
                                src="@/assets/images/homepage/ana_garcez_galinha.jpg"
                                alt="" />
                        </figure>
                    </div>
                    <div class="column is-3-widescreen is-12-touch columns is-mobile is-multiline">
                        <div class="column is-12-mobile is-6-tablet is-12-widescreen tw-order-1 md:tw-order-3 xl:tw-order-1">
                            <h3 class="has-font-borest">{{ t.see }}</h3>
                            <p class="has-font-lota mt-8">
                                {{ t.things }}
                            </p>
                            <hr class="has-background-dark my-8" />
                        </div>
                        <div class="column is-12-mobile is-6-tablet is-12-widescreen tw-order-2">
                            <figure class="image">
                                <img
                                    src="@/assets/images/homepage/ana_olhar_cima.jpg"
                                    alt="" />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section
            id="divine"
            class="hero is-medium">
            <div class="hero-body">
                <h1
                    class="has-text-white has-font-borest tw-pb-[3em] tw-text-left tw-text-5xl sm:tw-text-7xl md:tw-pb-[2em] md:tw-text-8xl lg:tw-text-9xl"
                    v-html="t.divineTitle"></h1>
            </div>
        </section>
        <section
            class="section has-background-grey-lighter"
            id="believe">
            <div class="container">
                <div class="columns is-mobile is-multiline">
                    <div class="column is-5-widescreen is-10-desktop is-offset-1-desktop is-12-touch">
                        <p class="has-font-reft has-text-centered has-background-primary has-text-white card">{{ t.believe }}</p>
                    </div>
                </div>
            </div>
        </section>
        <section
            class="section"
            id="time">
            <div class="container">
                <div class="columns is-mobile is-multiline is-centered">
                    <div class="column is-12-mobile is-6-tablet">
                        <p class="has-font-reft has-text-centered is-size-1-desktop is-size-3-tablet is-size-3-mobile">{{ t.thingsHappen }}</p>
                        <p class="has-font-lota-light has-text-weight-bold is-size-5-desktop is-size-6-tablet is-size-6-mobile mt-8">{{ t.cross }}</p>
                        <hr class="my-8-desktop mb-4-touch mt-8-touch has-background-dark" />
                    </div>
                    <div class="column is-4-widescreen is-offset-1-widescreen is-5-fullhd is-12-mobile is-6-tablet is-relative">
                        <section class="section">
                            <figure class="image">
                                <img
                                    src="@/assets/images/homepage/ana_muro.jpg"
                                    alt="" />
                            </figure>
                        </section>
                    </div>
                </div>
            </div>
        </section>
        <div
            id="quiz"
            class="columns is-gapless is-multiline is-mobile is-centered mt-8">
            <div class="column is-6-widescreen is-12-touch is-12-desktop has-background-grey-lighter">
                <section class="section">
                    <div class="has-font-lota-light has-text-weight-bold is-size-6-desktop is-size-6-tablet is-size-6-mobile">
                        <p>{{ t.responsible }}</p>
                        <p class="mt-6">
                            {{ t.never }}
                        </p>

                        <p class="mt-6">
                            {{ t.eachDay }}
                        </p>
                        <p class="mt-6">
                            {{ t.divine }}
                        </p>

                        <p class="mt-6">
                            {{ t.care }}
                        </p>

                        <p class="mt-6">
                            {{ t.work }}
                        </p>

                        <p class="mt-6">
                            {{ t.started }}
                        </p>

                        <p
                            class="mt-6"
                            v-html="t.side"></p>

                        <p class="mt-6">
                            {{ t.ideas }}
                            <span class="has-text-black has-text-weight-bold"> {{ t.look }}</span>
                        </p>
                    </div>
                </section>
                <div class="has-text-centered mb-4">
                    <b-icon
                        icon="arrow-down-drop-circle-outline"
                        type="is-black"
                        size="is-medium"></b-icon>
                </div>
            </div>
            <div class="column is-6-widescreen is-12-touch is-12-desktop">
                <section class="section p-0">
                    <figure class="image">
                        <img
                            src="@/assets/images/homepage/ana_conversar.jpg"
                            alt="" />
                    </figure>

                    <h1 class="has-text-centered has-font-reft is-uppercase has-text-primary mt-8">{{ t.questionary }}</h1>
                    <div class="columns is-mobile is-centered">
                        <div class="column is-10-widescreen is-12">
                            <b-carousel
                                ref="questCart"
                                animated="slide"
                                :arrow="true"
                                :has-drag="false"
                                :autoplay="false"
                                :arrow-hover="true"
                                :repeat="true"
                                v-model="questionIndex">
                                <b-carousel-item
                                    v-for="(faq, i) in questions"
                                    :key="i"
                                    class="carousel-item">
                                    <div class="has-font-lota has-background-primary mx-auto has-text-white px-6-widescreen px-8-fullhd px-4-desktop px-2-mobile py-8 mb-8">
                                        <h4 class="has-text-centered has-text-weight-bold mb-4">
                                            {{ faq.question }}
                                        </h4>
                                        <p class="has-text-centered mx-4">
                                            {{ faq.answer }}
                                        </p>
                                    </div>
                                </b-carousel-item>
                            </b-carousel>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <div
            id="mission"
            class="hero is-fullheight is-pink">
            <div class="hero-body">
                <div class="columns is-centered is-multiline is-mobile">
                    <div class="column is-5-desktop is-5-widescreen is-6-tablet is-10-mobile is-flex is-align-items-center order-2">
                        <figure class="image is-flex tw-h-full">
                            <img
                                class="tw-aspect-[0.6498] tw-object-contain"
                                src="@/assets/images/homepage/ana_porta_.png"
                                alt="" />
                        </figure>
                    </div>
                    <div class="column is-5-desktop is-offset-1-desktop is-4-widescreen is-6-tablet is-12-mobile has-font-lota-light has-text-weight-bold order-1">
                        <h1 class="has-font-reft">{{ t.mission }}</h1>
                        <p class="">
                            {{ t.myMission }}
                        </p>
                        <p class="mission-description mt-8">
                            {{ t.naturalCapacity }}
                        </p>
                        <p class="mission-description mt-8">
                            {{ t.transform }}
                        </p>
                        <p class="mission-description mt-8 has-font-sacramento is-size-1-desktop is-size-4-mobile">
                            {{ t.you }}
                        </p>

                        <p class="mission-description mt-8">{{ t.boom }}</p>
                    </div>
                </div>
            </div>
            <div class="hero-body">
                <div class="columns is-mobile is-multiline">
                    <div
                        id="change-div"
                        class="column is-7-desktop is-12-touch">
                        <div class="my-8 mr-4">
                            <h1 class="has-font-reft">{{ t.change }}</h1>
                            <p class="has-font-lota-light has-text-weight-bold">
                                {{ t.primaryFood }}
                                <br />
                                {{ t.important }}
                            </p>
                            <p class="has-font-sacramento my-4 is-size-2">{{ t.assume }}</p>
                            <p class="has-font-lota-light has-text-weight-bold">
                                {{ t.sucess }}
                            </p>
                        </div>
                    </div>
                    <div class="column is-5-desktop is-12-touch p-0 pt-8-touch">
                        <figure class="image">
                            <img
                                class=""
                                src="@/assets/images/homepage/ana_campo.jpg"
                                alt="" />
                        </figure>
                    </div>
                </div>
            </div>
        </div>
        <section class="section">
            <div class="container">
                <div class="columns is-centered is-mobile is-multiline">
                    <div class="column is-8-desktop is-6-widescreen is-10-tablet is-12-mobile has-text-pink">
                        <p class="has-font-sacramento is-size-2 my-4">{{ t.true }}</p>
                        <p class="has-font-lota-light has-text-weight-bold">{{ t.pain }}</p>
                        <p class="has-font-sacramento is-size-2 mt-8 mb-4">{{ t.body }}</p>
                        <p class="has-font-lota-light has-text-weight-bold">{{ t.possible }}</p>
                    </div>
                    <div class="column is-4-desktop is-6-widescreen is-2-tablet is-12-mobile is-flex is-justify-content-center is-align-content-center is-align-items-center">
                        <figure class="image is-64x64">
                            <img
                                src="../assets/icons/coracao_verde_medio.png"
                                alt="" />
                        </figure>
                    </div>
                </div>
            </div>
        </section>
        <div
            id="miracle"
            class="columns is-centered is-mobile is-multiline">
            <div class="column is-6-desktop is-12-touch">
                <figure class="image is-flex">
                    <img
                        src="@/assets/images/homepage/vegetais.jpg"
                        alt="" />
                </figure>
            </div>
            <div class="column is-6-desktop is-12-touch has-text-cyan has-font-reft is-size-1 has-text-centered mt-8-mobile is-flex is-justify-content-center is-flex-direction-column">
                <div class="m-8-desktop m-4-touch">
                    <p>{{ t.miracle }}</p>
                    <p>{{ t.exist }}</p>
                </div>
            </div>
        </div>
        <section class="section">
            <div class="container">
                <div class="columns is-centered is-mobile is-multiline">
                    <div class="column is-7-desktop is-6-widescreen is-10-tablet is-12-mobile has-text-pink">
                        <p class="has-font-lota-light has-text-weight-bold">{{ t.diets }}</p>
                        <p class="has-font-sacramento is-size-2 my-4">{{ t.eat }}</p>

                        <p class="has-font-lota-light has-text-weight-bold">{{ t.consciousness }}</p>
                        <p class="has-font-sacramento is-size-2 my-4">{{ t.appointment }}</p>
                    </div>
                    <div class="column is-5-desktop is-5-widescreen is-offset-1-widescreen is-10-tablet is-12-mobile is-flex is-justify-content-center is-align-content-center is-align-items-center">
                        <figure class="image xl:-tw-mt-24">
                            <img
                                class="tw-max-h-[40rem]"
                                src="../assets/images/homepage/ana_praia.jpg"
                                alt="" />
                        </figure>
                    </div>
                </div>
            </div>
        </section>
        <div
            id="formation"
            class="hero is-grey-variant">
            <div class="hero-body">
                <h1 class="has-font-reft has-text-centered">{{ t.formation }}</h1>
                <div class="columns is-centered is-mobile is-multiline mb-8">
                    <div class="column is-4-desktop is-6-tablet is-12-mobile has-text-centered is-size-5">
                        <h4 class="has-font-lota">HEALTH COACHING</h4>
                        <div
                            class="is-clickable is-underlined"
                            @click="goToPage('INN')">
                            {{ t.inn }}
                        </div>
                    </div>
                    <div class="column is-4-desktop is-6-tablet is-12-mobile has-text-centered is-size-5 my-8-touch">
                        <h4 class="has-font-lota">{{ t.musicTherapy }}</h4>
                        <div
                            class="is-clickable is-underlined"
                            @click="goToPage('MusicTherapy')">
                            {{ t.music }}
                        </div>
                    </div>
                    <div class="column is-4-desktop is-6-tablet is-12-mobile has-text-centered is-size-5">
                        <h4 class="has-font-lota">{{ t.consultory }}</h4>
                        <div
                            class="is-clickable is-underlined"
                            @click="goToPage('Consultory')">
                            {{ t.consultor }}
                        </div>
                    </div>
                </div>
                <div class="has-text-centered">
                    <b-button
                        class="has-background-transparent has-font-lota"
                        outlined
                        rounded
                        @click="goToPage('ShortCV')"
                        >{{ t.seeMore }}</b-button
                    >
                </div>
            </div>
        </div>
        <div
            id="participation"
            class="hero">
            <div class="hero-body">
                <h1 class="has-font-reft has-text-centered">{{ t.participation }}</h1>
                <b-carousel-list
                    v-model="imageIndex"
                    class="is-shadowless"
                    :data="participations"
                    :items-to-show="photosToShow"
                    :repeat="true">
                    <template #item="list">
                        <figure
                            class="image mx-8"
                            style="filter: grayscale(1)">
                            <img :src="list.image" />
                        </figure>
                    </template>
                </b-carousel-list>
            </div>
        </div>
    </div>
</template>

<script>
    import ScrollArrow from '@/components/shared/ScrollArrow.vue';
    import Hammer from 'hammerjs';

    export default {
        components: {
            ScrollArrow,
        },

        data() {
            return {
                progress: '0%',
                imageIndex: 0,
                currentWidth: 0,
                questions: [
                    {
                        question: this.$t('views.Home.questionOne'),
                        answer: this.$t('views.Home.answerOne'),
                    },
                    {
                        question: this.$t('views.Home.questionTwo'),
                        answer: this.$t('views.Home.answerTwo'),
                    },

                    {
                        question: this.$t('views.Home.questionThree'),
                        answer: this.$t('views.Home.answerThree'),
                    },
                    {
                        question: this.$t('views.Home.questionFour'),
                        answer: this.$t('views.Home.answerFour'),
                    },
                    {
                        question: this.$t('views.Home.questionFive'),
                        answer: this.$t('views.Home.answerFive'),
                    },
                    {
                        question: this.$t('views.Home.questionSix'),
                        answer: this.$t('views.Home.answerSix'),
                    },
                    {
                        question: this.$t('views.Home.questionSeven'),
                        answer: this.$t('views.Home.answerSeven'),
                    },
                    {
                        question: this.$t('views.Home.questionEight'),
                        answer: this.$t('views.Home.answerEight'),
                    },
                ],
                questionIndex: 0,

                participations: [
                    {
                        image: require('@/assets/images/homepage/balcony.png'),
                    },
                    {
                        image: require('@/assets/images/homepage/btv.png'),
                    },
                    {
                        image: require('@/assets/images/homepage/elisabetta.png'),
                    },

                    {
                        image: require('@/assets/images/homepage/lisbon_south.png'),
                    },
                    {
                        image: require('@/assets/images/homepage/logoAped.png'),
                    },
                    {
                        image: require('@/assets/images/homepage/RTP.svg.png'),
                    },
                    {
                        image: require('@/assets/images/homepage/sic.png'),
                    },
                    {
                        image: require('@/assets/images/homepage/sic_mulher.png'),
                    },
                    {
                        image: require('@/assets/images/homepage/SXL1.png'),
                    },
                    {
                        image: require('@/assets/images/homepage/zen.jpg'),
                    },
                ],
            };
        },
        computed: {
            t() {
                return this.$t('views.Home');
            },
            photosToShow() {
                // if isMobile
                if (this.currentWidth < 768) {
                    return 2;
                }
                // if isTablet
                if (this.currentWidth < 1024) {
                    return 2;
                }
                // if isDesktop
                return 4;
            },
        },
        methods: {
            nextQuestion() {
                if (this.questionIndex < this.questions.length) {
                    this.questionIndex++;
                } else {
                    this.questionIndex = 0;
                }
            },
            previousQuestion() {
                if (this.questionIndex > 0) {
                    this.questionIndex--;
                } else {
                    this.questionIndex = this.questions.length - 1;
                }
            },

            carouselAutoPlay() {
                this.imageIndex++;
            },
            handleResize() {
                this.currentWidth = window.innerWidth;
                const div = document.getElementById('change-div');
                if (div) {
                    // get height of div
                    const divHeight = div.offsetHeight;

                    // set css var to height of div
                    document.documentElement.style.setProperty('--change-div-height', `${divHeight}px`);
                }
            },
        },
        mounted() {
            setInterval(() => {
                this.carouselAutoPlay();
            }, 2500);
            this.handleResize();
            window.addEventListener('resize', this.handleResize);

            const carousel = this.$refs.questCart;
            const hammer = new Hammer(carousel.$el);
            hammer.on('swipeleft', () => {
                carousel.next();
            });
            hammer.on('swiperight', () => {
                carousel.prev();
            });
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize);
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';
    @import '@/assets/scss/mixins.scss';

    .main {
        overflow-x: hidden;
    }

    .hero:first-of-type {
        overflow: hidden;
        video {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 100vh;
            width: 100vw;
            max-width: unset;
            max-height: unset;
            object-fit: cover;

            &:focus-visible {
                outline: none;
            }
        }
    }

    #ana-garcez {
        h1 {
            font-size: 10rem;
            line-height: 1em;
            @include until($fullhd) {
                font-size: 8rem;
            }
            @include mobile() {
                font-size: 5rem;
            }
        }
        @include until($desktop) {
            figure,
            figure img {
                /*  max-height: 35rem; */
                width: 100%;
                margin-left: auto;
                margin-right: auto;
            }
        }

        @include from($widescreen) {
            figure {
                height: 100%;
                width: 100%;
                transform: translate(4em, 6em);

                img {
                    height: 100%;
                    object-fit: cover;
                    width: 100%;
                }
            }
        }

        @include from($fullhd) {
            figure {
                height: 120%;
            }
        }
    }

    #fantasy {
        p {
            overflow-y: auto;
            max-height: 4.5em;
            line-height: 1.1em;
            letter-spacing: 0.01em;
            @include scrollbar($pink, $pink);
        }
    }

    #learn {
        h3 {
            font-size: 5rem;
            line-height: 1em;
            @include until($fullhd) {
                font-size: 4rem;
            }
        }

        .column:nth-of-type(2) {
            overflow: hidden;
            figure {
                height: 100%;
                width: auto;
                img {
                    height: 100%;
                    // width: auto;
                    object-fit: cover;
                }
            }
        }
    }

    #divine {
        background-image: url('~@/assets/images/homepage/ana_mar.jpg');
        background-size: cover;
        background-position-x: left;
        h1 {
            line-height: 1em;
        }
    }

    #believe {
        p.has-background-primary {
            font-size: 2.5rem;
            border-radius: 2em;
            padding: 2em 1em;
            box-shadow: 0 13px 16px rgba(0, 0, 0, 0.2);
            transform: translateY(-5em);
            margin-bottom: -5em;

            @include touch {
                font-size: 2.2rem;
                border-radius: 4rem;
                line-height: 1em;
            }
        }
    }

    #time {
        p.has-font-reft {
            line-height: 1.2em;
            letter-spacing: 0.01em;
        }
        hr {
            @include from($widescreen) {
                margin-bottom: 0 !important;
            }
        }

        figure {
            @include from($widescreen) {
                position: absolute;
                bottom: 0;
            }
            img {
                border-radius: 10rem;
                @include touch {
                    border-radius: 5rem;
                }
            }
        }

        section {
            @include touch {
                padding: 0;
            }
        }
    }

    #quiz {
        .column:nth-of-type(1) > section {
            overflow-y: auto;
            @include scrollbar($white, $white);
            max-height: 30em;
            padding-right: 1rem;
            margin-block: 1rem;
            margin-right: 0.5rem;
            margin-left: auto;
            @include from($fullhd) {
                max-height: 40em;
                margin-right: 2rem;
                margin-left: 2rem;
            }
        }
        .column:nth-of-type(2) {
            h1 {
                font-size: 3.5rem;
                @include from($widescreen) {
                    @include until($fullhd) {
                        font-size: 4.5rem;
                    }
                }
                @include fullhd {
                    font-size: 7.5rem;
                }
            }
            .carousel-item {
                div {
                    border-radius: 5rem;
                    width: 80%;
                    @include touch {
                        border-radius: 3rem;
                        width: 90%;
                    }
                    @include mobile {
                        border-radius: 2rem;
                        width: 95%;
                    }
                }
            }
        }
    }
    #mission {
        h1 {
            font-size: 6rem;
            line-height: 1em;
            margin-bottom: 1.5rem;
            @include widescreen {
                font-size: 10rem;
            }
            @include fullhd {
                font-size: 12rem;
            }
        }

        .hero-body:nth-of-type(1) {
            figure {
                img {
                    filter: drop-shadow(14px 14px 18px rgba(0, 0, 0, 0.3));
                }
            }
        }

        .hero-body:nth-of-type(2) {
            @include desktop {
                padding-left: 0;
            }

            @include touch {
                // padding-inline: unset;
            }
            .columns {
                .column:nth-of-type(1) {
                    padding-left: 3rem;
                    border-top: 0.3em solid white;
                    border-bottom: 0.3em solid white;
                    h1 {
                        line-height: 1em;
                    }
                    @include widescreen {
                        padding-left: calc((75% / 12) * 2);
                    }

                    @include mobile {
                        .has-font-sacramento {
                            line-height: 1.2em;
                        }
                    }
                }
                figure,
                img {
                    max-height: var(--change-div-height);
                    width: auto;
                    border-top-right-radius: 50%;
                    border-bottom-right-radius: 50%;
                    @include touch {
                        $radius: 10%;
                        border-radius: $radius;
                    }
                }
                img {
                    @include touch {
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }
        }
    }

    #miracle {
        figure {
            width: auto;
            height: 100%;
            object-fit: cover;
            img {
                width: auto;
                height: 100%;
                object-fit: cover;
                // shadow
                box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
            }
        }
        p {
            font-size: 2rem;
            @include widescreen {
                font-size: 3.5rem;
            }
        }
    }

    #formation {
        h1 {
            font-size: 5.5rem;
            @include widescreen {
                font-size: 10rem;
            }
            @include fullhd {
                font-size: 12rem;
            }
        }

        .column {
            h4 {
                height: 4em;
            }

            &:nth-of-type(2) {
                @include desktop {
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                }
                @include touch {
                    border-bottom: 1px solid black;
                    border-top: 1px solid black;
                }
            }

            div {
                width: 80%;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    #participation {
        h1 {
            font-size: 4rem;
            @include widescreen {
                font-size: 10rem;
            }
            @include fullhd {
                font-size: 12rem;
            }
        }

        figure {
            height: 100%;
            display: flex;
            justify-content: center;
            img {
                max-height: 10rem;
                width: auto;
                object-fit: contain;
            }
        }
    }

    .order-1 {
        @include mobile {
            order: 1;
        }
    }
    .order-2 {
        @include mobile {
            order: 2;
        }
    }
</style>
<style lang="scss">
    #quiz {
        a {
            text-decoration: underline;
            @apply tw-text-dark;
        }
    }
</style>
