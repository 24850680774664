import { get, post, put } from '@/utils/http';

export function getVideos() {
    return get('/videos');
}

export function getMeditations() {
    return get('/meditations');
}

export function stripeCheckout(data) {
    return post('/meditations-order/checkout/stripe', data);
}

export function payPalCheckout(data) {
    return post('/meditations-order/checkout/paypal', data);
}

export function getDownloadCode(code) {
    return get('/meditations-order?code=' + code);
}

export function updateDownloadCode(data) {
    return put('/meditations-order', data);
}
