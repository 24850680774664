<template>
    <div>
        <section
            id="media"
            class="hero is-fullheight">
            <div class="columns is-flex is-mobile is-multiline is-gapless">
                <div class="column is-flex is-align-items-center is-justify-content-center is-half-desktop is-full-touch">
                    <div class="has-text-centered">
                        <h1 class="title has-font-borest has-text-weight-medium">Media</h1>
                        <h2 class="subtitle has-font-sacramento has-text-weight-medium">
                            {{ t.meditations }}
                        </h2>

                        <div class="has-font-lota-light has-text-weight-bold has-text-weight-bold px-10">
                            <p>{{ t.imagine }}</p>
                            <p>{{ t.transport }}</p>
                            <p>{{ t.sensation }}</p>

                            <p class="mt-8">
                                {{ t.now }}
                            </p>
                            <p>{{ t.do }}</p>
                            <p>{{ t.prepared }}</p>
                        </div>
                        <b-button
                            class="button is-medium is-primary is-rounded px-15 mt-10 button-shadow mb-5-mobile"
                            @click="goToPage('Meditate')"
                            >{{ t.meditate }}</b-button
                        >
                    </div>
                </div>
                <div class="column is-half-desktop is-full-touch">
                    <figure class="image is-fullheight-percent">
                        <img
                            class="is-fullheight-percent object-cover"
                            src="@/assets/images/media/media.jpg"
                            alt="meditate" />
                    </figure>
                </div>
            </div>
        </section>

        <div class="is-hidden-touch">
            <div class="videos">
                <div class="video-title">
                    <section class="hero is-small">
                        <div class="hero-body is-align-self-center py-10">
                            <h1 class="title has-font-reft has-text-weight-medium has-text-centered">Vídeos</h1>
                        </div>
                    </section>
                </div>
                <section
                    class="hero is-fullheight"
                    v-show="videos.length > 0">
                    <div
                        id="videoDesktop"
                        class="hero-body is-justify-content-center">
                        <div class="columns is-mobile">
                            <div
                                id="video-column"
                                ref="videoColumn"
                                class="column is-7">
                                <youtube
                                    :resize="true"
                                    :fitParent="true"
                                    :video-id="currentVideo"
                                    ref="youtube"></youtube>

                                <p
                                    v-html="description[getLocale]"
                                    class="has-text-white is-size-5 has-font-lota-light has-text-weight-bold mt-4"
                                    :style="{ 'max-width': youTubeWidth + 'px' }"></p>
                            </div>

                            <div
                                class="column is-offset-1 is-overflow"
                                :style="{ height: youTubeHeight + 60 + 'px' }">
                                <div
                                    v-for="video in videos"
                                    :key="video.id">
                                    <div class="columns mb-2">
                                        <div class="column is-3">
                                            <div
                                                class="is-clickable"
                                                @click="selectVideo(video.videoId, video.description)">
                                                <figure class="image is-square">
                                                    <img
                                                        class="object-fit"
                                                        :src="video.image" />
                                                </figure>
                                            </div>
                                        </div>
                                        <div class="column is-9 is-flex is-align-items-center">
                                            <div class="has-text-white">
                                                <p
                                                    v-if="video.videoId == currentVideo"
                                                    class="has-text-secondary has-text-weight-semibold is-size-5">
                                                    {{ t.watching }}
                                                </p>
                                                <p class="has-font-lota-light has-text-weight-bold">ANA GARCEZ</p>
                                                <p class="has-font-lota-light has-text-weight-bold">
                                                    {{ moment(video.createdAt).utc().format('DD MMMM YYYY') }}
                                                </p>

                                                <p class="has-font-lota my-2 is-uppercase is-size-5">{{ video.title[getLocale] }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>

        <div class="is-hidden-desktop">
            <div class="videos">
                <div class="video-title">
                    <section class="hero is-small">
                        <div class="hero-body is-align-self-center">
                            <h1 class="title has-font-reft has-text-weight-medium">Vídeos</h1>
                        </div>
                    </section>
                </div>
                <section
                    id="videoMobile"
                    class="hero py-10">
                    <div class="hero-body">
                        <div class="columns is-multiline is-flex-direction-column is-align-items-flex-start is-justify-content-center">
                            <div class="column is-11-tablet is-10-mobile">
                                <youtube
                                    :resize="true"
                                    :fitParent="true"
                                    :video-id="currentVideo"
                                    ref="youtube"></youtube>

                                <h2
                                    v-html="description[getLocale]"
                                    class="has-text-white is-size-6 has-font-lota-light has-text-weight-bold mt-4"
                                    style="font-family: Lota Grotesque"
                                    :style="{ 'max-width': youTubeWidth + 'px' }"></h2>
                            </div>

                            <div class="column is-12-touch mobile-list is-overflow mt-10-touch">
                                <div
                                    v-for="video in videos"
                                    :key="video.id">
                                    <div class="columns is-mobile my-5">
                                        <div class="column is-4">
                                            <div
                                                class="is-clickable"
                                                @click="selectVideo(video.videoId, video.description)">
                                                <figure class="image is-square">
                                                    <img
                                                        class="object-fit"
                                                        :src="video.image" />
                                                </figure>
                                            </div>
                                        </div>
                                        <div class="column is-8 is-flex is-align-items-center">
                                            <div class="has-text-white">
                                                <p
                                                    v-if="video.videoId == currentVideo"
                                                    class="has-text-secondary has-text-weight-semibold is-size-5">
                                                    {{ t.watching }}
                                                </p>
                                                <p class="has-font-lota-light has-text-weight-bold">ANA GARCEZ</p>
                                                <p class="has-font-lota-light has-text-weight-bold">
                                                    {{ moment(video.createdAt).utc().format('DD MMMM YYYY') }}
                                                </p>

                                                <p class="has-font-lota my-2 is-uppercase">{{ video.title[getLocale] }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
    import Navbar from '@/components/Navbar.vue';
    import Footer from '@/components/Footer.vue';
    import Newsletter from '@/components/Newsletter.vue';
    import { getVideos } from '@/api/media';
    export default {
        components: {
            Navbar,
            Footer,
            Newsletter,
        },

        data() {
            return {
                videos: [],
                currentVideo: null,
                description: null,
                youTubeWidth: 800,
                youTubeHeight: 500,
                hasMounted: false,
            };
        },

        computed: {
            player() {
                return this.$refs.youtube.player;
            },
            t() {
                return this.$t('views.Media');
            },
        },

        methods: {
            getId(url) {
                return this.$youtube.getIdFromUrl(url);
            },

            selectVideo(id, description) {
                this.currentVideo = id;
                this.description = description;
            },

            handleResize() {
                const videoColumn = document.getElementById('video-column');
                // get the width of the video column without the padding

                const width = videoColumn.clientWidth - 20;
                this.youTubeWidth = width;
                this.youTubeHeight = width * 0.5625;
            },
            scrollToVideo() {
                this.$nextTick(() => {
                    if (this.hasMounted && this.$route.query?.v) {
                        // if window is smaller than 1024px, scroll to the id 'videoMobile'
                        if (window.innerWidth < 1024) {
                            document.getElementById('videoMobile').scrollIntoView();
                        } else {
                            // if window is bigger than 1024px, scroll to the id 'videoDesktop'
                            document.getElementById('videoDesktop').scrollIntoView();
                        }
                    }
                });
            },
        },

        created() {
            getVideos()
                .then((response) => {
                    this.videos = response.data.videos;

                    for (const video of this.videos) {
                        let videoId = this.getId(video.video);
                        video.videoId = videoId;
                    }

                    this.currentVideo = this.videos[0].videoId;
                    this.description = this.videos[0].description;

                    if (this.$route.query?.v) {
                        this.currentVideo = this.videos.find((video) => video._id === this.$route.query.v).videoId;
                        this.description = this.videos.find((video) => video._id === this.$route.query.v).description;
                    }
                    this.scrollToVideo();
                })
                .catch((error) => {
                    throw error;
                });

            window.addEventListener('resize', this.handleResize);
        },
        mounted() {
            try {
                this.handleResize();
            } catch (error) {

            }

            this.hasMounted = true;
            this.scrollToVideo();
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize);
        },

        updated() {
            this.handleResize();
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';

    #media {
        flex-direction: row;

        .columns {
            width: 100%;
            @include touch {
                margin-top: 6rem;
                & > .column:first-of-type {
                    margin-bottom: 2rem;
                }
            }
        }
    }

    .media {
        .hero {
            .hero-body {
                display: -webkit-box !important;

                figure,
                img {
                    height: 100%;
                    max-width: unset;
                    object-fit: cover;
                }
            }
        }
    }

    h1 {
        font-size: 6rem;
        @include mobile {
            font-size: 4rem;
        }
        @include tablet-only {
            font-size: 5rem;
        }
    }

    h2 {
        font-size: 4rem;
        @include mobile {
            font-size: 2.5rem;
        }
        @include tablet-only {
            font-size: 4rem;
        }
    }

    .videos {
        .hero {
            background-color: #2e2e2e;
        }
        .video-title {
            .hero {
                background-color: #f7f7f7;
            }
        }
    }

    .object-fit {
        object-fit: cover;
    }
    .is-overflow {
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .mobile-list {
        max-height: 400px;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: white;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: $primary;
    }
</style>
