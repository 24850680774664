import { post, get } from '@/utils/http';
const queryString = require('query-string');

export function getProducts(query = {}) {
    let _query = '?' + queryString.stringify(query);
    return get('/products/' + _query);
}

export function checkout(data) {
    return post('/stripe/checkout', data);
}

export function payPalCheckout(data) {
    return post('/orders', data);
}

export function giftCheckout(data) {
    return post('/gifts/checkout/stripe', data);
}
export function giftPaypalCheckout(data) {
    return post('/gifts/checkout/paypal', data);
}
