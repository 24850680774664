const state = {
    cart: [],
};

const getters = {
    getCart(state) {
        return state.cart;
    },
};

const mutations = {
    setCart(state, cart) {
        state.cart = cart;
    },

    clearCart(state) {
        state.cart = [];
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
