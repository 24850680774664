<template>
    <div>
        <section class="section">
            <div class="container pt-20-desktop pt-10-touch">
                <h1
                    v-html="details.title[getLocale]"
                    class="has-font-lota has-text-black has-text-centered"></h1>
                <div
                    class="template ql-editor has-font-lota mt-10"
                    v-html="template"></div>
            </div>
        </section>
    </div>
</template>

<script>
    import { getPosts } from '@/api/blog';
    export default {
        data() {
            return {
                details: [],
                template: null,
            };
        },

        created() {
            let query = {
                id: this.$route.params.id,
            };
            getPosts(query)
                .then((response) => {
                    this.details = response.data.posts[0];

                    let doc = new DOMParser().parseFromString(this.details.text[this.getLocale], 'text/html');
                    this.template = doc.documentElement.textContent;
                })
                .catch((error) => {
                    throw error;
                });
        },

        updated() {
            let doc = new DOMParser().parseFromString(this.details.text[this.getLocale], 'text/html');
            this.template = doc.documentElement.textContent;
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';

    h1 {
        font-size: 3rem;
        @include mobile {
            word-break: break-word;
            font-size: 2rem;
        }
    }
</style>

<style lang="scss">
    @import '~bulma/sass/utilities/mixins.sass';
    .template.ql-editor {
        p {
            font-size: 1.2rem;
            min-height: 1em;
            @include mobile {
                font-size: 1rem;
            }
        }
    }
</style>
