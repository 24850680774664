<template>
    <div
        class="is-relative"
        :class="{ 'nav-menu-open': isNavMenuOpen }">
        <Navbar />
        <ScrollProgress />
        <router-view />
        <Newsletter />
        <Footer />
    </div>
</template>

<script>
    import Navbar from '@/components/Navbar.vue';
    import Footer from '@/components/Footer.vue';
    import Newsletter from '@/components/Newsletter.vue';
    import ScrollProgress from '@/components/shared/ScrollProgress.vue';

    export default {
        name: 'App',
        components: {
            Navbar,
            Footer,
            Newsletter,
            ScrollProgress,
        },
        created() {
            addEventListener('resize', () => {
                this.updateWidth(innerWidth);
            });
        },
        computed: {
            isNavMenuOpen() {
                return this.$store.getters['app/getShowMobileNav'];
            },
        },
    };
</script>

<style lang="scss">
    @import './assets/scss/app';
    @import '~bulma/sass/utilities/mixins.sass';

    .nav-menu-open {
        // @include touch {
        //     overflow: hidden;
        //     max-height: 100vh;
        // }
    }

    @font-face {
        font-family: 'Lota Grotesque';
        src:
            local('Lota-Grotesque-Regular'),
            url(./fonts/Lota-Grotesque-Regular.otf) format('truetype');
    }

    @font-face {
        font-family: 'Lota Grotesque Light';
        src:
            local('Lota-Grotesque-Thin'),
            url(./fonts/Lota-Grotesque-Thin.otf) format('truetype');
    }

    @font-face {
        font-family: 'Reftograph';
        src:
            local('Reftograph'),
            url(./fonts/Reftograph.ttf) format('truetype');
    }

    @font-face {
        font-family: 'Borest-Standard';
        src:
            local('Borest-Standard'),
            url(./fonts/Borest-Standard.ttf) format('truetype');
    }
    @font-face {
        font-family: 'Sacramento';
        src:
            local('Sacramento'),
            url(./fonts/Sacramento.ttf) format('truetype');
    }
</style>
