<template>
    <div class="has-background-primary">
        <section class="section">
            <div class="container">
                <div class="columns is-mobile is-multiline is-vcentered is-fullwidth">
                    <div class="column is-12">
                        <div class="columns is-mobile is-multiline">
                            <div class="column is-12 is-flex is-flex-direction-row">
                                <img
                                    class="heart"
                                    src="../assets/icons/coracao_verde.png"
                                    alt="heart" />
                                <p class="heart-text has-font-borest has-text-white ml-1">ANAGARCEZ</p>
                            </div>
                        </div>
                    </div>
                    <div class="column is-12">
                        <div class="columns is-mobile is-multiline">
                            <div class="column is-6-desktop is-12-touch">
                                <p class="has-font-lota has-text-white">{{ t.author }}</p>

                                <div class="is-flex mt-6">
                                    <a
                                        href="https://www.instagram.com/belovebeyou/?hl=en"
                                        target="_blank">
                                        <div class="social center-inside mr-2">
                                            <b-icon
                                                icon="instagram"
                                                type="is-white">
                                            </b-icon></div
                                    ></a>

                                    <a
                                        href="https://pt-pt.facebook.com/pages/category/Health---Wellness-Website/Be-Love-Be-You-1739647529592163/"
                                        target="_blank">
                                        <div class="social center-inside mr-2">
                                            <b-icon
                                                icon="facebook"
                                                type="is-white">
                                            </b-icon></div
                                    ></a>

                                    <a
                                        href="https://twitter.com/belovebeyoufg"
                                        target="_blank">
                                        <div class="social center-inside mr-2">
                                            <b-icon
                                                icon="twitter"
                                                type="is-white">
                                            </b-icon></div
                                    ></a>

                                    <a
                                        href="https://pt.linkedin.com/in/ana-garcez-3a166338"
                                        target="_blank">
                                        <div class="social center-inside">
                                            <b-icon
                                                icon="linkedin"
                                                type="is-white">
                                            </b-icon>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div class="column is-2-desktop is-5-touch is-offset-1-desktop">
                                <p
                                    class="has-font-lota has-text-weight-bold has-text-white is-clickable mt-2"
                                    @click="goToPage('Home')">
                                    {{ t.about }}
                                </p>
                                <p
                                    class="has-font-lota has-text-weight-bold has-text-white is-clickable mt-2"
                                    @click="goToPage('GetInspired')">
                                    {{ t.inspire }}
                                </p>

                                <!--     <p class="has-font-lota has-text-weight-bold has-text-white is-clickable mt-2" @click="goToPage('Podcast')">PODCAST</p> -->
                                <p
                                    class="has-font-lota has-text-weight-bold has-text-white is-clickable mt-2"
                                    @click="goToPage('Media')">
                                    MEDIA
                                </p>
                                <b-icon
                                    class="mt-2 is-size-4 is-clickable"
                                    pack="bl-icons"
                                    icon="bl-cesto_compras"
                                    type="is-white"
                                    @click.native="goToPage('Checkout')">
                                </b-icon>
                            </div>

                            <div class="column is-2-desktop is-5-touch">
                                <p
                                    class="has-font-lota has-text-weight-bold has-text-white is-clickable mt-2"
                                    @click="goToPage('Blog')">
                                    BLOG
                                </p>
                                <p
                                    class="has-font-lota has-text-weight-bold has-text-white is-clickable mt-2"
                                    @click="goToPage('Store')">
                                    {{ t.store }}
                                </p>
                                <p
                                    class="has-font-lota has-text-weight-bold has-text-white is-clickable mt-2"
                                    @click="goToPage('YourOpinion')">
                                    {{ t.testimonies }}
                                </p>
                                <p
                                    class="has-font-lota has-text-weight-bold has-text-white is-clickable mt-2"
                                    @click="changePage('Home', '#participation')">
                                    {{ t.invites }}
                                </p>
                                <p
                                    class="has-font-lota has-text-weight-bold has-text-white is-clickable mt-2"
                                    @click="goToPage('Contacts')">
                                    {{ t.contacts }}
                                </p>
                                <p
                                    class="has-font-lota has-text-weight-bold has-text-white is-clickable mt-2"
                                    @click="goToPage('Terms')">
                                    {{ t.terms }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        computed: {
            t() {
                return this.$t('components.Footer');
            },
        },

        methods: {
            changePage(name, hash) {
                this.$router.push({ name: name, hash: hash });
                this.setShowSideMenu(false);
                this.setShowMobileNav(false);
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';

    .heart {
        width: 2rem;
        height: 2rem;
    }

    .heart-text {
        font-family: 'Borest-Standard';
        color: white;
        font-size: 1.5rem;
    }

    .social {
        background-color: #bfd9d1;
        width: 36px;
        height: 36px;
        border-radius: 19px;

        @include until($touch) {
            width: 41px;
            height: 41px;
        }
    }
</style>
