import Vue from 'vue';

// initial state
const state = {
    user: '',
    token: null,
};

const initial = Object.assign(state);

// getters
const getters = {
    getToken(state, getters) {
        return state.token;
    },

    getUser(state, getters) {
        return state.user;
    },
};

// actions
const actions = {
    authenticateUser({ commit }, userData) {
        // Set token
        const token = userData.headers.authorization;
        commit('setToken', token);
    },
};

// mutations
const mutations = {
    setToken(state, token) {
        state.token = token;
    },

    resetStore(s) {
        s = Object.assign(initial);
    },

    addUser(state, user) {
        Vue.set(state, 'user', user);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
