export default {
    methods: {
        goToPage(location) {
            if (this.$route.name !== location) {
                this.$router.push({ name: location });
                this.setShowSideMenu(false);
                this.setShowMobileNav(false);
            }
        },
    },
};
