<template>
    <div>
        <section
            class="hero is-fullheight is-relative"
            id="meal-planner">
            <div class="hero-body is-flex-direction-column is-justify-content-center">
                <div class="columns is-mobile is-fullwidth is-multiline">
                    <div class="column is-12-touch is-offset-1-desktop is-6-desktop">
                        <h1 class="has-font-borest">Meal Planner</h1>
                        <div class="columns">
                            <div class="column is-10-desktop is-9-fullhd is-12-touch mt-20-desktop mt-10-touch">
                                <p class="has-font-lota-light has-text-weight-bold has-text-black">{{ t.mealPlanner }}</p>
                            </div>
                        </div>

                        <div class="mt-6">
                            <b-button
                                rounded
                                class="sign-btn mr-6 mb-4-mobile has-text-white has-font-lota has-text-weight-bold"
                                @click="goToRegister">
                                {{ t.register }}</b-button
                            >
                            <b-button
                                rounded
                                class="login-btn has-font-lota has-text-weight-bold"
                                @click="goToLogin"
                                >{{ t.login }}</b-button
                            >
                        </div>

                        <div class="columns">
                            <div class="column is-11-desktop is-9-widescreen is-9-fullhd is-12-touch mt-10-desktop mt-3-touch">
                                <hr class="has-background-black" />
                            </div>
                        </div>
                    </div>
                    <div class="column is-12-touch is-5-desktop">
                        <h1 class="has-font-borest">Vídeo</h1>
                    </div>
                </div>
            </div>
            <ScrollArrow />
        </section>

        <div class="background-linear-pink-grey">
            <section
                class="section"
                id="belover">
                <div class="container">
                    <h1 class="has-font-borest has-text-white">Belover</h1>
                    <h2 class="has-font-borest has-text-white">meal planner</h2>
                    <div class="heart-image">
                        <img
                            src="../assets/images/mealPlanner/coracao_meal_planner.png"
                            alt="" />
                    </div>
                    <div class="columns is-mobile is-multiline is-vcentered">
                        <div class="benefits-container column is-12-touch">
                            <ul>
                                <li>
                                    <a href="#recipes-plant-based">{{ t.recipes }}</a>
                                </li>
                                <li>
                                    <a href="#tracker">TRACKER</a>
                                </li>
                                <li>
                                    <a href="#baby">{{ t.babyRecipes }}</a>
                                </li>
                                <li>
                                    <a href="#approved">{{ t.approvedRecipes }}</a>
                                </li>
                                <li>
                                    <a href="#cooking">{{ t.cooking }}</a>
                                </li>
                                <li>
                                    <a href="#new-recipes">{{ t.weekRecipes }}</a>
                                </li>
                                <li>
                                    <a href="#surprises">{{ t.surprises }}</a>
                                </li>
                                <li>
                                    <a href="#grocery">{{ t.grocery }}</a>
                                </li>
                                <li>
                                    <a href="#personalized">{{ t.personalizedRecipes }}</a>
                                </li>
                                <li>
                                    <a href="#workshops">{{ t.discount }}</a>
                                </li>
                                <li>
                                    <a href="#devices">{{ t.devices }}</a>
                                </li>
                            </ul>
                        </div>

                        <div class="column is-3-desktop is-12-touch">
                            <div class="meal-planner-box">
                                <p class="has-font-reft">{{ t.what }}</p>
                                <p class="has-font-reft">{{ t.did }}</p>
                                <p class="has-font-reft">{{ t.today }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section
                class="section"
                id="recipes-plant-based">
                <div class="container">
                    <div class="columns is-mobile is-multiline is-vcentered">
                        <div class="column is-8-desktop is-offset-1-desktop is-12-touch">
                            <h2 class="has-font-borest has-text-right-desktop has-text-black has-text-centered-touch">{{ t.more }}</h2>
                        </div>
                        <div class="column is-8-desktop is-offset-2-desktop is-12-touch">
                            <h1 class="has-font-borest has-text-right has-text-black has-text-centered-touch">{{ t.recipesTwo }}</h1>
                        </div>
                        <div class="column is-offset-1-desktop is-7-desktop is-12-touch">
                            <figure class="image py-10-desktop py-5-touch">
                                <img
                                    src="../assets/images/mealPlanner/receitas_mockup.png"
                                    class="recipes-image" />
                            </figure>
                        </div>
                        <div class="column is-4-desktop is-12-touch mt-8-desktop">
                            <h3 class="has-font-borest has-text-black has-text-centered-touch">{{ t.plant }}</h3>
                            <p class="has-font-lota has-text-black mt-4-touch pr-16-widescreen">{{ t.discover }}</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="background-linear-grey-white">
            <section
                class="section"
                id="tracker">
                <div class="container">
                    <div class="columns is-mobile is-multiline is-vcentered">
                        <div class="column is-6-desktop is-offset-1-desktop is-12-touch">
                            <h2 class="has-font-borest has-text-right-desktop has-text-centered-touch">{{ t.your }}</h2>
                        </div>
                        <div class="column is-8-desktop is-offset-2-desktop is-12-touch">
                            <h1 class="has-font-borest has-text-right has-text-centered-touch">Tracker</h1>
                        </div>
                        <div class="column is-offset-1-desktop is-6-desktop is-12-touch">
                            <figure class="image py-10-desktop py-5-touch">
                                <img
                                    src="../assets/images/mealPlanner/tracker.png"
                                    class="tracker-image" />
                            </figure>
                        </div>
                        <div class="column is-5-desktop is-12-touch mt-8-desktop">
                            <p class="has-font-lota pr-16-widescreen">
                                {{ t.believe }}<b> {{ t.tool }}</b
                                >,{{ t.help }} <b>{{ t.health }}</b>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <div
                class="columns is-mobile is-multiline is-relative m-0"
                id="baby-recipes">
                <div class="column is-12">
                    <h1 class="has-font-borest has-text-centered">{{ t.recipesTwo }}</h1>
                </div>

                <div class="column is-offset-1-desktop is-6-desktop is-12-touch mt-20-desktop mt-10-touch has-text-right p-0">
                    <figure class="image is-flex">
                        <img src="../assets/images/mealPlanner/bebe_receita.jpg" />
                    </figure>
                </div>
                <div class="column is-5-desktop is-12-touch pl-0 mt-15-desktop is-flex is-flex-direction-column is-justify-content-flex-start">
                    <div>
                        <h2 class="has-font-refto ml-10-desktop has-text-centered-touch has-text-black has-text-weight-light">{{ t.babys }}</h2>
                        <h2 class="has-font-refto ml-10-desktop has-text-centered-touch has-text-black has-text-weight-light">{{ t.sixMonths }}</h2>
                    </div>

                    <section class="hero is-small is-hidden-touch my-8-fullhd my-3-desktop">
                        <div class="hero-body"></div>
                    </section>
                    <p class="has-font-lota has-text-black pr-16-widescreen ml-10-desktop mt-5-desktop pr-8-desktop px-10-touch mt-6-touch">
                        {{ t.mother }}
                    </p>
                </div>
            </div>
        </div>

        <section
            class="section"
            id="plan">
            <div class="container">
                <div class="columns mt-10-desktop">
                    <div class="column is-6">
                        <div class="columns is-mobile is-multiline">
                            <div class="column is-11 is-offset-1-desktop">
                                <h2 class="has-font-borest has-text-centered-desktop has-text-centered-touch has-text-black">{{ t.your }}</h2>
                            </div>
                            <div class="column is-12">
                                <h1 class="has-font-borest has-text-centered has-text-black">{{ t.plan }}</h1>
                            </div>
                        </div>
                    </div>
                    <div class="column is-6">
                        <div class="columns is-mobile is-multiline">
                            <div class="column is-12">
                                <h2 class="has-font-borest has-text-centered has-text-black">{{ t.since }}</h2>
                            </div>
                            <div class="column is-12">
                                <h1 class="has-font-borest has-text-centered has-text-black is-flex is-justify-content-center">
                                    <h2>€</h2>
                                    0
                                    <h2>,54</h2>
                                    {{ t.day }}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <section class="section">
                    <div
                        class="columns is-multiline is-flex is-justify-content-center mt-10-desktop is-gapless"
                        id="prices-card">
                        <div class="column is-5-desktop is-5-widescreen is-4-fullhd is-12-touch mr-4-desktop">
                            <div class="price-header center-inside">
                                <h1 class="is-flex has-text-white has-font-reft has-text-weight-light">
                                    <h2>€</h2>
                                    <h1>16</h1>
                                    <h2>.50</h2>
                                    <h2>/{{ t.month }}</h2>
                                </h1>
                            </div>
                            <div class="mt-6">
                                <p class="has-text-centered has-font-borest">
                                    {{ t.investiment }}
                                </p>
                                <p class="has-text-centered has-font-borest">Anual € 198</p>
                                <figure class="image is-48x48 mx-auto mt-4">
                                    <img
                                        src="@/assets/icons/coracao_verde.png"
                                        alt="" />
                                </figure>
                                <p class="has-text-centered has-font-borest mt-6-desktop mt-8-mobile">{{ t.save }} 41€ / {{ t.year }}</p>
                                <p class="has-text-centered has-text-primary has-font-lota is-size-5 mt-6">
                                    {{ t.bestOption }}
                                </p>
                            </div>
                            <div class="py-20-desktop py-8-touch mt-8-mobile center-inside">
                                <b-button
                                    rounded
                                    class="sign-btn has-text-white mr-4"
                                    @click="goToRegister"
                                    >{{ t.register }}</b-button
                                >
                                <b-button
                                    rounded
                                    class="login-btn"
                                    @click="goToLogin"
                                    >{{ t.login }}</b-button
                                >
                            </div>
                        </div>
                        <div class="column is-5-desktop is-5-widescreen is-4-fullhd is-12-touch ml-4-desktop mt-10-touch">
                            <div class="price-header center-inside">
                                <h1 class="is-flex has-text-white has-font-reft has-text-weight-light">
                                    <h2>€</h2>
                                    <h1>19</h1>
                                    <h2>.99</h2>
                                    <h2>/{{ t.month }}</h2>
                                </h1>
                            </div>
                            <div class="mt-6">
                                <p class="has-text-centered has-font-borest">
                                    {{ t.investiment }}
                                </p>
                                <p class="has-text-centered has-font-borest">{{ t.monthly }}</p>
                                <p class="has-text-centered has-font-borest mt-14-desktop mt-10-mobile">
                                    {{ t.annualValue }}
                                </p>
                                <p class="has-text-centered has-font-borest">€239.88</p>
                            </div>
                            <div class="py-24-desktop py-8-touch mt-3-mobile center-inside">
                                <b-button
                                    rounded
                                    class="sign-btn has-text-white mr-4"
                                    @click="goToRegister"
                                    >{{ t.register }}</b-button
                                >
                                <b-button
                                    rounded
                                    class="login-btn"
                                    @click="goToLogin"
                                    >{{ t.login }}</b-button
                                >
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </section>

        <section
            class="section"
            id="approved-recipes">
            <div class="container">
                <div class="columns is-mobile is-multiline">
                    <div class="column is-12 has-text-centered has-font-reft has-text-black">
                        <h1>{{ t.recipesTwo }}</h1>
                    </div>
                    <div class="column is-12 has-text-centered has-font-reft has-text-black">
                        <h2>{{ t.approvedBy }}</h2>
                    </div>
                    <div class="column is-offset-1-desktop is-11 has-text-centered has-font-reft has-text-black">
                        <h2>{{ t.healthProfessionals }}</h2>
                    </div>
                </div>
            </div>
        </section>

        <div class="food-background">
            <section class="hero is-large is-relative">
                <div class="hero-body pt-0">
                    <div class="recipes-balloon">
                        <p>
                            {{ t.allRecipes }}
                        </p>
                    </div>
                </div>
            </section>
        </div>

        <section
            class="section"
            id="cooking">
            <div class="container">
                <div class="columns is-mobile is-multiline is-vcentered">
                    <div class="column is-9-fullhd is-offset-1-widescreen is-offset-3-desktop is-12-touch is-9-desktop is-8-widescreen">
                        <h2 class="has-font-reft has-text-black has-text-right-widescreen has-text-centered-touch">
                            {{ t.companion }}
                        </h2>
                    </div>
                    <div class="column is-9-fullhd is-offset-1-desktop is-12-touch is-11-desktop is-11-widescreen">
                        <h2 class="has-font-reft has-text-black has-text-right-fullhd has-text-centered-widescreen has-text-centered-touch">{{ t.questions }} {{ t.tips }}</h2>
                    </div>
                    <div class="column is-7-fullhd is-12-touch is-8-desktop">
                        <h1 class="has-font-borest has-text-black has-text-right-widescreen has-text-centered-touch">Cooking</h1>
                    </div>
                    <div class="column is-5-fullhd is-12-touch is-4-desktop">
                        <h2 class="has-font-reft has-text-black has-text-centered-touch">{{ t.together }}</h2>
                    </div>
                </div>
            </div>
        </section>

        <div class="computer-background">
            <section class="hero is-fullheight is-relative">
                <div class="hero-body">
                    <div class="columns is-mobile">
                        <div class="column is-7-desktop is-4-widescreen is-4-fullhd is-offset-1-desktop is-offset-0-widescreen">
                            <p class="has-text-black has-font-lota has-text-centered">
                                {{ t.group }}
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <section
            class="section"
            id="new-recipes">
            <div class="container">
                <div class="columns is-mobile is-multiline">
                    <div class="column is-offset-1-desktop is-6-desktop has-text-right-desktop is-12-touch has-text-centered-touch">
                        <h2 class="has-font-borest">{{ t.recipesTwo }}</h2>
                    </div>
                    <div class="column is-4-desktop is-offset-2-desktop has-text-right-desktop is-12-touch has-text-centered-touch">
                        <h1 class="has-font-borest">{{ t.new }}</h1>
                    </div>
                    <div
                        class="column is-12-desktop is-6-widescreen mt-4-desktop is-12-touch has-text-centered-touch"
                        :class="$i18n.locale == 'pt' ? 'is-offset-1-widescreen' : 'is-offset-2-widescreen'">
                        <h2 class="has-font-borest">{{ t.weekly }}</h2>
                    </div>
                    <div class="column is-6-desktop is-5-widescreen is-offset-6-desktop mt-4-desktop mt-6-touch is-7-touch">
                        <figure class="image large-image">
                            <img
                                class="is-rounded"
                                src="../assets/images/mealPlanner/cookies.png"
                                alt="cookies" />
                        </figure>
                    </div>
                    <div class="column is-5-desktop is-4-widescreen mt-4-desktop is-7-touch">
                        <figure class="image medium-image">
                            <img
                                class="is-rounded"
                                src="../assets/images/mealPlanner/launch.png"
                                alt="launch" />
                        </figure>
                    </div>
                    <div class="column is-4-desktop is-3-widescreen mt-4-desktop is-7-touch">
                        <figure class="image small-image">
                            <img
                                class="is-rounded"
                                src="../assets/images/mealPlanner/soup.png"
                                alt="soup" />
                        </figure>
                    </div>
                    <div class="column is-3-desktop is-12-touch has-text-centered-touch">
                        <p class="has-font-lota has-text-black has-text-weight-semibold">
                            {{ t.challange }}
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section
            class="section"
            id="surprises">
            <div class="container">
                <div class="columns is-mobile is-multiline">
                    <div class="column is-5-fullhd is-offset-1-widescreen is-offset-2-desktop is-12-touch is-9-desktop is-5-widescreen">
                        <h2 class="has-font-borest has-text-black has-text-right-widescreen has-text-centered-touch">
                            {{ t.challanges }}
                        </h2>
                    </div>
                    <div class="column is-12-fullhd is-12-touch is-12-desktop has-text-centered is-11-widescreen">
                        <h1 class="has-font-borest has-text-black">{{ t.surprisesTitle }}</h1>
                    </div>
                    <div class="column is-7-fullhd is-7-widescreen is-12-touch is-9-desktop is-offset-2-desktop has-text-right-desktop has-text-centered-touch">
                        <h2 class="has-font-borest has-text-black">{{ t.monthlyTitle }}</h2>
                    </div>
                    <div class="column is-5-fullhd is-6-widescreen is-12-touch is-6-desktop is-offset-5-desktop is-offset-6-fullhd mt-4">
                        <p class="has-font-lota has-text-black has-text-weight-semibold">{{ t.yourRegister }}</p>
                    </div>
                </div>
            </div>
        </section>

        <section
            class="hero is-medium is-secondary mt-26-desktop"
            id="grocery">
            <div class="hero-body pt-0">
                <div class="columns is-mobile is-multiline">
                    <div class="column is-4-desktop is-4-touch is-offset-4-touch is-offset-6-desktop">
                        <figure class="image">
                            <img
                                src="../assets/images/mealPlanner/coracao_logo_rosa_fuccia.png"
                                alt="" />
                        </figure>
                    </div>
                    <div class="column is-6-desktop is-offset-1-desktop is-offset-1-fullhd is-12-touch has-text-centered-touch has-text-centered-desktop">
                        <h1 class="has-text-white has-font-borest">{{ t.list }}</h1>
                    </div>
                    <div class="column is-6-desktop has-text-right-desktop is-6-fullhd is-12-touch has-text-centered-touch">
                        <h2 class="has-text-white has-font-borest">{{ t.purchases }}</h2>
                    </div>
                    <div class="column is-5-desktop is-4-fullhd is-12-touch">
                        <p class="has-text-white has-font-lota">{{ t.easy }}</p>
                    </div>
                </div>
            </div>
        </section>

        <section
            class="section"
            id="personalized-recipes">
            <div class="container">
                <div class="columns is-mobile is-multiline">
                    <div class="column is-12 has-text-centered">
                        <h2 class="has-font-reft has-text-black">{{ t.personalized }}</h2>
                    </div>
                    <div class="column is-12 has-text-centered">
                        <h1 class="has-font-reft has-text-black">{{ t.recipesTwo }}</h1>
                    </div>
                    <div class="column is-12 has-text-centered">
                        <h2 class="has-font-reft has-text-black">{{ t.function }}</h2>
                    </div>
                    <div class="column is-12 has-text-centered">
                        <h2 class="has-font-reft has-text-black">{{ t.healthState }}</h2>
                    </div>
                    <div class="column is-12-desktop has-text-centered is-8-widescreen is-offset-2-widescreen is-6-fullhd is-offset-3-fullhd mt-4-desktop has-text-weight-semibold">
                        <p class="has-font-lota has-text-black">{{ t.unique }}</p>
                    </div>
                </div>
            </div>
        </section>
        <section
            class="section"
            id="workshops">
            <div class="container">
                <div class="columns is-mobile is-multiline">
                    <div class="column is-12-desktop is-12-touch has-text-centered">
                        <h2 class="has-font-borest has-text-black">{{ t.discountsTitle }}</h2>
                    </div>
                    <div class="column is-10-desktop is-12-widescreen is-12-touch has-text-centered">
                        <h1 class="has-font-borest has-text-black">Workshops</h1>
                    </div>
                    <div class="column is-12-desktop is-12-touch has-text-centered">
                        <h2 class="has-font-borest has-text-black">{{ t.siteProducts }}</h2>
                    </div>

                    <div class="column is-8-desktop is-offset-2-desktop has-text-centered mt-4-desktop has-text-weight-semibold">
                        <p class="has-font-lota has-text-black">{{ t.envolve }}</p>
                    </div>
                </div>
            </div>
        </section>

        <section
            class="section"
            id="mobile">
            <div class="container">
                <div class="columns is-mobile is-multiline">
                    <div class="column is-12-desktop is-12-touch has-text-centered">
                        <h2 class="has-font-reft has-text-black">{{ t.available }}</h2>
                    </div>
                    <div class="column is-12-desktop has-text-centered is-12-touch">
                        <h1 class="has-font-borest has-text-black">{{ t.devicesTitle }}</h1>
                    </div>
                    <div class="column is-12-desktop is-12-touch has-text-centered">
                        <h2 class="has-font-reft has-text-black">{{ t.mobile }}</h2>
                    </div>
                </div>
            </div>
        </section>

        <div class="background-mobile">
            <section
                class="section"
                id="mobile-photo">
                <div class="container">
                    <div class="columns is-mobile is-multiline is-vcentered">
                        <div class="column is-offset-1-desktop is-8-desktop is-7-widescreen is-12-touch">
                            <figure class="image py-10-desktop py-5-touch">
                                <img
                                    src="../assets/images/mealPlanner/receitas_mockup.png"
                                    class="recipes-image" />
                            </figure>
                        </div>
                        <div class="column is-3-desktop is-8-touch is-offset-2-touch has-text-centered">
                            <p class="has-font-lota has-text-black mt-4-touch pr-16-widescreen">{{ t.allDevices }}</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    import ScrollArrow from '@/components/shared/ScrollArrow.vue';
    export default {
        components: {
            ScrollArrow,
        },

        methods: {
            goToRegister() {
                window.open('https://belovebeyou.iotech.pt/register', '_blank');
            },
            goToLogin() {
                window.open('https://belovebeyou.iotech.pt/login', '_blank');
            },
        },

        computed: {
            t() {
                return this.$t('views.MealPlanner');
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';

    #meal-planner {
        h1 {
            line-height: 1.2em;
            font-size: 5rem;
            @include until($touch) {
                font-size: 4.5rem;
            }

            @include from($fullhd) {
                font-size: 6.8rem;
            }
        }

        p {
            font-size: 1.6rem;
            letter-spacing: 0px !important;

            @include until($touch) {
                font-size: 1.2rem;
            }
        }

        .sign-btn {
            background-color: #3db8bc;
        }

        .login-btn {
            border: solid 2px #3db8bc;
            color: #3db8bc;
        }
    }

    .background-linear-pink-grey {
        background: rgb(217, 176, 195);
        background: linear-gradient(180deg, rgba(217, 176, 195, 1) 35%, rgba(234, 234, 234, 1) 80%);
    }

    #belover {


        ul {
            list-style-type: disc;
        }
        li::marker {
            color: white;
        }

        h1,
        h2 {
            line-height: 1.2em;
        }
        h1 {
            font-size: 15rem;
            @include until($touch) {
                font-size: 5rem;
            }
        }

        h2 {
            font-size: 6rem;
            @include until($touch) {
                font-size: 3rem;
            }
        }

        .benefits-container {
            min-height: 25rem;
            background-color: #f2d8e1;
            border: solid white 5px;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 39px;
            margin-top: 6rem;
            width: 70%;
            padding: 3rem;

            @include until($touch) {
                width: 100%;
                padding: 2rem;
                min-height: auto;
            }
        }

        .benefits-container a {
            color: $secondary;
            font-size: 1rem;
            font-family: 'Lota Grotesque';
            cursor: pointer;
            line-height: 2em;

            &:hover {
                color: white;
            }

            @include until($touch) {
                font-size: 1rem;
            }
        }

        .heart-image {
            position: absolute;
            right: 16%;
            transform: translateY(-3em);

            @include until($touch) {
                right: 0%;
                transform: translateX(2em);
            }
        }

        .heart-image img {
            width: 16rem;

            @include until($touch) {
                width: 10rem;
            }
        }

        .meal-planner-box {
            width: 100%;
            border: solid 2px white;
            margin: 0 auto;
            margin-top: 5rem;
            padding: 2rem;

            @include until($touch) {
                margin-left: 0;
                margin-right: 0;
                padding: 2rem;
                margin-top: 0rem;
            }
        }

        .meal-planner-box p {
            color: white;
            font-size: 2rem;
            text-align: center;
        }
    }

    #recipes-plant-based {
        h2 {
            font-size: 5rem;

            @include until($touch) {
                font-size: 3rem;
            }
        }

        h1 {
            font-size: 13rem;
            line-height: 0.5em;

            @include until($touch) {
                font-size: 5rem;
            }
        }

        h3 {
            font-size: 3.5rem;

            @include until($touch) {
                font-size: 3rem;
            }

            @include from($fullhd) {
                font-size: 5rem;
            }
        }

        p {
            font-size: 1rem;

            @include from($widescreen) {
                font-size: 1.3rem;
            }
        }
    }

    .background-linear-grey-white {
        background: rgb(248, 248, 246);
        background: linear-gradient(180deg, rgba(248, 248, 246, 1) 60%, rgba(255, 255, 255, 1) 80%);
    }

    #tracker {
        h2 {
            font-size: 5rem;
            color: #00b9bd;
            @include until($touch) {
                font-size: 4rem;
            }
        }

        h1 {
            font-size: 13rem;
            line-height: 0.5em;
            color: #00b9bd;
            @include until($touch) {
                font-size: 5rem;
            }
        }

        h3 {
            font-size: 3.5rem;

            @include until($touch) {
                font-size: 4rem;
            }

            @include from($fullhd) {
                font-size: 5rem;
            }
        }

        p {
            font-size: 1rem;
            color: #00b9bd;
            @include from($widescreen) {
                font-size: 1.3rem;
            }
        }
    }
    #baby-recipes {
        h2 {
            font-size: 4rem;
            line-height: 1em;

            @include from($fullhd) {
                font-size: 6rem;
            }

            @include until($touch) {
                font-size: 4rem;
            }
        }

        h1 {
            font-size: 13rem;
            line-height: 0.5em;
            color: #e0d2c9;
            @include until($touch) {
                font-size: 5rem;
            }
        }

        p {
            font-size: 1rem;
        }

        .hero {
            background-color: #e0d2c9;
            height: 5rem !important;

            @include from($fullhd) {
                height: 7rem !important;
            }
        }
    }

    #plan {
        h2 {
            font-size: 3rem;

            @include from($widescreen) {
                font-size: 4rem;
            }
        }

        h1 {
            font-size: 10rem;
            line-height: 0.5em;

            @include until($touch) {
                font-size: 4.5rem;
            }

            @include from($widescreen) {
                font-size: 13rem;
            }
        }

        @include mobile {
            section {
                padding-inline: 0;
            }
        }
    }

    #prices-card {
        .column {
            box-shadow: 0px 6px 28px #00000029;
            border-radius: 3rem;
        }

        .price-header {
            background-color: #bfd9d1;
            min-height: 16rem;
            border-top-right-radius: 3rem;
            border-top-left-radius: 3rem;
        }
        h1 {
            font-size: 9rem;

            @include until($touch) {
                font-size: 5.5rem;
            }
            @include from($widescreen) {
                font-size: 12rem;
            }
        }
        h2 {
            font-size: 4rem;
            @include mobile {
                font-size: 3.5rem;
            }
        }

        p {
            font-size: 2.8rem;
            @include mobile {
                font-size: 2rem;
            }
        }

        .sign-btn {
            background-color: #3db8bc;
        }

        .login-btn {
            border: solid 2px #3db8bc;
            color: #3db8bc;
        }
    }

    #approved-recipes {
        h1,
        h2 {
            line-height: 0.7em;
        }

        h1 {
            font-size: 16rem;
            letter-spacing: 0.625rem;
            font-weight: 500;
            @include until($touch) {
                font-size: 6rem;
            }
        }

        h2 {
            font-size: 7rem;
            @include until($touch) {
                font-size: 3rem;
            }
        }
    }

    .food-background {
        background-image: url('../assets/images/mealPlanner/approved-recipes.jpg');
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: -2;

        .hero {
            padding-bottom: 10rem;
        }

        .recipes-balloon {
            background-color: white;
            text-align: center;
            padding: 3rem;
            opacity: 0.7;
            border-bottom-left-radius: 5rem;
            border-bottom-right-radius: 5rem;
            width: 25rem;
            margin-left: 6.25rem;

            @include until($touch) {
                margin-left: 0;
                width: 100%;
            }
        }
    }

    #cooking {
        background-color: #f7f7f7;
        h2 {
            font-size: 3.5rem;
            line-height: 0.8em;

            @include until($touch) {
                font-size: 2.7rem;
            }

            @include from($widescreen) {
                font-size: 4rem;
            }
        }

        h1 {
            font-size: 10rem;
            line-height: 0.7em;

            @include until($touch) {
                font-size: 5rem;
            }

            @include from($widescreen) {
                font-size: 13rem;
            }
        }
    }

    .computer-background {
        background-image: url('../assets/images/mealPlanner/mackbook_mockup_.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: -2;

        @include mobile {
            background-position-y: 6rem;
            p {
                padding-bottom: 2rem;
            }
        }

        .hero-body {
            align-items: baseline !important;
        }
    }

    #new-recipes {
        h1 {
            line-height: 0.7em;
        }
        h2 {
            color: #c38859;
            font-size: 6rem;
            text-transform: lowercase;
            line-height: 0.5em;
            @include until($touch) {
                font-size: 3rem;
            }
        }
        h1 {
            color: #c38859;
            font-size: 13rem;
            @include until($touch) {
                font-size: 7rem;
            }
        }

        .columns {
            @include until($touch) {
                display: flex !important;
                justify-content: center !important;
                align-items: center !important;
            }
        }

        .large-image {
            transform: translateY(-2em);
            @include from($fullhd) {
                transform: translateY(-10em) translateX(2em);
            }
        }
        .medium-image {
            transform: translateY(-20em);
            @include from($fullhd) {
                transform: translateY(-36em);
            }
        }
        .small-image {
            transform: translateY(-3em);
            @include from($fullhd) {
                transform: translateY(-16em);
            }
        }

        .large-image,
        .medium-image,
        .small-image {
            @include until($touch) {
                transform: translateY(0);
            }
        }
    }

    #surprises {
        margin-top: -10em;
        @include until($touch) {
            margin-top: 0em;
        }

        @include from($widescreen) {
            margin-top: -10em;
        }
        @include from($fullhd) {
            margin-top: -20em;
        }

        h2 {
            font-size: 5rem;
            line-height: 0.8em;

            @include until($touch) {
                font-size: 2.5rem;
            }

            @include from($widescreen) {
                font-size: 3rem;
            }
        }

        h1 {
            font-size: 13rem;
            line-height: 1em;

            @include until($touch) {
                font-size: 4.5rem;
            }
        }
        p {
            font-size: 1rem;

            @include from($widescreen) {
                font-size: 1.2rem;
            }
        }
    }

    #grocery {
        figure {
            transform: translateY(-10em);
            @include until($touch) {
                transform: translateY(0em);
            }
        }

        h1 {
            font-size: 13rem;
            margin-top: -1.5em;

            @include from($widescreen) {
                margin-top: -2em;
                font-size: 10rem;
            }

            @include until($touch) {
                margin-top: 0;
                font-size: 5rem;
                line-height: 0.5em;
            }
        }

        h2 {
            font-size: 5rem;
            margin-top: -1em;

            @include from($widescreen) {
                margin-top: -1.5em;
            }

            @include until($touch) {
                margin-top: 0;
                font-size: 3.5rem;
            }
        }
        p {
            margin-top: -2.5em;

            @include from($widescreen) {
                margin-top: -5em;
            }
            @include until($touch) {
                margin-top: 0;
            }
        }
    }

    #personalized-recipes {
        h2 {
            font-size: 5rem;
            line-height: 0.7em;
            @include until($touch) {
                font-size: 3.5rem;
            }
        }

        h1 {
            font-size: 13rem;
            line-height: 0.4em;

            @include until($touch) {
                font-size: 7rem;
            }
        }
    }
    #workshops {
        h2 {
            font-size: 5rem;
            line-height: 0.9em;
            @include until($touch) {
                font-size: 2.8rem;
            }
        }

        h1 {
            font-size: 11rem;
            line-height: 0.9em;

            @include until($touch) {
                font-size: 4rem;
            }

            @include from($widescreen) {
                font-size: 13rem;
            }
        }
    }
    #mobile {
        h2 {
            font-size: 5rem;
            line-height: 0.9em;
            @include until($touch) {
                font-size: 2.5rem;
            }
        }

        h1 {
            font-size: 11rem;
            line-height: 0.9em;

            @include until($touch) {
                font-size: 4rem;
            }

            @include from($widescreen) {
                font-size: 13rem;
            }
        }
    }

    .background-mobile {
        background: rgb(242, 242, 239);
        background: linear-gradient(180deg, rgba(242, 242, 239, 1) 68%, rgba(255, 255, 255, 1) 96%);
    }
</style>
