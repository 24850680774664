<template>
    <div id="main">
        <div class="background">
            <section class="hero is-fullheight is-relative">
                <div class="hero-body is-flex-direction-column">
                    <div class="columns is-mobile is-fullwidth">
                        <div class="column is-12-touch is-6-fullhd is-8-widescreen is-12-desktop">
                            <div class="has-text-centered-desktop">
                                <h1 class="has-font-borest">{{ t.inspire }}</h1>
                                <h2 class="has-text-secondary has-font-sacramento">{{ t.now }}</h2>
                                <h3 class="has-font-lota-light has-text-weight-bold">{{ t.allow }}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollArrow />
            </section>
        </div>
        <section
            id="health-coach"
            class="section">
            <div class="container">
                <h1 class="has-font-borest has-text-black">{{ t.coach }}</h1>
            </div>
        </section>

        <div
            id="health-coach-columns"
            class="columns is-variable is-6 is-mobile is-centered is-multiline">
            <div class="column is-5-desktop is-offset-1-desktop is-11-touch">
                <h1 class="has-font-lota">{{ t.reflect }}</h1>
                <p class="has-font-lota-light has-text-weight-bold has-text-weight-bold my-4">
                    {{ t.reflectText }}
                </p>
                <p class="has-font-lota-light has-text-weight-bold has-text-weight-bold my-4">
                    {{ t.reflectTwo }}
                </p>
                <p class="has-font-lota-light has-text-weight-bold has-text-weight-bold my-4">
                    {{ t.reflectThree }}
                </p>
                <h2 class="has-font-sacramento my-6">
                    {{ t.reflectFour }}
                </h2>

                <div
                    id="pink-container"
                    class="has-text-white has-text-centered my-6 p-8">
                    <p class="has-font-reft">{{ t.healthCoach }}</p>
                    <p class="has-font-reft">{{ t.healthCoachTwo }}</p>
                </div>

                <p class="has-font-lota-light has-text-weight-bold has-text-weight-bold my-4">
                    {{ t.changeLife }}
                </p>

                <h2 class="has-font-sacramento my-6">
                    {{ t.equilibrium }}
                </h2>

                <p class="has-font-lota-light has-text-weight-bold has-text-weight-bold my-4">{{ t.important }} {{ t.changes }} {{ t.changeRythm }}</p>

                <h2 class="has-font-sacramento my-6">
                    {{ t.route }}
                </h2>

                <p class="has-font-lota-light has-text-weight-bold has-text-weight-bold">{{ t.changesTime }}</p>
            </div>
            <div class="column is-6-desktop is-11-touch pr-0">
                <div class="columns is-mobile is-multiline">
                    <div class="column is-11-desktop is-10-widescreen is-6-tablet is-12-mobile is-flex">
                        <figure class="image pr-6-mobile my-auto">
                            <img
                                src="@/assets/images/getInspired/vegetables.jpg"
                                alt="" />
                        </figure>
                    </div>
                    <div class="column is-8-widescreen is-offset-1-widescreen is-11-desktop is-6-tablet is-10-mobile">
                        <div class="my-10-widescreen my-10-mobile">
                            <h1 class="has-text-secondary has-font-lota">{{ t.not }}</h1>
                            <h1 class="has-text-secondary has-font-lota">{{ t.notTwo }}</h1>
                            <p class="has-font-lota-light has-text-weight-bold has-text-weight-bold mt-4">
                                {{ t.reflectFive }}
                            </p>
                        </div>
                    </div>
                    <div class="column is-11-widescreen is-offset-1-widescreen pr-0">
                        <figure
                            class="image"
                            id="light-photo">
                            <img
                                src="@/assets/images/getInspired/light.jpg"
                                alt="" />
                        </figure>
                        <h1 class="has-text-black has-font-lota mt-8">{{ t.time }}</h1>
                        <h1 class="has-text-black has-font-lota">{{ t.timeTwo }}</h1>
                        <h1 class="has-text-black has-font-lota">{{ t.timeThree }}</h1>
                    </div>
                </div>
            </div>
        </div>

        <section
            id="now"
            class="section pt-0">
            <div class="container has-text-centered">
                <h1 class="has-font-borest has-text-secondary">{{ t.nowTitle }}</h1>

                <p class="has-font-reft has-text-primary">{{ t.pointsOne }}</p>
                <p class="has-font-reft has-text-primary">{{ t.pointsTwo }}</p>
                <p class="has-font-reft has-text-primary">{{ t.pointsThree }}</p>

                <div class="has-text-centered">
                    <b-button
                        type="is-primary"
                        rounded
                        class="has-font-lota mt-10 px-16"
                        @click="goToPage('Store')">
                        {{ t.trip }}</b-button
                    >
                </div>

                <hr
                    id="horizontal-line"
                    class="mt-10" />
            </div>
        </section>

        <section
            id="approach"
            class="section">
            <div class="container">
                <h2 class="has-font-lota-light has-text-weight-bold ml-30">{{ t.my }}</h2>
                <h1 class="has-font-borest has-text-black">{{ t.approach }}</h1>
            </div>
        </section>

        <div
            class="columns has-background-secondary mb-0"
            id="approach-text-container">
            <div class="column is-7-widescreen is-6-desktop">
                <section class="section">
                    <div class="container has-text-white">
                        <p class="has-font-lota-light has-text-weight-bold">{{ t.approachText }}</p>
                        <br />
                        <p class="has-font-lota-light has-text-weight-bold">{{ t.approachTextTwo }}</p>
                        <br />
                        <p class="has-font-lota-light has-text-weight-bold">{{ t.today }}</p>
                        <br />
                        <p class="has-font-lota-light has-text-weight-bold">{{ t.minimum }}</p>
                        <br />
                        <p class="has-font-lota-light has-text-weight-bold">{{ t.food }}</p>
                        <br />
                        <p class="has-font-lota-light has-text-weight-bold">{{ t.badFood }}</p>
                        <br />
                        <p class="has-font-lota-light has-text-weight-bold">{{ t.badFoodTwo }}</p>
                        <br />
                        <p class="has-font-lota-light has-text-weight-bold">{{ t.simple }}</p>
                        <br />
                        <p class="has-font-lota-light has-text-weight-bold">{{ t.think }}</p>
                        <br />
                        <p class="has-font-lota-light has-text-weight-bold">{{ t.thinkTwo }}</p>
                        <br />
                        <p class="has-font-lota-light has-text-weight-bold">{{ t.obvious }}</p>
                        <br />
                        <p class="has-font-lota-light has-text-weight-bold">{{ t.differents }}</p>
                        <br />
                        <p class="has-font-lota-light has-text-weight-bold">{{ t.incredible }}</p>
                        <br />
                        <p class="has-font-lota-light has-text-weight-bold">{{ t.listen }}</p>
                        <br />
                        <p class="has-font-lota-light has-text-weight-bold">{{ t.doctors }}</p>
                        <br />
                        <p class="has-font-lota-light has-text-weight-bold">{{ t.connect }}</p>
                    </div>
                </section>
            </div>
            <div class="column is-5-widescreen is-6-desktop is-hidden-touch">
                <figure class="image">
                    <img
                        src="@/assets/images/getInspired/fruits.png"
                        alt="" />
                </figure>
            </div>
        </div>

        <section
            class="section has-background-white"
            id="food-columns"
            :key="approachKey">
            <div class="container">
                <div class="columns is-multiline is-centered is-mobile">
                    <div
                        class="column is-4-desktop is-6-tablet is-12-mobile is-flex is-justify-content-start is-flex-direction-column"
                        v-for="(ap, i) in approachs"
                        :key="i">
                        <h1 class="has-font-lota has-text-black has-text-centered px-5 mb-8-desktop mb-0-fullhd">{{ ap.title }}</h1>
                        <figure class="image mb-8 mt-4">
                            <img
                                class="is-rounded"
                                :src="ap.img"
                                alt="" />
                        </figure>
                        <p
                            class="px-6 px-6-touch"
                            v-html="ap.small"></p>
                        <p
                            class="px-6 px-6-touch"
                            v-show="ap.show">
                            {{ ap.description }}
                        </p>

                        <p
                            class="has-font-lota has-text-centered is-clickable mt-8"
                            @click="showMore(i)">
                            {{ ap.show ? t.showLess : t.showMore }}
                        </p>
                        <hr class="my-2" />
                    </div>

                    <div class="column is-4-desktop is-6-tablet is-10-mobile is-flex is-justify-content-start is-flex-direction-column">
                        <h1 class="has-font-lota has-text-black has-text-centered px-5 mb-8-desktop mb-0-fullhd">{{ t.learnMore }}</h1>
                        <figure class="image mb-8 mt-4">
                            <img
                                class="is-rounded"
                                src="@/assets/images/getInspired/Integrative_Nutrition.png"
                                alt="" />
                        </figure>
                        <p class="px-6 px-6-touch">
                            {{ t.visit }}
                            <a
                                id="link"
                                href="https://www.integrativenutrition.com/"
                                target="__blank"
                                >Institute for Integrative Nutrition</a
                            >
                            {{ t.visitTwo }}
                        </p>

                        <div class="center-inside mt-8">
                            <a
                                class=""
                                href="https://www.credly.com/badges/1e98dd0a-ef8b-4e81-836d-2d925315302b/embedded"
                                target="__blank">
                                <b-button
                                    type="is-primary"
                                    rounded
                                    class="has-font-lota px-16"
                                    >{{ t.seeBadge }}</b-button
                                >
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section
            id="soul-states"
            class="section">
            <div class="container">
                <h1 class="has-font-borest has-text-black has-text-centered">{{ t.soulStates }}</h1>
                <div class="columns is-gapless is-mobile is-multiline mt-20 pb-20">
                    <div class="column is-2-desktop is-12-touch">
                        <h2 class="has-font-lota-light has-text-weight-bold mb-4-desktop mb-0-widescreen mb-10-touch">{{ t.upload }}</h2>
                        <ScrollArrowHorizontal class="is-hidden-touch" />

                        <h3 class="right-scroll-text has-font-lota has-text-weight-bold has-text-black is-hidden-touch">{{ t.scroll }}</h3>
                    </div>
                    <div class="column is-10-desktop is-12-touch">
                        <b-carousel-list
                            v-model="test"
                            :data="items"
                            :items-to-show="photosToShow">
                            <template #item="list">
                                <div
                                    v-if="list._id != 'add'"
                                    class="card">
                                    <div class="card-image">
                                        <figure class="image is-1by1">
                                            <div>
                                                <img
                                                    class="object-fit"
                                                    :src="list.image"
                                                    loading="lazy" />
                                                <p class="has-text-white has-font-lota soul-name">{{ list.name }}</p>
                                                <p class="has-text-white has-font-lota soul-date">
                                                    {{ moment(list.createdAt).utc().format('DD/MM/YYYY') }}
                                                </p>
                                                <p class="has-text-white has-font-lota soul-like">
                                                    {{ list.likes || 0 }}
                                                </p>

                                                <b-icon
                                                    v-if="!hasLike(list.index)"
                                                    icon="heart-outline"
                                                    custom-size="mdi-16px"
                                                    type="is-white"
                                                    class="heart is-clickable"
                                                    @click.native="like(list._id, list.index)">
                                                </b-icon>
                                                <b-icon
                                                    v-else
                                                    icon="heart"
                                                    custom-size="mdi-16px"
                                                    type="is-white"
                                                    class="heart is-clickable"
                                                    @click.native="dislike(list._id, list.index)">
                                                </b-icon>
                                            </div>
                                        </figure>
                                    </div>
                                </div>
                                <div
                                    v-else
                                    class="tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center">
                                    <b-upload
                                        v-if="!uploadImg"
                                        v-model="uploadImg"
                                        class="file-label"
                                        accept="image/*">
                                        <b-icon
                                            size="is-large"
                                            icon="plus"
                                            type="is-black">
                                        </b-icon>
                                    </b-upload>

                                    <h3
                                        class="has-font-lota max-lg:tw-mt-4"
                                        v-if="uploadImg">
                                        {{ uploadImg.name }}
                                    </h3>
                                    <input
                                        v-if="uploadImg"
                                        class="my-4 p-1 has-font-lota"
                                        type="text"
                                        name="name"
                                        id="soulStateName"
                                        :placeholder="t.name"
                                        v-model="name"
                                        @click="onInputClick"
                                        required />
                                    <b-button
                                        @click="uploadImage"
                                        v-if="uploadImg"
                                        :disabled="!name"
                                        class="has-background-primary has-text-white max-lg:tw-mb-4"
                                        :loading="isLoading"
                                        >{{ t.send }}</b-button
                                    >
                                </div>
                            </template>
                        </b-carousel-list>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import { getImages, addImage, addLike, removeLike } from '@/api/soulStates.js';
    import ScrollArrow from '@/components/shared/ScrollArrow.vue';
    import ScrollArrowHorizontal from '@/components/shared/ScrollArrowHorizontal.vue';
    export default {
        components: {
            ScrollArrow,
            ScrollArrowHorizontal,
        },

        data() {
            return {
                progress: '0%',
                showIntegrativeNutrition: false,
                showPrimaryFood: false,
                showNoRestrict: false,
                showBioIndividuality: false,
                showVices: false,
                photosToShow: 4,
                name: '',
                test: 0,
                items: [],
                uploadImg: null,
                isLoading: false,
                approachKey: 0,
            };
        },

        methods: {
            uploadImage() {
                this.isLoading = true;
                addImage({ image: this.uploadImg, name: this.name })
                    .then((response) => {
                        this.uploadImg = null;
                        this.name = '';
                        this.$buefy.snackbar.open({
                            type: 'is-primary',
                            message: this.t.message,
                            actionText: 'Ok',
                            indefinite: true,
                            position: 'is-top',
                        });
                    })
                    .catch((error) => {
                        throw error;
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },

            hasLike(index) {
                return this.$store.getters['app/getLikedSoulStates'].find((s) => s === this.items[index]._id);
            },

            like(id, index) {
                addLike(id)
                    .then((response) => {
                        this.items[index].likes++;
                        this.$store.commit('app/addLikedSoulState', id);
                    })
                    .catch((error) => {
                        throw error;
                    });
            },

            dislike(id, index) {
                removeLike(id)
                    .then((response) => {
                        this.items[index].likes--;
                        this.$store.commit('app/removeLikedSoulState', id);
                    })
                    .catch((error) => {
                        throw error;
                    });
            },

            handleResize() {
                if (window.innerWidth < 740) {
                    this.photosToShow = 2;
                } else if (window.innerWidth < 1037) {
                    this.photosToShow = 3;
                } else this.photosToShow = 4;
            },

            showMore(index) {
                this.approachs[index].show = !this.approachs[index].show;
                this.approachKey++;
            },
            onInputClick() {
                console.log('click');
                document.getElementById('soulStateName').focus();
            },
        },

        computed: {
            t() {
                return this.$t('views.GetInspired');
            },
            approachs() {
                return [
                    {
                        title: this.t.integrativeNutrition,
                        img: require('@/assets/images/getInspired/Integrative_nutrition.jpg'),
                        small: this.t.integrativeNutritionText,
                        description: this.t.integrativeNutritionTextTwo,
                        show: false,
                    },
                    {
                        title: this.t.primaryFood,
                        img: require('@/assets/images/getInspired/primary_food.jpg'),
                        small: this.t.primaryFoodText,
                        description: this.t.primaryFoodTextTwo,
                        show: false,
                    },
                    {
                        title: this.t.reduce,
                        img: require('@/assets/images/getInspired/food_square.jpg'),
                        small: this.t.reduceText,
                        description: this.t.reduceTextTwo,
                        show: false,
                    },
                    {
                        title: this.t.bio,
                        img: require('@/assets/images/getInspired/fruit-heart.png'),
                        small: this.t.bioText,
                        description: this.t.bioTextTwo,
                        show: false,
                    },
                    {
                        title: this.t.addiction,
                        img: require('@/assets/images/getInspired/donuts.jpg'),
                        small: this.t.addictionText,
                        description: this.t.addictionTextTwo,
                        show: false,
                    },
                ];
            },
        },

        mounted() {
            window.addEventListener('resize', this.handleResize);

            getImages()
                .then((response) => {
                    this.items = response.data.soul_states.reverse();
                    this.items.unshift({
                        _id: 'add',
                    });
                    this.handleResize();
                })
                .catch((error) => {
                    throw error;
                });
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize);
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';
    @import '@/assets/scss/mixins.scss';

    #main {
        background-color: $grey-variant;
        overflow-x: hidden;
    }

    .background {
        background-image: url('../assets/images/getInspired/arrow.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-color: $grey-variant;
        z-index: -2;
        background-position-x: right;

        h1,
        h2,
        h3 {
            line-height: 1.2em;
        }
        h1 {
            font-size: 8rem;
            @include until($fullhd) {
                font-size: 6rem;
            }
            @include until($desktop) {
                font-size: 4rem;
            }
        }
        h2 {
            font-size: 5rem;
            @include until($fullhd) {
                font-size: 4rem;
            }
            @include until($desktop) {
                font-size: 3.5rem;
            }
        }
        h3 {
            font-size: 4.5rem;
            @include until($fullhd) {
                font-size: 3.5rem;
            }
            @include until($desktop) {
                font-size: 3rem;
            }
        }

        .hero-body {
            margin-top: 8rem;
        }

        @include mobile {
            .column {
                padding: 0;
            }
            .hero-body {
                justify-content: center;
                margin-top: 15rem;
            }
        }
    }

    #health-coach {
        h1 {
            font-size: 11.5rem;
            @include until($fullhd) {
                font-size: 8rem;
            }
            @include mobile {
                font-size: 3rem;
            }
        }
    }

    #health-coach-columns {
        h2 {
            font-size: 2.5rem;
            // @include until($touch) {
            //     font-size: 3rem;
            // }
        }

        h1 {
            font-size: 1.1rem;
            @include tablet {
                font-size: 1.5rem;
            }

            @include from($widescreen) {
                font-size: 1.7rem;
            }
            @include from($fullhd) {
                font-size: 2.2rem;
            }
        }
    }

    .vegetables-photo {
        width: 100%;
        height: auto;
    }

    #pink-container {
        background: #c2749c;
        box-shadow: -3px 6px 11px #00000029;
        border: 1px solid #a8325d;

        p {
            font-size: 1.5rem;
        }
    }

    #light-photo {
        box-shadow: -3px 6px 11px #00000029;
    }

    #now {
        h1 {
            font-size: 12rem;

            @include until($touch) {
                font-size: 6rem;
            }
            @include until($fullhd) {
                font-size: 10rem;
            }
            @include from($fullhd) {
                font-size: 14rem;
            }
            @include mobile {
                font-size: 5rem;
            }
        }

        p {
            font-size: 2.5rem;
            @include until($touch) {
                font-size: 2rem;
            }
        }
    }

    #horizontal-line {
        background-color: $black;
    }

    #approach {
        h2 {
            font-size: 2.5rem;
            @include from($widescreen) {
                font-size: 3.5rem;
            }
        }

        h1 {
            font-size: 10rem;
            @include until($touch) {
                font-size: 4rem;
                text-align: center;
            }
            @include mobile {
                font-size: 3.8rem;
            }

            @include from($fullhd) {
                font-size: 15rem;
            }
        }
    }

    #approach-text-container {
        .column:nth-of-type(1) > section {
            overflow-y: auto;
            @include scrollbar($white, $white);
            max-height: 20em;
            padding: 0;
            padding-right: 1em;
            margin: 2em;
            font-size: 1rem;
            font-weight: bold;

            @include tablet {
                font-size: 1.2rem;
            }

            @include desktop {
                margin-left: auto;
                padding-left: 2.5em;
                max-width: 40em;
                max-height: 30em;
                font-size: 1.2rem;
            }
        }

        .column:nth-of-type(2) {
            figure {
                display: block;
                position: relative;
                max-width: 30em;
                position: absolute;
                right: 0;
                margin-top: -140px;
            }
            img {
                transform: scaleX(-1);
            }

            max-height: 30em;
            padding: 0;
        }
    }

    #food-columns {
        h1 {
            height: 3em;
            font-size: 1.2rem;

            @include mobile {
                height: unset;
            }

            @include from($widescreen) {
                font-size: 1.5rem;
            }
        }

        figure {
            max-height: 12rem;
            img {
                height: 100%;
                width: auto;
                margin-left: auto;
                margin-right: auto;
            }
        }

        p {
            font-size: 1.25rem;
            color: black;
            text-align: justify;
            &:first-of-type {
                height: 4.5em;
                overflow: hidden;
            }

            @include mobile {
                font-size: 1rem;
            }
        }

        hr {
            background-color: #707070;
            width: 50%;
            display: flex;
            margin: 0 auto;
            height: 1px;
        }

        #link {
            color: black;
            text-decoration: underline;
        }

        @include desktop {
            margin-top: 3rem;
            // every column after the third
            .column:nth-of-type(n + 4) {
                margin-top: 3rem;
            }
        }
        @include touch {
            .column {
                margin-top: 2rem;
            }
        }
    }

    #soul-states {
        h1 {
            font-size: 11rem;
            @include until($widescreen) {
                font-size: 5rem;
            }
        }
        h2 {
            font-size: 1.3rem;
            letter-spacing: 0.34rem !important;

            @include until($widescreen) {
                font-size: 1rem;
            }
            @include from($fullhd) {
                font-size: 1.5rem;
            }
        }

        .right-scroll-text {
            position: absolute;
            bottom: 0px;
        }

        .column:nth-of-type(1) {
            position: relative;
        }

        .column:nth-of-type(2) {
            border: solid 1px #707070;
        }
        .column:nth-of-type(3) {
            border: solid 1px #707070;
        }

        .soul-name {
            position: absolute;
            bottom: 5rem;
            left: 1.8rem;
            text-transform: uppercase;

            @include until($mobile) {
                top: 1rem;
                left: 1rem;
                font-size: 0.8rem;
            }
        }

        .soul-date {
            position: absolute;
            bottom: 3rem;
            left: 1.8rem;
            letter-spacing: 2px;
            font-size: 0.9rem;

            @include until($mobile) {
                top: 3rem;
                left: 1rem;
                font-size: 0.8rem;
            }
        }

        .soul-like {
            position: absolute;
            bottom: 0.6rem;
            right: 5.6rem;

            @include until($mobile) {
                bottom: 0.6rem;
                right: 5.6rem;
            }
        }

        .heart {
            position: absolute;
            bottom: 0.6rem;
            right: 3.1rem;
        }

        .object-fit {
            object-fit: cover;
        }
    }

    /*  .vl {
        border-left: 2px solid #707070;
        height: 300px;
    } */
</style>
