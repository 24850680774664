import Vue from 'vue';
import App from './App.vue';
import Buefy from 'buefy';
import './assets/scss/app.scss';
import router from './router';
import i18n from './i18n';
import store from './store';

import './assets/scss/spacing.scss';
import './assets/scss/icons.scss';

import './mixins/index';
import 'vue-swatches/dist/vue-swatches.css';

import moment from 'moment';

moment.locale('pt');
Vue.prototype.moment = moment;

Vue.use(Buefy);

import requiresAuth from './utils/requiresAuth';
requiresAuth();

Vue.config.productionTip = false;

import VueYoutube from 'vue-youtube';

Vue.use(VueYoutube);

const setDPR = () => {
    // get device pixel ratio
    const dpr = window.devicePixelRatio || 1;
    // set as a css var
    document.documentElement.style.setProperty('--dpr', dpr);
};

addEventListener('resize', setDPR);
setDPR();

new Vue({
    router,
    i18n,
    store,
    render: (h) => h(App),
}).$mount('#app');
