<template>
    <div>
        <div class="columns is-mobile is-multiline pt-30-desktop">
            <div class="column is-12">
                <div class="columns is-mobile is-multiline">
                    <div class="column is-5-desktop is-12-touch mt-20-desktop mt-10-touch">
                        <div class="columns is-multiline is-mobile">
                            <div class="column is-4-desktop is-12-touch mt-20">
                                <div class="img-box center-inside">
                                    <a
                                        class="center-inside"
                                        href="https://www.credly.com/badges/1e98dd0a-ef8b-4e81-836d-2d925315302b/embedded">
                                        <figure class="image">
                                            <img
                                                class="inn mx-auto"
                                                src="../assets/images/getInspired/Integrative_Nutrition.png"
                                                alt="" />
                                        </figure>
                                    </a>
                                </div>
                            </div>
                            <div class="column is-7-desktop is-12-touch">
                                <h1 class="has-font-lota has-text-black has-text-weight-bold has-text-centered-fullhd px-4-touch">HEALTH COACHING</h1>
                                <p class="has-font-lota has-text-black mt-16-desktop mt-6-touch px-4-touch">
                                    {{ t.formation }} <b>{{ t.inn }}</b>
                                    {{ t.learned }}
                                </p>
                            </div>

                            <div class="column is-11-desktop is-10-touch is-offset-1-touch">
                                <figure class="image">
                                    <img
                                        class="food"
                                        src="../assets/images/inn/food.jpg"
                                        alt="food" />
                                </figure>
                            </div>
                        </div>
                    </div>

                    <div class="column is-7-desktop is-12-touch mt-20-desktop mt-10-touch pr-0">
                        <div class="columns is-multiline is-mobile">
                            <div class="column is-7-desktop is-12-touch">
                                <h1 class="has-font-lota has-text-black has-text-weight-bold has-text-centered-touch">{{ t.oriented }}</h1>
                                <p class="has-font-lota has-text-black mt-16-desktop mt-8-touch px-4-touch">
                                    <b> Joshua Rosenthal</b> <br />
                                    {{ t.joshuaText }}
                                    <br />
                                    <br />

                                    <b>Deepak Chopra MD</b> <br />
                                    {{ t.deepakText }} <br /><br />

                                    <b>David Katz</b> <br />
                                    {{ t.davidText }} <br />
                                    <br />

                                    <b>Walter Willett</b> <br />{{ t.walterText }} <br />
                                    <br />

                                    <b>Andrew Weil</b><br />
                                    {{ t.andrewText }}<br />
                                    <br />

                                    <b>Gabrielle Bernstein</b> <br />
                                    {{ t.gabrielleText }} <br />
                                    <br />

                                    <b>Susan Blum</b> <br />
                                    {{ t.susanText }}<br />
                                    <br />

                                    <b>Mark Hyman</b> <br />
                                    {{ t.markText }} <br /><br />

                                    <b>Geneen Roth</b> {{ t.gennenText }} <br />
                                    <br />

                                    <b>David Wolfe</b> <br />
                                    {{ t.wolfeText }}<br />
                                    <br />

                                    <b>Marion Nestle</b> {{ t.marionText }} <br />
                                    <br />

                                    <b>Mark Bittman</b> <br />
                                    {{ t.bittmanText }}
                                </p>
                            </div>
                            <div class="column is-5-desktop is-10-touch is-offset-1-touch has-text-centered">
                                <figure class="image">
                                    <img
                                        src="../assets/images/inn/armario.jpg"
                                        alt="" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            t() {
                return this.$t('views.INN');
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';

    .img-box {
        border: 1px solid black;
        width: 90%;
        height: 12.5rem;
        border-top-right-radius: 4.3rem;
        border-bottom-right-radius: 4.3rem;

        @include until($touch) {
            width: 96%;
        }
    }
    .inn {
        width: 50%;
        @include until($touch) {
            width: 20%;
        }
    }

    h1 {
        font-size: 1.5rem;
    }
</style>
