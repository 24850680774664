const state = {
    language: 'pt',
};

// getters
const getters = {
    getLanguage(state, getters) {
        return state.language;
    },
};

// mutations
const mutations = {
    setLanguage(state, language) {
        state.language = language;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
