<template>
    <div>
        <div class="columns is-mobile is-multiline pt-40-desktop">
            <div class="column is-12">
                <div class="columns is-mobile is-multiline">
                    <div class="column is-2-desktop is-12-touch mt-20-desktop mt-10-touch">
                        <div class="img-box center-inside mt-20-touch">
                            <figure class="image">
                                <img
                                    class="inn mx-auto"
                                    src="../assets/images/MusicTherapy/lusiada.png"
                                    alt="" />
                            </figure>
                        </div>
                    </div>

                    <div class="column is-3-desktop is-12-touch">
                        <h1 class="has-font-lota has-text-black has-text-weight-bold has-text-centered-touch px-4-touch">{{ t.music }}</h1>
                        <p class="has-font-lota has-text-black mt-16-desktop mt-6-touch px-4-touch">
                            {{ t.master }}
                            <br />
                            <br />
                            {{ t.wfmt }}
                        </p>
                    </div>
                    <div class="column is-4-desktop is-offset-1-desktop">
                        <h1 class="column-title has-font-lota has-text-black has-text-weight-bold has-text-centered-touch">{{ t.oriented }}</h1>
                        <p class="column-text has-font-lota has-text-black mt-16-desktop mt-8-touch px-4-touch">
                            <b> {{ t.allan }}</b> <br />{{ t.allanText }} <br />
                            <br />

                            <b>{{ t.robin }}</b> {{ t.robinText }} <br />
                            <br />

                            <b>{{ t.antonio }}</b
                            >, {{ t.antonioText }} <br />
                            <br />

                            <b>{{ t.eduarda }}</b> <br />{{ t.eduardaText }}
                        </p>

                        <figure class="image mt-10">
                            <img
                                class="mx-auto"
                                src="../assets/images/MusicTherapy/therapy.jpg"
                                alt="" />
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            t() {
                return this.$t('views.MusicTherapy');
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';
    .img-box {
        border: 1px solid black;
        width: 90%;
        height: 12.5rem;
        border-top-right-radius: 4.3rem;
        border-bottom-right-radius: 4.3rem;

        @include until($touch) {
            width: 96%;
        }
    }
    .inn {
        width: 86%;
        @include until($touch) {
            width: 50%;
        }
    }

    h1 {
        font-size: 1.5rem;
    }
</style>
