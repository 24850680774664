<template>
    <div
        v-show="show"
        id="scroll-progress"
        :class="show ? 'is-flex' : ''"
        class="progress-container is-flex-direction-row is-align-items-center">
        <h1 class="scroll-text-bar mr-6">SENTE ESTE PROJETO</h1>
        <div class="progress-indicator-wrapper">
            <div
                class="progress-indicator"
                :style="getStyleIndicator"></div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import vars from '@/assets/scss/_variables.scss';
    export default {
        name: 'ScrollProgress',
        data() {
            return {
                progress: '0%',
                windowScroll: null,
                height: null,
            };
        },
        mounted() {
            window.addEventListener('scroll', this.updateProgressIndicator);
        },
        computed: {
            getBackground() {
                return `has-background-${_.get(this.$route, 'meta.scrollProgress', 'primary')}`;
            },
            getStyleIndicator() {
                return {
                    width: this.progress,
                    'background-color': vars[_.get(this.$route, 'meta.scrollProgress', 'primary')],
                };
            },
            show() {
                return (this.windowScroll / this.height) * 100 > 3;
            },
            // getStyleWrapper() {
            //     let rColor = _.get(this.$route, 'meta.scrollProgresss', 'white');
            //     let color = _.get(vars, rColor, rColor);
            //     return {
            //         border: `1px solid ${color}`,
            //     };
            // },
        },
        methods: {
            updateProgressIndicator() {
                const { documentElement, body } = document;
                this.windowScroll = body.scrollTop || documentElement.scrollTop;
                this.height = documentElement.scrollHeight - documentElement.clientHeight;
                this.progress = (this.windowScroll / this.height) * 100 + '%';
                const scrollProgress = document.getElementById('scroll-progress');
                // set css var with offsetWidth
                this.$nextTick(() => {
                    document.documentElement.style.setProperty('--scroll-progress-width', `-${scrollProgress.offsetWidth / 2.15}px`);
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';

    $progress-indicator-height: 3px;

    .progress-container {
        position: fixed;
        z-index: 1;
        top: 50%;
        left: 0;
        transform: translateX(var(--scroll-progress-width, -100vw)) rotate(90deg);

        @include until($desktop) {
            transform: rotate(90deg);
            display: none !important;
        }

        @media screen and (min-width: 1920px) {
            z-index: 20;
        }
    }

    .scroll-text-bar {
        font-size: 0.8rem;
        letter-spacing: 2px;
        font-family: 'Lota Grotesque';
        color: #6e6e6e;
        font-size: 10px;
        width: max-content;
    }

    // Progress Indicator
    .progress-indicator-wrapper {
        height: 5px;
        background-color: #eee;
        width: 40vh;
        border: 1px solid $white-ter;
        border-radius: 3px;
        z-index: 28;

        .progress-indicator {
            height: $progress-indicator-height;
        }
    }
</style>
