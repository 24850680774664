<template>
    <section class="section">
        <div class="container">
            <h1 class="has-font-borest has-text-secondary pt-20">{{ t.teachers }}</h1>

            <div class="columns is-mobile is-multiline is-centered mt-5">
                <div
                    v-for="sheet in sheets"
                    :key="sheet.id"
                    class="column column-sheet is-flex is-half-desktop is-full-touch my-5 mr-auto">
                    <a
                        class="sheet"
                        :href="sheet.href"
                        target="_blank"
                        ><img
                            :src="require(`../assets/images/teachers/${sheet.img}`)"
                            alt=""
                    /></a>
                    <div>
                        <p class="has-font-lota ml-4 is-size-4 mt-4">{{ sheet.name }}</p>
                        <p class="has-font-lota ml-4 mt-2">{{ sheet.p }}</p>
                        <p class="has-font-lota ml-4 mt-2">{{ sheet.p2 }}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import Navbar from '@/components/Navbar.vue';
    import Footer from '@/components/Footer.vue';
    export default {
        components: { Navbar, Footer },

        created() {
            this.clearCart();
        },

        computed: {
            t() {
                return this.$t('views.Teachers');
            },

            sheets() {
                return [
                    {
                        name: this.$t('views.Teachers.writtenSheet'),
                        img: 'Ficha_escrita.png',
                        href: '/documents/Ficha_Escrita.pdf',
                        p: this.$t('views.Teachers.writtenP'),
                        p2: this.$t('views.Teachers.writtenP2'),
                    },
                    {
                        name: this.$t('views.Teachers.sugarMath'),
                        img: 'Ficha_matematica.png',
                        href: '/documents/Mat_acucar.pdf',
                        p: this.$t('views.Teachers.sugarP'),
                        p2: this.$t('views.Teachers.sugarP2'),
                    },
                    {
                        name: this.$t('views.Teachers.picnic'),
                        img: 'Ficha_picnic.png',
                        href: '/documents/picnic.pdf',
                        p: this.$t('views.Teachers.picnicP'),
                        p2: this.$t('views.Teachers.picnicP2'),
                    },
                    {
                        name: this.$t('views.Teachers.math'),
                        img: 'Ficha_matematica2.png',
                        href: '/documents/matematica.pdf',
                        p: this.$t('views.Teachers.mathP'),
                    },
                    {
                        name: this.$t('views.Teachers.group'),
                        img: 'Ficha_grupo.png',
                        href: '/documents/Varias_fichas.pdf',
                        p: this.$t('views.Teachers.groupP'),
                    },
                ];
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';

    h1 {
        font-size: 8rem;

        @include until($touch) {
            font-size: 3rem;
        }
    }

    a {
        min-width: 140px;
    }

    .sheet {
        display: block;
        @include mobile {
            display: flex;
            justify-content: center;
            margin-bottom: 1rem;
            margin-top: 1rem;
        }
        /*   @include until($mobile) {
            display: flex;
            justify-content: center;
            margin-bottom: 1rem;
            margin-top: 1rem;
        } */
    }
    .column-sheet {
        flex-direction: row;

        @include mobile {
            flex-direction: column-reverse;
            p {
                margin-inline: 0 !important;
            }
        }
    }
</style>
