<template>
    <div>
        <section
            class="hero is-fullheight is-relative has-background-grey pt-10-desktop"
            id="blog-posts">
            <div class="hero-body is-flex-direction-column is-justify-content-center">
                <div class="columns is-fullwidth is-mobile is-flex is-multiline pt-10">
                    <div class="column is-8-desktop is-5-widescreen is-offset-1-desktop is-offset-2-widescreen is-12-touch">
                        <h1 class="has-font-reft has-text-black">{{ t.meditate }}</h1>
                    </div>
                    <div class="column is-8-desktop is-5-widescreen is-offset-1-desktop is-offset-2-widescreen is-12-touch">
                        <p class="has-font-lota has-text-black is-size-7-touch">
                            {{ t.now }}
                        </p>
                    </div>
                </div>
                <div class="columns is-fullwidth is-mobile is-flex is-justify-content-center is-multiline">
                    <div class="column is-10-desktop is-8-widescreen is-12-touch is-flex is-justify-content-center px-0">
                        <div
                            @scroll="scrollPost"
                            id="posts-container"
                            class="columns is-fullwidth is-mobile pb-2">
                            <div
                                v-for="post in posts"
                                :key="post.id"
                                class="column is-4-tablet is-6-mobile"
                                :ref="post._id"
                                :id="post._id">
                                <figure
                                    class="image is-1by1 is-clickable"
                                    @click="seePostDetail(post._id)">
                                    <img
                                        :src="post.image"
                                        alt=""
                                        class="object-fit" />
                                </figure>
                                <p class="has-font-lota is-size-5-desktop is-size-7-touch is-size-6-widescreen is-size-5-fullhd has-text-black mt-2">{{ post.title[getLocale] }}</p>
                                <p class="has-font-lota is-size-7-desktop is-size-7-touch is-size-7-widescreen">{{ moment(post.createdAt).utc().format('DD MMMM YYYY') }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="column is-12 has-text-centered mt-2">
                        <p class="has-text-black has-font-lota-light has-text-weight-bold is-size-5-desktop is-size-6-touch">{{ t.drag }}</p>
                    </div>
                </div>

                <div class="columns is-fullwidth is-mobile is-flex is-justify-content-center pt-10 is-multiline">
                    <div
                        class="column is-2-desktop is-6-mobile"
                        v-for="(category, index) in categories"
                        :key="index"
                        @click="goToPage(category.page)">
                        <div class="is-flex is-flex-direction-column is-justify-content-center is-align-content-center is-fullheight my-4">
                            <div class="center-inside is-relative">
                                <div
                                    class="icon-category is-clickable"
                                    :class="category.class"></div>
                            </div>

                            <p class="icon-title has-font-lota has-text-weight-semibold has-text-centered mt-5 is-clickable">{{ category.title }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Navbar from '@/components/Navbar.vue';
    import Footer from '@/components/Footer.vue';
    import Newsletter from '@/components/Newsletter.vue';

    import { getPosts } from '@/api/blog';
    export default {
        components: {
            Navbar,
            Footer,
            Newsletter,
        },

        data() {
            return {
                posts: [],
                page: 1,
                limit: 5,
                totalItems: 0,
                fetchingPosts: false,
            };
        },

        computed: {
            t() {
                return this.$t('components.BlogCategories.Meditate');
            },

            categories() {
                return [
                    {
                        title: this.t.healthyFood,
                        class: 'icon-carrot',
                        page: 'HealthyFood',
                    },
                    {
                        title: this.t.exercise,
                        class: 'icon-exercise',
                        page: 'Exercise',
                    },
                    {
                        title: this.t.all,
                        class: 'icon-all',
                        page: 'AllPosts',
                    },
                    {
                        title: this.t.wellBeing,
                        class: 'icon-heart',
                        page: 'WellBeing',
                    },
                    {
                        title: this.t.file,
                        class: 'icon-files',
                        page: 'Files',
                    },
                ];
            },
        },

        methods: {
            scrollPost(e) {
                if (this.fetchingPosts) return;
                const { target } = e;
                if (Math.abs(target.scrollLeft) >= target.scrollWidth - target.clientWidth - 100) {
                    if (this.totalItems > 0) {
                        this.page = this.totalItems / this.limit;
                        this.totalItems = 0;
                    }
                    this.page++;

                    let query = {
                        page: this.page,
                        limit: this.limit,
                        category: 'meditation',
                        order: 'desc',
                    };

                    this.fetchingPosts = true;
                    getPosts(query)
                        .then((response) => {
                            this.posts.push(...response.data.posts);
                        })
                        .catch((error) => {
                            throw error;
                        }).finally(() => {
                            this.fetchingPosts = false;
                        });
                }
            },

            seePostDetail(id) {
                if (this.totalItems > 0) {
                    this.setPageRequested(this.totalItems / this.limit);
                } else this.setPageRequested(this.page);

                this.setSavedID(id);
                this.$router.push({ name: 'Post', params: { id: id } });
                this.setShowSideMenu(false);
            },
        },

        created() {
            if (this.mustScroll) {
                this.totalItems = this.pageRequested * this.limit;

                this.setMustScroll(false);
                let query = {
                    page: this.page,
                    limit: this.totalItems,
                    order: 'desc',
                    category: 'meditation',
                };
                getPosts(query)
                    .then((response) => {
                        this.posts = response.data.posts;
                    })
                    .catch((error) => {
                        throw error;
                    })
                    .finally(() => {
                        document.getElementById(this.getSavedId).scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                            inline: 'center',
                        });
                    });
            } else {
                let query = {
                    page: this.page,
                    limit: this.limit,
                    category: 'meditation',
                    order: 'desc',
                };
                getPosts(query)
                    .then((response) => {
                        this.posts = response.data.posts;
                    })
                    .catch((error) => {
                        throw error;
                    });
            }
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';

    @import '@/assets/scss/mixins.scss';

    .hero {
        h1 {
            font-size: 7.5rem;
            line-height: 0.7em;

            @include until($touch) {
                font-size: 6rem;
            }
        }
    }

    #posts-container {
        display: inline-flex;
        overflow-x: scroll;
        @include scrollbar($dark);

        .object-fit {
            object-fit: cover;
        }
    }

    .icon-category {
        width: 1.5rem;
        height: 4rem;
        background: #545454;
        mask-size: cover;
    }
    .icon-title {
        font-size: 1rem;
        color: #545454;

        @include from($fullhd) {
            font-size: 1.3rem;
        }
    }

    @mixin mask() {
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
    }

    .icon-carrot {
        mask: url('../../assets/images/blog/Grupo_156.svg');

        @include mask();
        @include until($mobile-l) {
            height: 6.4rem;
        }
    }

    .icon-exercise {
        mask: url('../../assets/images/blog/Grupo_152.svg');
        width: 3rem;
        @include mask();
        @include until($mobile-l) {
            height: 6.4rem;
        }
    }

    .icon-meditate {
        mask: url('../../assets/images/blog/Grupo_155.svg');
        width: 3rem;
        @include mask();
        @include until($mobile-l) {
            width: 6.4rem;
            height: 6.4rem;
        }
    }
    .icon-all {
        mask: url('../../assets/images/blog/Grupo_153.svg');
        height: 3.8rem;
        width: 3rem;
        @include mask();
        @include until($mobile-l) {
            width: 6.4rem;
            height: 6.4rem;
        }
    }
    .icon-files {
        mask: url('../../assets/images/blog/Grupo_154.svg');
        height: 3.75rem;
        width: 3rem;
        @include mask();
        @include until($mobile-l) {
            width: 6.4rem;
            height: 6.4rem;
        }
    }
    .icon-heart {
        mask: url('../../assets/images/blog/coracao-01.svg');
        height: 3.2rem;
        width: 3rem;
        @include mask();
        @include until($mobile-l) {
            width: 6.4rem;
            height: 6.4rem;
        }
    }
</style>
