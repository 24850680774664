<template>
    <div>
        <section class="section">
            <div class="container">
                <h1 class="has-font-borest has-text-centered has-text-primary">Short CV</h1>

                <p class="has-font-lota mt-5">{{ t.textOne }}</p>
                <p class="has-font-lota">{{ t.textTwo }}</p>
                <p class="has-font-lota">{{ t.textThree }}</p>
                <p class="has-font-lota">{{ t.textFour }}</p>
                <p class="has-font-lota has-text-weight-bold has-text-black">{{ t.textFive }}</p>
                <br />
                <p class="has-font-lota">{{ t.textSix }}</p>
                <br />
                <p class="has-font-lota has-text-secondary">{{ t.textSeven }}</p>
                <br />
                <p class="has-font-lota has-text-secondary">{{ t.textEight }}</p>
                <br />
                <p class="has-font-lota">{{ t.textNine }}</p>
                <br />
                <p class="has-font-lota">{{ t.textTen }}</p>
                <br />
                <p class="has-font-lota">{{ t.textEleven }}</p>
                <br />
                <p class="has-font-lota">{{ t.textTwelve }}</p>
                <br />
                <p class="has-font-lota">{{ t.textThirteen }}</p>
                <br />
                <p class="has-font-lota">{{ t.textFourteen }}</p>
                <br />
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        computed: {
            t() {
                return this.$t('views.Curriculum');
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';

    h1 {
        font-size: 8rem;

        @include until($touch) {
            font-size: 3rem;
        }
    }
</style>
