<template>
    <div>
        <div class="background">
            <section class="hero is-fullheight is-relative">
                <div class="hero-head is-flex-direction-column is-justify-content-center">
                    <div class="columns is-mobile is-fullwidth">
                        <div class="column is-11-touch is-offset-1-touch is-4-desktop">
                            <div class="has-text-centered-desktop">
                                <h1 class="has-font-borest has-text-white pb-40-desktop">{{ t.store }}</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="hero-body">
                    <div class="columns is-mobile is-fullwidth">
                        <div class="column is-8-desktop is-offset-3-desktop is-offset-4-widescreen is-12-touch has-text-centered-touch">
                            <h2 class="has-font-sacramento has-text-white">{{ t.think }}</h2>
                            <h2 class="has-font-sacramento has-text-white">{{ t.thinkTwo }}</h2>
                        </div>
                    </div>
                </div>
                <ScrollArrow />
            </section>
        </div>

        <section
            class="hero is-halfheight"
            id="books-section">
            <div
                class="hero-body is-justify-content-center is-flex-direction-column tw-relative"
                id="categories">
                <h1 class="has-font-borest has-text-black mb-12">{{ t.categories }}</h1>
                <b-loading
                    v-model="isLoadingBooks"
                    :is-full-page="false"
                    :can-cancel="false"></b-loading>
                <div class="columns is-mobile is-multiline is-fullwidth">
                    <div
                        v-for="category in categories"
                        :key="category._id"
                        class="column is-6-touch is-4-desktop mb-8">
                        <Category
                            :image="category.images[0]"
                            :id="category._id"
                            :name="category.name[getLocale]" />
                    </div>
                </div>
            </div>
        </section>

        <section
            class="hero is-fullheight"
            id="garcez-cooking">
            <div class="hero-body">
                <div class="columns is-multiline is-mobile">
                    <div class="column is-align-self-flex-end is-4-widescreen is-12-touch mt-48-widescreen mt-6-touch mt-6-desktop mb-32-widescreen mb-4-desktop mb-4-touch">
                        <h2 class="is-uppercase has-font-lota health-food-text">{{ t.healthyFood }}<br />{{ t.everyDay }}</h2>

                        <div class="is-flex is-flex-direction-column is-justify-content-center">
                            <a
                                href="https://www.garcezcooking.com/"
                                target="_blank"
                                class="is-flex">
                                <b-button
                                    type="is-primary"
                                    class="button-radius px-8 mx-auto is-size-5 mt-12-widescreen mt-6-desktop mt-6-touch"
                                    >{{ t.seeDetails }}</b-button
                                ></a
                            >

                            <SocialButtons class="mt-16-widescreen mt-8-desktop mt-8-touch" />
                        </div>
                    </div>

                    <div class="column is-offset-1-widescreen is-7-widescreen is-12-touch has-text-centered">
                        <h1
                            class="has-font-borest ml-auto has-text-centered has-text-black"
                            id="cooking">
                            Garcez<br />Cooking<br />School
                        </h1>
                        <p class="has-text-black has-text-weight-bold has-font-lota my-8">{{ t.realTime }}</p>

                        <div class="text-box p-10-desktop p-6-touch">
                            <p
                                class="is-uppercase has-font-lota"
                                v-html="t.cookingText"></p>
                            <figure class="image is-24x24 mx-auto mt-12">
                                <img
                                    src="@/assets/icons/coracao_verde.png"
                                    alt="" />
                            </figure>
                        </div>
                        <figure class="image is-16by9 mt-24-desktop mt-12-touch mb-32-widescreen mb-12-desktop mb-4-touch">
                            <img
                                src="../assets/images/store/Garcez_cooking.jpg"
                                alt="" />
                        </figure>
                    </div>
                </div>
            </div>
        </section>

        <section
            class="hero"
            id="health-coaching">
            <div class="hero-body is-justify-content-center is-flex-direction-column">
                <h1
                    class="has-font-reft has-text-secondary has-text-centered"
                    id="health">
                    {{ t.coach }}
                </h1>
                <h1 class="has-font-reft has-text-secondary has-text-centered">
                    {{ t.coachTwo }}
                </h1>
            </div>
        </section>

        <section
            class="hero"
            id="health-coaching-content">
            <div class="hero-body p-0">
                <div class="columns is-mobile is-multiline is-gapless">
                    <div class="column is-6-desktop is-12-touch">
                        <b-image
                            id="journey_img"
                            @load="setHeight"
                            responsive
                            :src="require('@/assets/images/store/journey.jpg')"></b-image>
                    </div>
                    <div class="column is-6-desktop is-12-touch has-background-secondary">
                        <div class="m-5-touch m-10-desktop is-flex">
                            <p class="my-5-touch mr-3-touch pr-2-touch ml-5-touch my-10-desktop ml-10-desktop mr-8-desktop pr-2-desktop has-text-white is-uppercase has-font-lota health-coaching-text">
                                {{ t.healthCoachTextOne }}
                                <br />
                                {{ t.healthCoachTextTwo }}
                                <br />
                                {{ t.healthCoachTextThree }}

                                {{ t.healthCoachTextFour }}
                                <br />
                                {{ t.healthCoachTextFive }}
                                <br />
                                <br />
                                {{ t.healthCoachTextSix }}
                                <br />
                                <br />
                                {{ t.healthCoachTextSeven }}
                                <br />
                                <br />
                                {{ t.healthCoachTextNine }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section
            class="hero py-18-desktop"
            id="health-coaching-store">
            <div class="hero-body">
                <div class="columns is-mobile is-multiline">
                    <div class="column is-6-desktop is-12-touch">
                        <div class="is-flex justify-content">
                            <p class="has-text-centered has-font-reft is-size-3 ml-11-desktop prepared-text">{{ t.version }}<br /><br />{{ t.learn }}</p>
                        </div>
                    </div>
                    <div class="column is-4-desktop is-12-touch is-offset-1-desktop">
                        <div class="is-flex is-justify-content-center is-flex-direction-column">
                            <b-button
                                type="is-primary"
                                class="button-radius mb-12 px-8 is-size-5 mt-12-widescreen mt-6-desktop mt-6-touch"
                                @click="buyHealthCoach"
                                :loading="isLoading"
                                >{{ t.buy }}</b-button
                            >
                            <SocialButtons centered />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <BannerLinks />

        <section
            class="hero"
            id="meditations">
            <div class="hero-body is-align-self-center">
                <h1
                    class="has-font-reft has-text-centered"
                    id="meditations">
                    {{ t.meditations }}
                </h1>
                <figure class="imagem img-bowl mx-auto">
                    <img
                        src="@/assets/images/store/bowl.jpg"
                        alt="" />
                </figure>
                <div class="columns is-mobile is-centered mt-23-desktop mt-12-touch">
                    <div class="column is-6-desktop is-12-touch has-text-centered">
                        <p class="has-font-lota-light has-text-weight-bold has-text-centered is-size-5">{{ t.imagine }}<br /><br />{{ t.now }}</p>
                        <b-button
                            rounded
                            class="gold-button mt-24-desktop mt-12-touch p-6 has-font-lota"
                            @click="goToPage('Meditate')">
                            {{ t.relax }}
                        </b-button>
                    </div>
                </div>
                <h1 class="has-font-reft has-text-centered">
                    {{ t.reiki }}
                </h1>

                <figure class="image heart-reiki mx-auto">
                    <img
                        src="@/assets/images/store/reiki_heart.jpg"
                        alt="" />
                </figure>
                <div class="center-inside">
                    <b-button
                        rounded
                        class="gold-button mt-24-desktop mt-12-touch p-6 has-font-lota"
                        @click="goToPage('Reiki')">
                        {{ t.moreInfo }}
                    </b-button>
                </div>
            </div>
        </section>

        <section id="gift-card">
            <h1 class="has-font-reft has-text-centered has-text-primary">Gift Card</h1>

            <form
                @submit.prevent="giftCardCheckout"
                class="card">
                <div class="columns is-multiline is-mobile">
                    <div class="column is-12-mobile is-6-desktop">
                        <b-field>
                            <b-input
                                :placeholder="t.from"
                                type="email"
                                v-model="from"
                                required>
                            </b-input>
                        </b-field>
                    </div>
                    <div class="column is-12-mobile is-6-desktop">
                        <b-field>
                            <b-input
                                :placeholder="t.to"
                                type="email"
                                v-model="to"
                                required>
                            </b-input>
                        </b-field>
                    </div>
                    <div class="column is-12">
                        <b-field>
                            <b-select
                                expanded
                                v-model="giftProduct"
                                required>
                                <option
                                    value=""
                                    disabled
                                    selected>
                                    {{ t.selectProduct }}
                                </option>
                                <option
                                    v-for="product in categories"
                                    :key="product._id"
                                    placeholder="Selecione um produto"
                                    :value="product._id">
                                    {{ product.name[getLocale] }}
                                </option></b-select
                            >
                        </b-field>
                    </div>
                    <div class="column is-12">
                        <textarea
                            name=""
                            v-model="message"
                            id=""
                            cols="30"
                            rows="10"></textarea>
                    </div>
                </div>

                <div class="center-inside is-flex-direction-column buttons-container">
                    <b-button
                        style="margin: 0 auto"
                        class="is-flex btn"
                        type="is-primary"
                        size="is-large"
                        :loading="isLoading"
                        native-type="submit"
                        >Checkout</b-button
                    >
                    <div
                        style="margin: 0 auto"
                        id="paypal-button-container"
                        class="mt-4"></div>
                </div>
            </form>
        </section>
    </div>
</template>

<script>
    import Category from '@/components/store/Category.vue';
    import SocialButtons from '@/components/store/SocialButtons';
    import BannerLinks from '@/components/store/BannerLinks.vue';
    import { getProducts, giftCheckout, giftPaypalCheckout, checkout } from '@/api/store';
    import { loadScript } from '@paypal/paypal-js';
    import ScrollArrow from '@/components/shared/ScrollArrow.vue';
    export default {
        name: 'Store',
        components: {
            BannerLinks,
            Category,
            SocialButtons,
            ScrollArrow,
        },
        data() {
            return {
                categories: [],
                giftProduct: '',
                from: null,
                to: null,
                message: null,
                isLoading: false,
                isLoadingBooks: false,
            };
        },
        mounted() {
            addEventListener('resize', this.setHeight);
            this.isLoadingBooks = true;
            getProducts({
                store: true,
            })
                .then((response) => {
                    this.categories = response.data.products;

                    this.paypal();
                })
                .catch((error) => {
                    throw error;
                })
                .finally(() => {
                    this.isLoadingBooks = false;
                });
        },
        computed: {
            getCategoriesColumns() {
                let temporary = [];
                let chunk = 3;
                for (let i = 0, j = this.categories.length; i < j; i += chunk) {
                    temporary.push(this.categories.slice(i, i + chunk));
                }
                return temporary;
            },
            t() {
                return this.$t('views.Store');
            },
        },
        methods: {
            setHeight() {
                let el = document.getElementById('journey_img');
                if (el) document.querySelector(':root').style.setProperty('--img-height', `${el.offsetHeight}px`);
            },

            giftCardCheckout() {
                this.isLoading = true;
                let data = {
                    buyer_email: this.from,
                    receiver_email: this.to,
                    product: this.giftProduct,
                    quantity: 1,
                    message: this.message,
                    language: this.getLocale,
                };
                giftCheckout(data)
                    .then((response) => {
                        this.isLoading = false;

                        window.location.href = response.data.session.url;
                    })
                    .catch((error) => {
                        this.$buefy.toast.open({
                            message: this.t.errorMessage,
                            position: 'is-top',
                            type: 'is-secondary',
                        });
                        throw error;
                    });
            },

            async paypal() {
                let paypal;
                let t = this;
                try {
                    paypal = await loadScript({
                        'client-id': 'AelSxaixTplWJjIpPfYBCNsS9etWjNbPGAJDegaNMRgT6u6cUzQ4c69FR2J6xtwF0PUk3swgAH2um0a8',
                        'disable-funding': 'credit,card',
                    });
                } catch (error) {
                    console.error('failed to load the PayPal JS SDK script', error);
                }

                if (paypal) {
                    try {
                        await paypal
                            .Buttons({
                                style: {
                                    label: 'paypal',
                                    height: 40,
                                    shape: 'pill',
                                },

                                onApprove: function (data, actions) {
                                    return actions.order.capture().then(function (orderData) {
                                        // Full available details
                                        console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));
                                        let data = {
                                            buyer_email: t.from,
                                            receiver_email: t.to,
                                            product: t.giftProduct,
                                            quantity: 1,
                                            message: t.message,
                                            language: t.getLocale,
                                            paypal_checkout: null,
                                        };

                                        data.paypal_checkout = orderData.id;

                                        giftPaypalCheckout(data)
                                            .then((response) => {
                                                t.goToPage('OrderSucess');
                                            })
                                            .catch((error) => {
                                                t.$buefy.toast.open({
                                                    message: t.t.errorMessage,
                                                    position: 'is-top',
                                                    type: 'is-secondary',
                                                });
                                                throw error;
                                            });
                                    });
                                },

                                onError: function (err) {
                                    console.log(err.message);

                                    if (err.message != 'Document is ready and element #paypal-button-container does not exist')
                                        t.$buefy.toast.open({
                                            message: t.t.errorMessage,
                                            position: 'is-top',
                                            type: 'is-secondary',
                                        });
                                },
                            })
                            .render('#paypal-button-container');
                    } catch (error) {
                        console.error('failed to render the PayPal Buttons', error);
                    }
                }
            },

            buyHealthCoach() {
                this.isLoading = true;
                let data = {
                    products: [
                        {
                            product: 'prod_LnoM0zzRkjNwhp',
                            quantity: 1,
                            price: 'price_1L6CkBEfa2R3O8dTyGhAEkC6',
                        },
                    ],
                };

                checkout(data)
                    .then((response) => {
                        if (this.isMobile) {
                            window.location.assign(response.data.session.url, '_blank');
                        } else window.location.href = response.data.session.url;
                    })
                    .catch((error) => {
                        throw error;
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
        },
        destroyed() {
            removeEventListener('resize', this.setHeight);
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';
    @import '@/assets/scss/mixins.scss';

    .background {
        background-image: url('../assets/images/store/banner.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        z-index: -2;
        background-position-x: center;
        background-position-y: top;

        h1,
        h2 {
            line-height: 1.2em;
        }
        h1 {
            font-size: 10rem;
            transform: translateY(0.5em);
            @include mobile {
                transform: translateY(0.8em);
                font-size: 7rem;
            }
        }

        h2 {
            font-size: 2.4rem;
            transform: translateY(2.5em);

            @include from($widescreen) {
                font-size: 3rem;
                transform: translateY(1.5em);
            }
        }
    }

    #books-section {
        h1 {
            font-size: 10rem;
            @include until($desktop) {
                font-size: 3rem;
            }
        }
        background-color: #f7f7f7;
    }

    #garcez-cooking {
        position: relative;
        background-color: #eaeaea;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            content: ' ';
            width: 100vw;
            height: 100vh;
            background-image: url('../assets/images/store/spoons.png');
            background-size: contain;
            background-position: left;
            background-repeat: no-repeat;
            z-index: 0;
        }

        .hero-body {
            @include desktop {
                margin-left: 7vw;
                margin-right: 7vw;
            }
        }

        > * {
            z-index: 1;
        }

        .column:nth-of-type(1) {
            @include until-widescreen {
                order: 2;
            }
        }

        .column:nth-of-type(2) {
            @include until-widescreen {
                order: 1;
            }
        }

        h1 {
            font-size: 12rem;
            line-height: 1.1em;
            @include until($desktop) {
                font-size: 5rem;
            }
        }

        .text-box {
            border: 2px solid black;
            @include until-widescreen {
                background-color: rgba(255, 255, 255, 0.9);
            }

            p {
                overflow-y: auto;
                max-height: 20em;
                line-height: 2.4em;
                letter-spacing: 0.15em !important;
                padding-right: 0.2em;
                @include until-widescreen {
                    font-size: 0.75rem;
                }
                @include scrollbar($dark, $dark);
            }
        }

        h2 {
            font-size: 2.5rem;
            @include until-widescreen {
                font-size: 1.7rem;
            }
        }

        figure.is-16by9 {
            max-width: unset;
            width: auto;
        }
    }

    #health-coaching {
        h1 {
            font-size: 11rem;
            line-height: 1.1em;
            @include touch {
                font-size: 4rem;
            }
        }
    }

    #health-coaching-content {
        .column {
            height: var(--img-height);
            @include touch {
                height: unset;
            }
            overflow: hidden;
        }
        .column:nth-of-type(2) {
            > div {
                border: 2px solid #ffffff;
                height: calc(var(--img-height) - 5rem);
                @include touch {
                    // height: calc(var(--img-height) - 2rem);
                    height: 30rem;
                }
                p {
                    overflow-y: auto;
                    line-height: 2.4em;
                    letter-spacing: 0.15em !important;
                    @include touch {
                        font-size: 0.75rem;
                    }
                    @include scrollbar($white, $white);
                }
            }
        }
    }

    #health-coaching-store {
        background: #eaeaea;
    }

    #meditations {
        h1 {
            font-size: 13rem;
            @include until($desktop) {
                font-size: 5rem;
            }
            color: #c0a97d;
        }

        .img-bowl {
            max-width: 80rem;
            * {
                margin-left: auto;
                margin-right: auto;
            }
        }

        p {
            color: #976a26;
            font-weight: bold;
        }
        .gold-button {
            background-color: #bc9d6e;
            color: white;
        }

        .heart-reiki {
            max-width: 37rem;
            * {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    //gift card

    #gift-card {
        h1 {
            font-size: 13rem;
            @include until($desktop) {
                font-size: 5rem;
            }
        }

        .card {
            background-color: lightgrey;
            padding: 2rem;
            border-radius: 30px;
            min-height: 25rem;
            max-width: 38.75rem;
            margin-inline: auto;
            @include mobile {
                margin-inline: 1rem;
            }
        }

        textarea {
            width: 100%;
            resize: none;
            padding: 1rem;
            font-size: 1rem;
            background-color: #f7f7f7;
            font-family: 'Lota Grotesque';
        }

        option[value=''][disabled] {
            display: none;
        }

        /deep/ input.input {
            background-color: #f7f7f7;
            font-family: 'Lota Grotesque';
        }

        .btn {
            width: 9rem;
            height: 3.4rem;
            border-radius: 60px;
            @include until($desktop) {
                display: flex;
                margin: 0 auto;
            }
        }

        .buttons-container {
            @include from($touch) {
                max-width: 50%;
                margin: 0 auto;
            }

            #paypal-button-container {
                width: 100%;
                z-index: 0 !important;

                @include until($touch) {
                    width: 50%;
                }
            }

            button {
                @include until($touch) {
                    width: 50%;
                }
                @include from($touch) {
                    width: 100%;
                    height: 40px;
                }
            }
        }
    }
</style>
